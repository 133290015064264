/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { createCalendar } from '../../actions/calendar';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class CalendarNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.requestSubmit = this.requestSubmit.bind(this);
    this.failedSubmit = this.failedSubmit.bind(this);
  }

  handleChange(e, name) {
    const { value } = e.target;
    this.setState({ [name]: value });
  }

  handleFileChange(e, name) {
    const { files } = e.target;
    this.setState({ [name]: files[0] });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { createCalendarAction, goToList, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      nameEN,
      nameFR,
      nameAR,
      dateEN,
      dateFR,
      dateAR,
      year,
    } = this.state;
    const body = {
      nameEN,
      nameFR,
      nameAR,
      dateEN,
      dateFR,
      dateAR,
      year,
    };
    createCalendarAction({ body: JSON.stringify(body) }).then(() => {
      goToList();
    });
  }

  render() {
    const {
      nameEN,
      nameFR,
      nameAR,
      dateEN,
      dateFR,
      dateAR,
      year,
      submitted,
    } = this.state;
    const { goToList } = this.props;
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        }
        <Row>
          <Col lg={6}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)} encType="multipart/form-data">
                <CardHeader>
                  <strong>
                    {'New calendar'}
                  </strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="nameEN">Calendar Name En</Label>
                    <Input
                      id="nameEN"
                      type="text"
                      placeholder="Enter Calendar Name En"
                      invalid={submitted && nameEN === ''}
                      onChange={e => this.handleChange(e, 'nameEN')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="nameFR">Calendar Name Fr</Label>
                    <Input
                      id="nameFR"
                      type="text"
                      placeholder="Enter Calendar Name Fr"
                      invalid={submitted && nameFR === ''}
                      onChange={e => this.handleChange(e, 'nameFR')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="dateFR">Calendar Name Ar</Label>
                    <Input
                      id="nameAR"
                      type="text"
                      placeholder="Enter Calendar Name Ar"
                      invalid={submitted && nameAR === ''}
                      onChange={e => this.handleChange(e, 'nameAR')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="dateEN">Calendar Date En</Label>
                    <Input
                      id="dateEN"
                      type="text"
                      placeholder="Enter Calendar Date En"
                      invalid={submitted && dateEN === ''}
                      onChange={e => this.handleChange(e, 'dateEN')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="dateFR">Calendar Date Fr</Label>
                    <Input
                      id="dateFR"
                      type="text"
                      placeholder="Enter Calendar Date Fr"
                      invalid={submitted && dateFR === ''}
                      onChange={e => this.handleChange(e, 'dateFR')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="dateAR">Calendar Date Ar</Label>
                    <Input
                      id="dateAR"
                      type="text"
                      placeholder="Enter Calendar Date Ar"
                      invalid={submitted && dateAR === ''}
                      onChange={e => this.handleChange(e, 'dateAR')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="year">Calendar Year</Label>
                    <Input
                      id="year"
                      type="text"
                      placeholder="Enter Calendar Year"
                      invalid={submitted && year === ''}
                      onChange={e => this.handleChange(e, 'year')}
                      required
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="primary">Create</Button>
                  {' '}
                  <Button onClick={() => goToList()} size="sm" color="danger">Cancel</Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

CalendarNew.propTypes = {
  createCalendarAction: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  createCalendarAction: body => createCalendar(body),
  goToList: () => push('/calendar'),
  resetErrorAction: () => resetError(),
}, dispatch);

export default connect(
  null,
  mapDispatchToProps,
)(CalendarNew);
