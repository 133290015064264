/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchMedia, saveMedia } from '../../actions/media';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class MediaEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    const { getMedia } = this.props;
    getMedia();
  }

  handleChange(e, name) {
    const { value } = e.target;
    this.setState({ [name]: value });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { saveMediaAction, goToShow, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      title,
      titleAR,
      link,
      category,
      url,
    } = this.state;
    const body = {
      title,
      titleAR,
      link,
      category,
      url,
    };
    Object.keys(body).forEach(key => body[key] === undefined && delete body[key]);
    const { media } = this.props;
    saveMediaAction(media.mediaID, { body: JSON.stringify(body) }).then(() => goToShow());
  }

  render() {
    const { media } = this.props;
    const {
      title,
      titleAR,
      link,
      category,
      url,
      submitted,
    } = this.state;
    if (!media || Object.keys(media).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        }
        <Row>
          <Col lg={8}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)} encType="multipart/form-data">
                <CardHeader>
                  <strong>
                    {'Edit media: '}
                    {media.mediaID}
                  </strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="title">Media Title</Label>
                    <Input
                      id="title"
                      type="text"
                      placeholder="Enter Media Title"
                      defaultValue={media.title}
                      invalid={submitted && title === ''}
                      onChange={e => this.handleChange(e, 'title')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="titleAR">Media Title AR</Label>
                    <Input
                      id="titleAR"
                      type="text"
                      placeholder="Enter Media Title AR"
                      defaultValue={media.titleAR}
                      invalid={submitted && titleAR === ''}
                      onChange={e => this.handleChange(e, 'titleAR')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="link">Media Link</Label>
                    <Input
                      id="link"
                      type="text"
                      placeholder="Enter Media Link"
                      defaultValue={media.link}
                      invalid={submitted && link === ''}
                      onChange={e => this.handleChange(e, 'link')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="category">Media Category</Label>
                    <Input
                      id="category"
                      type="text"
                      placeholder="Enter Media Category"
                      defaultValue={media.category}
                      invalid={submitted && category === ''}
                      onChange={e => this.handleChange(e, 'category')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="url">Media URL</Label>
                    <Input
                      id="url"
                      type="text"
                      placeholder="Enter Media URL"
                      defaultValue={media.url}
                      invalid={submitted && url === ''}
                      onChange={e => this.handleChange(e, 'url')}
                      required
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="success">Save</Button>
                  {' '}
                  <Button href={`/#/media/${media.mediaID}`} size="sm" color="danger">Cancel</Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

MediaEdit.propTypes = {
  getMedia: PropTypes.func.isRequired,
  goToShow: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
  saveMediaAction: PropTypes.func.isRequired,
  media: PropTypes.shape.isRequired,
};

const mapStateToProps = ({ media }) => ({
  media: media.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  getMedia: () => fetchMedia(ownProps.match.params.id),
  goToShow: () => push(`/media/${ownProps.match.params.id}`),
  resetErrorAction: () => resetError(),
  saveMediaAction: (id, body) => saveMedia(id, body),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MediaEdit);
