import {
  REQUEST_WALLPAPERS, RECEIVE_WALLPAPERS, RECEIVE_WALLPAPER,
} from '../actions/wallpapers';

const reducer = (state = {
  items: [],
  loading: false,
  item: {},
}, action) => {
  switch (action.type) {
    case REQUEST_WALLPAPERS:
      return { ...state, loading: true };
    case RECEIVE_WALLPAPERS:
      return { ...state, items: action.json.data, loading: false };
    case RECEIVE_WALLPAPER:
      return {
        ...state,
        item: action.json,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
