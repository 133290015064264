import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Row, Table,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { deleteCalculationMethod, fetchCalculationMethod } from '../../actions/calculation-methods';
import Can from '../../components/can';
import PinModal from '../../components/pin';

class CalculationMethod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.requestDelete = this.requestDelete.bind(this);
    this.failedDelete = this.failedDelete.bind(this);
  }

  componentWillMount() {
    const { getCalculationMethod } = this.props;
    getCalculationMethod();
  }

  requestDelete(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedDelete() {
    this.setState({
      submitted: false,
    });
  }

  handleDelete() {
    const { deleteCalculationMethodAction, goToList } = this.props;
    deleteCalculationMethodAction().then(() => goToList());
  }

  render() {
    const { submitted } = this.state;
    const { calculationMethod } = this.props;
    if (!calculationMethod || Object.keys(calculationMethod).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleDelete} failedSubmit={this.failedDelete} />
        }
        <Row>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <strong>
                  {'CalculationMethod ID: '}
                  {calculationMethod.id}
                </strong>
                <div className="card-header-actions">
                  <Can I="update" a="CalculationMethod">
                    <Button href={`/#/calculation-methods/${calculationMethod.id}/edit`} color="link" className="card-header-action btn-setting">
                      <i className="icon-note" />
                    </Button>
                  </Can>
                  <Can I="delete" a="CalculationMethod">
                    <Button color="link" className="card-header-action btn-setting" onClick={e => this.requestDelete(e)}>
                      <i className="icon-trash" />
                    </Button>
                  </Can>
                </div>
              </CardHeader>
              <CardBody>
                <Table responsive striped hover>
                  <tbody>
                    <tr key="id">
                      <td>CalculationMethod ID:</td>
                      <td><strong>{calculationMethod.id}</strong></td>
                    </tr>
                    <tr key="name">
                      <td>CalculationMethod Name:</td>
                      <td><strong>{calculationMethod.name}</strong></td>
                    </tr>
                    <tr key="name">
                      <td>CalculationMethod Name Arabic:</td>
                      <td><strong>{calculationMethod.name_ar}</strong></td>
                    </tr>
                    <tr key="code">
                      <td>Code:</td>
                      <td><strong>{calculationMethod.code}</strong></td>
                    </tr>
                    <tr>
                      <td>Fajr Angle</td>
                      <td>
                        <strong>
                          {calculationMethod.fajr_angle}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Isha Angle</td>
                      <td>
                        <strong>
                          {calculationMethod.isha_angle}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Manual Adjustment Fajr</td>
                      <td>
                        <strong>
                          {calculationMethod.manualAdjustmentFajr}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Manual Adjustment Sunrise</td>
                      <td>
                        <strong>
                          {calculationMethod.manualAdjustmentSunrise}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Manual Adjustment Dhuhr</td>
                      <td>
                        <strong>
                          {calculationMethod.manualAdjustmentDhuhr}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Manual Adjustment Asr</td>
                      <td>
                        <strong>
                          {calculationMethod.manualAdjustmentAsr}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Manual Adjustment Maghrib</td>
                      <td>
                        <strong>
                          {calculationMethod.manualAdjustmentMaghrib}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Manual Adjustment Isha</td>
                      <td>
                        <strong>
                          {calculationMethod.manualAdjustmentIsha}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Version</td>
                      <td>
                        <strong>
                          {calculationMethod.version}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter>
                <Button href={`/#/calculation-methods/${calculationMethod.id}/edit`} color="secondary" size="sm">Edit</Button>
                {' '}
                <Button color="danger" onClick={e => this.requestDelete(e)} size="sm">Delete</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

CalculationMethod.propTypes = {
  deleteCalculationMethodAction: PropTypes.func.isRequired,
  getCalculationMethod: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired,
  calculationMethod: PropTypes.shape,
};

CalculationMethod.defaultProps = {
  calculationMethod: {},
};

const mapStateToProps = ({ calculationMethods }) => ({
  calculationMethod: calculationMethods.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  deleteCalculationMethodAction: () => deleteCalculationMethod(ownProps.match.params.id),
  getCalculationMethod: () => fetchCalculationMethod(ownProps.match.params.id),
  goToList: () => goBack(),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CalculationMethod);
