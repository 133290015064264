import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardHeader, Col, Row,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchCalendars } from '../../actions/calendar';
import DataTable from '../../components/data-table';

const COLUMNS = [
  { id: 'calendarID', label: 'ID', link: true },
  { id: 'nameEN', label: 'Name EN', link: true },
  { id: 'nameFR', label: 'Name FR' },
  { id: 'nameAR', label: 'Name AR' },
  { id: 'dateEN', label: 'Date EN' },
  { id: 'dateFR', label: 'Date FR' },
  { id: 'dateAR', label: 'Date AR' },
  { id: 'year', label: 'Year' },
];

const Calendars = (props) => {
  const { getCalendars } = props;
  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <Row>
                <Col lg={4}>
                  Calendar
                </Col>
                <Col lg={8} style={{ textAlign: 'right' }}>
                  <Button href="/#/calendar/new" color="success" size="sm">New</Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <DataTable resource="calendars" dataSource={getCalendars} columns={COLUMNS} urlSchema="calendar" {...props} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

Calendars.propTypes = {
  getCalendars: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  calendars: PropTypes.arrayOf(PropTypes.object),
};

Calendars.defaultProps = {
  calendars: [],
};

const mapStateToProps = ({ calendar }) => ({
  loading: calendar.loading,
  calendars: calendar.items,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getCalendars: sort => fetchCalendars(sort),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Calendars);
