/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { createWallpaper } from '../../actions/wallpapers';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class WallpaperNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.requestSubmit = this.requestSubmit.bind(this);
    this.failedSubmit = this.failedSubmit.bind(this);
  }

  handleChange(e, name) {
    const { value } = e.target;
    if (value === 'true' || value === 'false') this.setState({ [name]: value === 'true' });
    else { this.setState({ [name]: value }); }
  }

  handleFileChange(e, name) {
    const { files } = e.target;
    this.setState({ [name]: files[0] });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { createWallpaperAction, goToList, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      title,
      link,
      filename,
      thumbnail,
      preview,
      category,
      device,
      type,
      format,
      comments,
      credit,
      credit_link,
      premium,
      default_alt,
      twoTones,
      darkVariation,
      alternative,
      darkVariation_alt,
      filename_alt,
      thumbnail_alt,
      order,
    } = this.state;
    const body = {
      title,
      link,
      filename,
      thumbnail,
      preview,
      category,
      device,
      type,
      format,
      comments,
      credit,
      credit_link,
      premium,
      default: default_alt,
      twoTones,
      darkVariation,
      alternative,
      darkVariation_alt,
      filename_alt,
      thumbnail_alt,
      order,
    };
    Object.keys(body).forEach(key => body[key] === undefined && delete body[key]);
    createWallpaperAction({ body: JSON.stringify(body) }).then(() => {
      goToList();
    });
  }

  render() {
    const {
      title,
      link,
      filename,
      thumbnail,
      preview,
      category,
      device,
      type,
      format,
      comments,
      credit,
      credit_link,
      twoTones,
      darkVariation,
      alternative,
      darkVariation_alt,
      filename_alt,
      thumbnail_alt,
      order,
      submitted,
    } = this.state;
    const { goToList } = this.props;
    return (
      <div className="animated fadeIn">
        {submitted && (
          <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        )}
        <Row>
          <Col lg={6}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)} encType="multipart/form-data">
                <CardHeader>
                  <strong>New wallpaper</strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="title">Title</Label>
                    <Input
                      id="title"
                      type="text"
                      placeholder="Enter Title"
                      invalid={submitted && title === ''}
                      onChange={e => this.handleChange(e, 'title')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="link">Link</Label>
                    <Input
                      id="link"
                      type="text"
                      placeholder="Enter Link"
                      invalid={submitted && link === ''}
                      onChange={e => this.handleChange(e, 'link')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="filename">Filename</Label>
                    <Input
                      id="filename"
                      type="text"
                      placeholder="Enter Filename"
                      invalid={submitted && filename === ''}
                      onChange={e => this.handleChange(e, 'filename')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="thumbnail">Thumbnail</Label>
                    <Input
                      id="thumbnail"
                      type="text"
                      placeholder="Enter Thumbnail"
                      invalid={submitted && thumbnail === ''}
                      onChange={e => this.handleChange(e, 'thumbnail')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="preview">Preview</Label>
                    <Input
                      id="preview"
                      type="text"
                      placeholder="Enter Preview"
                      invalid={submitted && preview === ''}
                      onChange={e => this.handleChange(e, 'preview')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="category">Category</Label>
                    <Input
                      id="category"
                      type="text"
                      placeholder="Enter Category"
                      invalid={submitted && category === ''}
                      onChange={e => this.handleChange(e, 'category')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="device">Device</Label>
                    <Input
                      id="device"
                      type="text"
                      placeholder="Enter Device"
                      invalid={submitted && device === ''}
                      onChange={e => this.handleChange(e, 'device')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="type">Type</Label>
                    <Input
                      id="type"
                      type="text"
                      placeholder="Enter Type"
                      invalid={submitted && type === ''}
                      onChange={e => this.handleChange(e, 'type')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="format">Format</Label>
                    <Input
                      id="format"
                      type="text"
                      placeholder="Enter Format"
                      invalid={submitted && format === ''}
                      onChange={e => this.handleChange(e, 'format')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="premium">Premium</Label>
                    <Input
                      type="select"
                      name="premium"
                      id="premium"
                      onChange={e => this.handleChange(e, 'premium')}
                    >
                      <option>true</option>
                      <option>false</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="default_alt">Default</Label>
                    <Input
                      type="select"
                      name="default_alt"
                      id="default_alt"
                      onChange={e => this.handleChange(e, 'default_alt')}
                    >
                      <option>true</option>
                      <option>false</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="comments">Comments</Label>
                    <Input
                      id="comments"
                      type="text"
                      placeholder="Enter Comments"
                      invalid={submitted && comments === ''}
                      onChange={e => this.handleChange(e, 'comments')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="credit">Credit</Label>
                    <Input
                      id="credit"
                      type="text"
                      placeholder="Enter Credit"
                      invalid={submitted && credit === ''}
                      onChange={e => this.handleChange(e, 'credit')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="credit_link">Credit Link</Label>
                    <Input
                      id="credit_link"
                      type="text"
                      placeholder="Enter Credit Link"
                      invalid={submitted && credit_link === ''}
                      onChange={e => this.handleChange(e, 'credit_link')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="twoTones">2 Tones</Label>
                    <Input
                      type="select"
                      name="twoTones"
                      id="twoTones"
                      onChange={e => this.handleChange(e, 'twoTones')}
                    >
                      <option selected={twoTones}>true</option>
                      <option selected={!twoTones}>false</option>
                    </Input>
                  </FormGroup>
                  {twoTones && (
                    <FormGroup>
                      <Label htmlFor="darkVariation">Dark Variation</Label>
                      <Input
                        id="darkVariation"
                        type="text"
                        placeholder="Enter Dark Variation"
                        invalid={submitted && darkVariation === ''}
                        onChange={e => this.handleChange(e, 'darkVariation')}
                      />
                    </FormGroup>
                  )}
                  <FormGroup>
                    <Label for="alternative">Alternative</Label>
                    <Input
                      type="select"
                      name="alternative"
                      id="alternative"
                      onChange={e => this.handleChange(e, 'alternative')}
                    >
                      <option selected={alternative}>true</option>
                      <option selected={!alternative}>false</option>
                    </Input>
                  </FormGroup>
                  {alternative && (
                    <>
                      <FormGroup>
                        <Label htmlFor="filename_alt">Filename Alt</Label>
                        <Input
                          id="filename_alt"
                          type="text"
                          placeholder="Enter Filename Alt"
                          invalid={submitted && filename_alt === ''}
                          onChange={e => this.handleChange(e, 'filename_alt')}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="darkVariation_alt">Dark Variation Alt</Label>
                        <Input
                          id="darkVariation_alt"
                          type="text"
                          placeholder="Enter Dark Variation Alt"
                          invalid={submitted && darkVariation_alt === ''}
                          onChange={e => this.handleChange(e, 'darkVariation_alt')}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="thumbnail_alt">Thumbnail Alt</Label>
                        <Input
                          id="thumbnail_alt"
                          type="text"
                          placeholder="Enter Thumbnail Alt"
                          invalid={submitted && thumbnail_alt === ''}
                          onChange={e => this.handleChange(e, 'thumbnail_alt')}
                          required
                        />
                      </FormGroup>
                    </>
                  )}
                  <FormGroup>
                    <Label htmlFor="order">Order</Label>
                    <Input
                      id="order"
                      type="number"
                      placeholder="Enter Order"
                      invalid={submitted && order === ''}
                      onChange={e => this.handleChange(e, 'order')}
                      required
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="primary">
                    Create
                  </Button>
                  {' '}
                  <Button onClick={() => goToList()} size="sm" color="danger">
                    Cancel
                  </Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

WallpaperNew.propTypes = {
  createWallpaperAction: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    createWallpaperAction: body => createWallpaper(body),
    goToList: () => push('/wallpapers'),
    resetErrorAction: () => resetError(),
  },
  dispatch,
);

export default connect(
  null,
  mapDispatchToProps,
)(WallpaperNew);
