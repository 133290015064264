/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchDaylightSaving, saveDaylightSaving } from '../../actions/daylight-savings';
import { fetchContinents } from '../../actions/continents';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class DaylightSavingEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.failedSubmit = this.failedSubmit.bind(this);
  }

  componentWillMount() {
    const { getDaylightSaving, getContinents } = this.props;
    getDaylightSaving();
    getContinents();
  }

  handleChange(e, name) {
    const { value } = e.target;
    this.setState({ [name]: value });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { saveDaylightSavingAction, goToShow, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      daylight_start,
      daylight_end,
      daylight_start_ref,
      daylight_end_ref,
      year,
      continent_id,
    } = this.state;
    const body = {
      daylight_start,
      daylight_end,
      daylight_start_ref,
      daylight_end_ref,
      year,
      continent_id,
    };
    Object.keys(body).forEach(key => body[key] === undefined && delete body[key]);
    const { daylightSaving } = this.props;
    saveDaylightSavingAction(
      daylightSaving.id,
      { body: JSON.stringify(body) },
    ).then(() => goToShow());
  }

  render() {
    const { daylightSaving, continents } = this.props;
    const {
      daylight_start,
      daylight_end,
      daylight_start_ref,
      daylight_end_ref,
      year,
      submitted,
    } = this.state;
    if (!daylightSaving || Object.keys(daylightSaving).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        }
        <Row>
          <Col lg={8}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)}>
                <CardHeader>
                  <strong>
                    {'Edit Daylight Saving: '}
                    {daylightSaving.id}
                  </strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="daylight_start">Daylight Start</Label>
                    <Input
                      id="daylight_start"
                      type="text"
                      placeholder="Enter Daylight Start"
                      invalid={submitted && daylight_start === ''}
                      onChange={e => this.handleChange(e, 'daylight_start')}
                      defaultValue={daylightSaving.daylight_start}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="daylight_end">Daylight End</Label>
                    <Input
                      id="daylight_end"
                      type="text"
                      placeholder="Enter Daylight End"
                      invalid={submitted && daylight_end === ''}
                      onChange={e => this.handleChange(e, 'daylight_end')}
                      defaultValue={daylightSaving.daylight_end}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="daylight_start_ref">Daylight Start Ref</Label>
                    <Input
                      id="daylight_start_ref"
                      type="text"
                      placeholder="Enter Daylight Start Ref"
                      invalid={submitted && daylight_start_ref === ''}
                      onChange={e => this.handleChange(e, 'daylight_start_ref')}
                      defaultValue={daylightSaving.daylight_start_ref}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="daylight_end_ref">Daylight End Ref</Label>
                    <Input
                      id="daylight_end_ref"
                      type="text"
                      placeholder="Enter Daylight End Ref"
                      invalid={submitted && daylight_end_ref === ''}
                      onChange={e => this.handleChange(e, 'daylight_end_ref')}
                      defaultValue={daylightSaving.daylight_end_ref}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="year">Year</Label>
                    <Input
                      id="year"
                      type="text"
                      placeholder="Enter Year"
                      invalid={submitted && year === ''}
                      onChange={e => this.handleChange(e, 'year')}
                      defaultValue={daylightSaving.year}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="continent_id">Continent</Label>
                    <Input
                      type="select"
                      name="continent_id"
                      id="continent_id"
                      onChange={e => this.handleChange(e, 'continent_id')}
                      defaultValue={daylightSaving.continent_id}
                      required
                    >
                      {
                        continents.map(continent => (
                          <option
                            key={continent.id}
                            value={continent.id}
                          >
                            { continent.name }
                          </option>
                        ))
                      }
                    </Input>
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="success">Save</Button>
                  {' '}
                  <Button href={`/#/daylight-savings/${daylightSaving.id}`} size="sm" color="danger">Cancel</Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

DaylightSavingEdit.propTypes = {
  getDaylightSaving: PropTypes.func.isRequired,
  getContinents: PropTypes.func.isRequired,
  goToShow: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
  saveDaylightSavingAction: PropTypes.func.isRequired,
  daylightSaving: PropTypes.shape.isRequired,
  continents: PropTypes.arrayOf(PropTypes.object),
};
DaylightSavingEdit.defaultProps = {
  continents: [],
};

const mapStateToProps = ({ daylightSavings, continents }) => ({
  daylightSaving: daylightSavings.item,
  continents: continents.items,
});
const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  getDaylightSaving: () => fetchDaylightSaving(ownProps.match.params.id),
  getContinents: () => fetchContinents(),
  goToShow: () => push(`/daylight-savings/${ownProps.match.params.id}`),
  resetErrorAction: () => resetError(),
  saveDaylightSavingAction: (id, body) => saveDaylightSaving(id, body),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DaylightSavingEdit);
