/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchCountry, saveCountry } from '../../actions/countries';
import { fetchContinents } from '../../actions/continents';
import { fetchCalculationMethods } from '../../actions/calculation-methods';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class CountryEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.failedSubmit = this.failedSubmit.bind(this);
  }

  componentWillMount() {
    const { getCountry, getContinents, getCalculationMethods } = this.props;
    getCountry();
    getContinents();
    getCalculationMethods();
  }

  handleChange(e, name) {
    const { value } = e.target;
    this.setState({ [name]: value });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { saveCountryAction, goToShow, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      name,
      continent_id,
      ISOcountryCode,
      daylightSaving,
      calculation_method_id,
    } = this.state;
    if (!name && !continent_id && !ISOcountryCode && !daylightSaving && !calculation_method_id) {
      goToShow();
      return;
    }
    const body = {
      name,
      continent_id,
      ISOcountryCode,
      daylightSaving,
      calculation_method_id,
    };
    Object.keys(body).forEach(key => body[key] === undefined && delete body[key]);
    const { country } = this.props;
    saveCountryAction(country.id, { body: JSON.stringify(body) }).then(() => goToShow());
  }

  render() {
    const { country, continents, calculationMethods } = this.props;
    const {
      name,
      submitted,
      ISOcountryCode,
      daylightSaving,
    } = this.state;
    if (!country || Object.keys(country).length === 0
    || !continents.length || !calculationMethods.length) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        }
        <Row>
          <Col lg={8}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)} encType="multipart/form-data">
                <CardHeader>
                  <strong>
                    {'Edit country: '}
                    {country.id}
                  </strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="name">Country Name</Label>
                    <Input
                      id="name"
                      type="text"
                      placeholder="Enter Country Name"
                      defaultValue={country.name}
                      invalid={submitted && name === ''}
                      onChange={e => this.handleChange(e, 'name')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="continent_id">Continent</Label>
                    <Input
                      type="select"
                      name="continent_id"
                      id="continent_id"
                      onChange={e => this.handleChange(e, 'continent_id')}
                      defaultValue={country.continent_id}
                    >
                      {
                        continents.map(continent => (
                          <option
                            key={continent.id}
                            value={continent.id}
                          >
                            { continent.name }
                          </option>
                        ))
                      }
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="ISOcountryCode">ISO Country Code</Label>
                    <Input
                      id="ISOcountryCode"
                      type="text"
                      placeholder="Enter ISO Country Code"
                      defaultValue={country.ISOcountryCode}
                      invalid={submitted && ISOcountryCode === ''}
                      onChange={e => this.handleChange(e, 'ISOcountryCode')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="daylightSaving">Daylight Saving</Label>
                    <Input
                      id="daylightSaving"
                      type="text"
                      placeholder="Enter Daylight Saving"
                      defaultValue={country.daylightSaving}
                      invalid={submitted && daylightSaving === ''}
                      onChange={e => this.handleChange(e, 'daylightSaving')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="calculation_method_id">Calculation Method</Label>
                    <Input
                      type="select"
                      name="calculation_method_id"
                      id="calculation_method_id"
                      onChange={e => this.handleChange(e, 'calculation_method_id')}
                      defaultValue={country.calculation_method_id}
                    >
                      {
                        calculationMethods.map(calculationMethod => (
                          <option
                            key={calculationMethod.id}
                            value={calculationMethod.id}
                          >
                            { calculationMethod.name }
                          </option>
                        ))
                      }
                    </Input>
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="success">Save</Button>
                  {' '}
                  <Button href={`/#/countries/${country.id}`} size="sm" color="danger">Cancel</Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

CountryEdit.propTypes = {
  getCountry: PropTypes.func.isRequired,
  getContinents: PropTypes.func.isRequired,
  getCalculationMethods: PropTypes.func.isRequired,
  goToShow: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
  saveCountryAction: PropTypes.func.isRequired,
  country: PropTypes.shape.isRequired,
  continents: PropTypes.arrayOf(PropTypes.object),
  calculationMethods: PropTypes.arrayOf(PropTypes.object),
};
CountryEdit.defaultProps = {
  continents: [],
  calculationMethods: [],
};

const mapStateToProps = ({ countries, continents, calculationMethods }) => ({
  country: countries.item,
  continents: continents.items,
  calculationMethods: calculationMethods.items,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  getCountry: () => fetchCountry(ownProps.match.params.id),
  getContinents: () => fetchContinents(),
  getCalculationMethods: () => fetchCalculationMethods(),
  goToShow: () => push(`/countries/${ownProps.match.params.id}`),
  resetErrorAction: () => resetError(),
  saveCountryAction: (id, body) => saveCountry(id, body),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CountryEdit);
