/* eslint-disable no-alert */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchWallpaper, saveWallpaper } from '../../actions/wallpapers';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class WallpaperEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    const { getWallpaper } = this.props;
    getWallpaper();
  }

  handleChange(e, name) {
    const { value } = e.target;
    if (value === 'true' || value === 'false') this.setState({ [name]: value === 'true' });
    else { this.setState({ [name]: value }); }
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { saveWallpaperAction, goToShow, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      title,
      link,
      filename,
      thumbnail,
      preview,
      category,
      device,
      type,
      format,
      comments,
      credit,
      credit_link,
      premium,
      default_alt,
      twoTones,
      darkVariation,
      alternative,
      darkVariation_alt,
      filename_alt,
      thumbnail_alt,
      order,
    } = this.state;
    const body = {
      title,
      link,
      filename,
      thumbnail,
      preview,
      category,
      device,
      type,
      format,
      comments,
      credit,
      credit_link,
      premium,
      default: default_alt,
      twoTones,
      darkVariation,
      alternative,
      darkVariation_alt,
      filename_alt,
      thumbnail_alt,
      order,
    };
    Object.keys(body).forEach(key => body[key] === undefined && delete body[key]);
    const { wallpaper } = this.props;
    saveWallpaperAction(
      wallpaper.wallpaperID,
      { body: JSON.stringify(body) },
    ).then(() => goToShow());
  }

  render() {
    const { wallpaper } = this.props;
    const {
      title,
      link,
      filename,
      thumbnail,
      preview,
      category,
      device,
      type,
      format,
      comments,
      credit,
      credit_link,
      twoTones,
      alternative,
      order,
      submitted,
    } = this.state;

    const render = (wallpaper.twoTones && twoTones === undefined)
     || (twoTones !== undefined && twoTones);

    const renderAlt = (wallpaper.alternative && alternative === undefined)
    || (alternative !== undefined && alternative);

    if (!wallpaper || Object.keys(wallpaper).length === 0) {
      return null;
    }
    return (
      <div className="animated fadeIn">
        {submitted && (
          <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        )}
        <Row>
          <Col lg={8}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)} encType="multipart/form-data">
                <CardHeader>
                  <strong>
                    {'Edit wallpaper: '}
                    {wallpaper.wallpaperID}
                  </strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="title">Title</Label>
                    <Input
                      id="title"
                      type="text"
                      placeholder="Enter Title"
                      invalid={submitted && title === ''}
                      onChange={e => this.handleChange(e, 'title')}
                      defaultValue={wallpaper.title}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="link">Link</Label>
                    <Input
                      id="link"
                      type="text"
                      placeholder="Enter Link"
                      invalid={submitted && link === ''}
                      onChange={e => this.handleChange(e, 'link')}
                      defaultValue={wallpaper.link}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="filename">Filename</Label>
                    <Input
                      id="filename"
                      type="text"
                      placeholder="Enter Filename"
                      invalid={submitted && filename === ''}
                      onChange={e => this.handleChange(e, 'filename')}
                      defaultValue={wallpaper.filename}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="thumbnail">Thumbnail</Label>
                    <Input
                      id="thumbnail"
                      type="text"
                      placeholder="Enter Thumbnail"
                      invalid={submitted && thumbnail === ''}
                      onChange={e => this.handleChange(e, 'thumbnail')}
                      defaultValue={wallpaper.thumbnail}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="preview">Preview</Label>
                    <Input
                      id="preview"
                      type="text"
                      placeholder="Enter Preview"
                      invalid={submitted && preview === ''}
                      onChange={e => this.handleChange(e, 'preview')}
                      defaultValue={wallpaper.preview}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="category">Category</Label>
                    <Input
                      id="category"
                      type="text"
                      placeholder="Enter Category"
                      invalid={submitted && category === ''}
                      onChange={e => this.handleChange(e, 'category')}
                      defaultValue={wallpaper.category}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="device">Device</Label>
                    <Input
                      id="device"
                      type="text"
                      placeholder="Enter Device"
                      invalid={submitted && device === ''}
                      onChange={e => this.handleChange(e, 'device')}
                      defaultValue={wallpaper.device}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="type">Type</Label>
                    <Input
                      id="type"
                      type="text"
                      placeholder="Enter Type"
                      invalid={submitted && type === ''}
                      onChange={e => this.handleChange(e, 'type')}
                      defaultValue={wallpaper.type}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="format">Format</Label>
                    <Input
                      id="format"
                      type="text"
                      placeholder="Enter Format"
                      invalid={submitted && format === ''}
                      onChange={e => this.handleChange(e, 'format')}
                      defaultValue={wallpaper.format}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="premium">Premium</Label>
                    <Input
                      type="select"
                      name="premium"
                      id="premium"
                      onChange={e => this.handleChange(e, 'premium')}
                      defaultValue={wallpaper.premium}
                    >
                      <option>true</option>
                      <option>false</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="default_alt">Default</Label>
                    <Input
                      type="select"
                      name="default_alt"
                      id="default_alt"
                      onChange={e => this.handleChange(e, 'default_alt')}
                      defaultValue={wallpaper.default}
                    >
                      <option>true</option>
                      <option>false</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="comments">Comments</Label>
                    <Input
                      id="comments"
                      type="text"
                      placeholder="Enter Comments"
                      invalid={submitted && comments === ''}
                      onChange={e => this.handleChange(e, 'comments')}
                      defaultValue={wallpaper.comments}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="credit">Credit</Label>
                    <Input
                      id="credit"
                      type="text"
                      placeholder="Enter Credit"
                      invalid={submitted && credit === ''}
                      onChange={e => this.handleChange(e, 'credit')}
                      defaultValue={wallpaper.credit}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="credit_link">Credit Link</Label>
                    <Input
                      id="credit_link"
                      type="text"
                      placeholder="Enter Credit Link"
                      invalid={submitted && credit_link === ''}
                      onChange={e => this.handleChange(e, 'credit_link')}
                      defaultValue={wallpaper.credit_link}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="twoTones">2 Tones</Label>
                    <Input
                      type="select"
                      name="twoTones"
                      id="twoTones"
                      onChange={e => this.handleChange(e, 'twoTones')}
                      defaultValue={wallpaper.twoTones}
                    >
                      <option>true</option>
                      <option>false</option>
                    </Input>
                  </FormGroup>
                  {render && (
                    <FormGroup>
                      <Label htmlFor="darkVariation">Dark Variation</Label>
                      <Input
                        id="darkVariation"
                        type="text"
                        placeholder="Enter Dark Variation"
                        defaultValue={wallpaper.darkVariation}
                        invalid={submitted}
                        onChange={e => this.handleChange(e, 'darkVariation')}
                      />
                    </FormGroup>
                  )}
                  <FormGroup>
                    <Label for="alternative">Alternative</Label>
                    <Input
                      type="select"
                      name="alternative"
                      id="alternative"
                      onChange={e => this.handleChange(e, 'alternative')}
                      defaultValue={wallpaper.alternative}
                    >
                      <option>true</option>
                      <option>false</option>
                    </Input>
                  </FormGroup>
                  {renderAlt && (
                    <>
                      <FormGroup>
                        <Label htmlFor="filename_alt">Filename Alt</Label>
                        <Input
                          id="filename_alt"
                          type="text"
                          placeholder="Enter Filename Alt"
                          defaultValue={wallpaper.filename_alt}
                          invalid={submitted}
                          onChange={e => this.handleChange(e, 'filename_alt')}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="darkVariation_alt">Dark Variation Alt</Label>
                        <Input
                          id="darkVariation_alt"
                          type="text"
                          placeholder="Enter Dark Variation Alt"
                          defaultValue={wallpaper.darkVariation_alt}
                          invalid={submitted}
                          onChange={e => this.handleChange(e, 'darkVariation_alt')}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="thumbnail_alt">Thumbnail Alt</Label>
                        <Input
                          id="thumbnail_alt"
                          type="text"
                          placeholder="Enter Thumbnail Alt"
                          defaultValue={wallpaper.thumbnail_alt}
                          invalid={submitted}
                          onChange={e => this.handleChange(e, 'thumbnail_alt')}
                        />
                      </FormGroup>
                    </>
                  )}
                  <FormGroup>
                    <Label htmlFor="order">Order</Label>
                    <Input
                      id="order"
                      type="number"
                      placeholder="Enter Order"
                      defaultValue={wallpaper.order}
                      invalid={submitted && order === ''}
                      onChange={e => this.handleChange(e, 'order')}
                      required
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="success">
                    Save
                  </Button>
                  {' '}
                  <Button href={`/#/wallpapers/${wallpaper.wallpaperID}`} size="sm" color="danger">
                    Cancel
                  </Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

WallpaperEdit.propTypes = {
  getWallpaper: PropTypes.func.isRequired,
  goToShow: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
  saveWallpaperAction: PropTypes.func.isRequired,
  wallpaper: PropTypes.shape.isRequired,
};

const mapStateToProps = ({ wallpapers }) => ({
  wallpaper: wallpapers.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators(
  {
    getWallpaper: () => fetchWallpaper(ownProps.match.params.id),
    goToShow: () => push(`/wallpapers/${ownProps.match.params.id}`),
    resetErrorAction: () => resetError(),
    saveWallpaperAction: (id, body) => saveWallpaper(id, body),
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WallpaperEdit);
