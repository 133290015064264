import { authorizedRequest, logout } from './account';
import { responseError } from './errors';

require('dotenv').config();

const ENV = process.env;

export const REQUEST_DAYLIGHT_SAVINGS = 'REQUEST_DAYLIGHT_SAVINGS';
export const RECEIVE_DAYLIGHT_SAVINGS = 'RECEIVE_DAYLIGHT_SAVINGS';
export const RECEIVE_DAYLIGHT_SAVING = 'RECEIVE_DAYLIGHT_SAVING';

export const requestDaylightSavings = () => ({
  type: REQUEST_DAYLIGHT_SAVINGS,
});

export const receivedDaylightSavings = json => ({
  type: RECEIVE_DAYLIGHT_SAVINGS,
  json,
});

export const receivedDaylightSaving = json => ({
  type: RECEIVE_DAYLIGHT_SAVING,
  json,
});

export const createDaylightSaving = params => (
  (dispatch) => {
    dispatch(requestDaylightSavings());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/daylight-savings`, { method: 'POST', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);

export const deleteDaylightSaving = id => (
  (dispatch) => {
    dispatch(requestDaylightSavings());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/daylight-savings/${id}`, { method: 'DELETE', headers: {} })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);

export const fetchDaylightSavings = sort => (
  (dispatch) => {
    dispatch(requestDaylightSavings());
    let url = `${ENV.REACT_APP_API_SERVER}/daylight-savings`;
    if (sort) url += `?order=${JSON.stringify(sort)}`;
    return authorizedRequest(url, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
          dispatch(receivedDaylightSavings([]));
        } else if (json.status === 401) {
          dispatch(logout());
          dispatch(receivedDaylightSavings([]));
        } else {
          dispatch(receivedDaylightSavings({ data: json } || []));
        }
        return json;
      });
  }
);

export const fetchDaylightSaving = id => (
  (dispatch) => {
    dispatch(requestDaylightSavings());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/daylight-savings/${id}`, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(logout());
        } else {
          dispatch(receivedDaylightSaving(json));
        }
        return json;
      });
  }
);

export const saveDaylightSaving = (id, params) => (
  (dispatch) => {
    dispatch(requestDaylightSavings());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/daylight-savings/${id}`, { method: 'POST', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);
