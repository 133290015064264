import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Row, Table,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { deleteToday, fetchToday } from '../../actions/today';
import Can from '../../components/can';
import PinModal from '../../components/pin';
import FIELDS from './forms/fields';

class Today extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.requestDelete = this.requestDelete.bind(this);
    this.failedDelete = this.failedDelete.bind(this);
  }

  componentWillMount() {
    const { getToday } = this.props;
    getToday();
  }

  requestDelete(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedDelete() {
    this.setState({
      submitted: false,
    });
  }

  handleDelete() {
    const { deleteTodayAction, goToList } = this.props;
    deleteTodayAction().then(() => goToList());
  }

  render() {
    const { submitted } = this.state;
    const { today } = this.props;
    if (!today || Object.keys(today).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleDelete} failedSubmit={this.failedDelete} />
        }
        <Row>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <strong>
                  {'Today ID: '}
                  {today.id}
                </strong>
                <div className="card-header-actions">
                  <Can I="update" a="Today">
                    <Button href={`/#/today/${today.id}/edit`} color="link" className="card-header-action btn-setting">
                      <i className="icon-note" />
                    </Button>
                  </Can>
                  <Can I="delete" a="Today">
                    <Button color="link" className="card-header-action btn-setting" onClick={e => this.requestDelete(e)}>
                      <i className="icon-trash" />
                    </Button>
                  </Can>
                </div>
              </CardHeader>
              <CardBody>
                <Table responsive striped hover>
                  <tbody>
                    <tr key="id">
                      <td>Today ID:</td>
                      <td><strong>{today.id}</strong></td>
                    </tr>
                    <tr key="title">
                      <td>Today Date:</td>
                      <td><strong>{today.date}</strong></td>
                    </tr>
                  </tbody>
                </Table>
                {
                  today.cards.map((card, i) => (
                    <div className="well">
                      <h6><strong>{`Card ${i + 1}`}</strong></h6>
                      <div className="well-table">
                        <table>
                          <tbody>
                            {Object.keys(card).map((key) => {
                              const { fields } = FIELDS[card.type];
                              const currentField = fields.find(field => key === field.id);
                              if (currentField && currentField.isImage) {
                                return (
                                  <tr>
                                    <th>{key}</th>
                                    <td><img src={card[key]} height="80" alt="Card Icon" /></td>
                                  </tr>
                                );
                              }
                              return (
                                <tr>
                                  <th>{key}</th>
                                  <td>{card[key]}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))
                }
              </CardBody>
              <CardFooter>
                <Button href={`/#/today/${today.id}/edit`} color="secondary" size="sm">Edit</Button>
                {' '}
                <Button color="danger" onClick={e => this.requestDelete(e)} size="sm">Delete</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

Today.propTypes = {
  deleteTodayAction: PropTypes.func.isRequired,
  getToday: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired,
  today: PropTypes.shape,
};

Today.defaultProps = {
  today: {},
};

const mapStateToProps = ({ today }) => ({
  today: today.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  deleteTodayAction: () => deleteToday(ownProps.match.params.id),
  getToday: () => fetchToday(ownProps.match.params.id),
  goToList: () => goBack(),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Today);
