/* eslint-disable camelcase */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { createCity } from '../../actions/cities';
import { fetchCountries } from '../../actions/countries';
import { fetchCalculationMethods } from '../../actions/calculation-methods';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class CityNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.requestSubmit = this.requestSubmit.bind(this);
    this.failedSubmit = this.failedSubmit.bind(this);
  }

  componentWillMount() {
    const { getCountries, getCalculationMethods } = this.props;
    getCountries();
    getCalculationMethods();
  }

  handleChange(e, name) {
    const { value } = e.target;
    this.setState({ [name]: value });
  }

  handleFileChange(e, name) {
    const { files } = e.target;
    this.setState({ [name]: files[0] });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { createCityAction, goToList, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      country_id,
      name,
      name_arabic,
      code,
      state,
      latitude,
      longitude,
      version,
      calculation_methods_id,
    } = this.state;
    if (!name && !name_arabic && !country_id && !code && !state
      && !calculation_methods_id && !latitude && !longitude && !version) {
      return;
    }
    const body = {
      country_id,
      name,
      name_arabic,
      code,
      state,
      latitude,
      longitude,
      version,
      calculation_methods_id,
    };
    createCityAction({ body: JSON.stringify(body) }).then(() => {
      goToList();
    });
  }

  render() {
    const {
      name,
      name_arabic,
      code,
      state,
      latitude,
      longitude,
      version,
      submitted,
    } = this.state;
    const { countries, calculationMethods, goToList } = this.props;
    if (!countries.length || !calculationMethods.length) {
      return null;
    }
    return (
      <div className="animated fadeIn">
        {submitted && (
          <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        )}
        <Row>
          <Col lg={6}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)} encType="multipart/form-data">
                <CardHeader>
                  <strong>New city</strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label for="country_id">Country</Label>
                    <Input
                      type="select"
                      name="country_id"
                      id="country_id"
                      onChange={e => this.handleChange(e, 'country_id')}
                      required
                    >
                      <Fragment>
                        <option />
                        {countries.map(country => (
                          <option key={country.id} value={country.id}>
                            {country.name}
                          </option>
                        ))}
                      </Fragment>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="name">City Name</Label>
                    <Input
                      id="name"
                      type="text"
                      placeholder="Enter City Name"
                      invalid={submitted && name === ''}
                      onChange={e => this.handleChange(e, 'name')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="name_arabic">City Name Arabic</Label>
                    <Input
                      id="name_arabic"
                      type="text"
                      placeholder="Enter City Name Arabic"
                      invalid={submitted && name_arabic === ''}
                      onChange={e => this.handleChange(e, 'name_arabic')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="calculation_methods_id">Calculation Method</Label>
                    <Input
                      type="select"
                      name="calculation_methods_id"
                      id="calculation_methods_id"
                      onChange={e => this.handleChange(e, 'calculation_methods_id')}
                      required
                    >
                      <Fragment>
                        <option />
                        {calculationMethods.map(calculationMethod => (
                          <option key={calculationMethod.id} value={calculationMethod.id}>
                            {calculationMethod.name}
                          </option>
                        ))}
                      </Fragment>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="code">Code</Label>
                    <Input
                      id="code"
                      type="text"
                      placeholder="Enter Code"
                      invalid={submitted && code === ''}
                      onChange={e => this.handleChange(e, 'code')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="state">State</Label>
                    <Input
                      id="state"
                      type="text"
                      placeholder="Enter State"
                      invalid={submitted && state === ''}
                      onChange={e => this.handleChange(e, 'state')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="latitude">Latitude</Label>
                    <Input
                      id="latitude"
                      type="text"
                      placeholder="Enter Latitude"
                      invalid={submitted && latitude === ''}
                      onChange={e => this.handleChange(e, 'latitude')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="longitude">Longitude</Label>
                    <Input
                      id="longitude"
                      type="text"
                      placeholder="Enter Longitude"
                      invalid={submitted && longitude === ''}
                      onChange={e => this.handleChange(e, 'longitude')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="version">Version</Label>
                    <Input
                      id="version"
                      type="number"
                      placeholder="Enter Version"
                      invalid={submitted && version === ''}
                      onChange={e => this.handleChange(e, 'version')}
                      required
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="primary">
                    Create
                  </Button>
                  {' '}
                  <Button onClick={() => goToList()} size="sm" color="danger">
                    Cancel
                  </Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

CityNew.propTypes = {
  getCountries: PropTypes.func.isRequired,
  getCalculationMethods: PropTypes.func.isRequired,
  createCityAction: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object),
  calculationMethods: PropTypes.arrayOf(PropTypes.object),
};

CityNew.defaultProps = {
  countries: [],
  calculationMethods: [],
};

const mapStateToProps = ({ countries, calculationMethods }) => ({
  countries: countries.items,
  calculationMethods: calculationMethods.items,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getCountries: () => fetchCountries(),
    getCalculationMethods: () => fetchCalculationMethods(),
    createCityAction: body => createCity(body),
    goToList: () => push('/cities'),
    resetErrorAction: () => resetError(),
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CityNew);
