// eslint-disable-next-line camelcase
const validateAnimationPoints = (animation_points) => {
  try {
    const { from, to } = JSON.parse(animation_points);

    const error = {
      valid: false,
      message: "Animation Points validation error: 'from' and 'to' parameters must be an array of 5 numbers",
    };

    const isNotNumber = n => typeof n !== 'number';

    if (Array.isArray(from) && Array.isArray(to)) {
      if (from.length !== 5 || to.length !== 5) {
        return error;
      }
      // check if in array are not only numbers
      const fromIsNotNumberOnly = from.some(isNotNumber);
      const toIsNotNumberOnly = to.some(isNotNumber);

      if (fromIsNotNumberOnly || toIsNotNumberOnly) return error;
      return {
        valid: true,
      };
    }
    return error;
  } catch (e) {
    return {
      valid: false,
      message: 'Animation Points must be a valid JSON',
    };
  }
};

export default validateAnimationPoints;
