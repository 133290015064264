import { authorizedRequest } from './account';
import { responseError } from './errors';

require('dotenv').config();

const ENV = process.env;

export const REQUEST_IMPORT = 'REQUEST_IMPORT';

export const requestImport = () => ({
  type: REQUEST_IMPORT,
});

export const importExcel = params => (
  (dispatch) => {
    dispatch(requestImport());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/import`, { method: 'POST', body: params })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);
