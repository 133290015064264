import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardHeader, Col, Row,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchCalculationMethods } from '../../actions/calculation-methods';
import DataTable from '../../components/data-table';

const COLUMNS = [
  { id: 'id', label: 'ID', link: true },
  { id: 'name', label: 'Name', link: true },
  { id: 'name_ar', label: 'Name Arabic', link: true },
  { id: 'code', label: 'Code' },
  { id: 'fajr_angle', label: 'Fajr Angle' },
  { id: 'isha_angle', label: 'Isha Angle' },
  { id: 'manualAdjustmentFajr', label: 'Manual Adjustment Fajr' },
  { id: 'manualAdjustmentSunrise', label: 'Manual Adjustment Sunrise' },
  { id: 'manualAdjustmentDhuhr', label: 'Manual Adjustment Dhuhr' },
  { id: 'manualAdjustmentAsr', label: 'Manual Adjustment Asr' },
  { id: 'manualAdjustmentMaghrib', label: 'Manual Adjustment Maghrib' },
  { id: 'manualAdjustmentIsha', label: 'Manual Adjustment Isha' },
  { id: 'version', label: 'Version' },
];

const CalculationMethods = (props) => {
  const { getCalculationMethods } = props;
  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <Row>
                <Col lg={4}>CalculationMethods</Col>
                <Col lg={8} style={{ textAlign: 'right' }}>
                  <Button href="/#/calculation-methods/new" color="success" size="sm">
                    New
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <DataTable
                resource="calculationMethods"
                dataSource={getCalculationMethods}
                columns={COLUMNS}
                urlSchema="calculation-methods"
                {...props}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
CalculationMethods.propTypes = {
  getCalculationMethods: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  calculationMethods: PropTypes.arrayOf(PropTypes.object),
};

CalculationMethods.defaultProps = {
  calculationMethods: [],
};

const mapStateToProps = ({ calculationMethods }) => ({
  loading: calculationMethods.loading,
  calculationMethods: calculationMethods.items,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getCalculationMethods: sort => fetchCalculationMethods(sort),
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CalculationMethods);
