import { authorizedRequest, logout } from './account';
import { responseError } from './errors';

require('dotenv').config();

const ENV = process.env;

export const REQUEST_PROMOTIONAL_APPS = 'REQUEST_PROMOTIONAL_APPS';
export const RECEIVE_PROMOTIONAL_APPS = 'RECEIVE_PROMOTIONAL_APPS';
export const RECEIVE_PROMOTIONAL_APP = 'RECEIVE_PROMOTIONAL_APP';

export const requestPromotionalApps = () => ({
  type: REQUEST_PROMOTIONAL_APPS,
});

export const receivedPromotionalApps = json => ({
  type: RECEIVE_PROMOTIONAL_APPS,
  json,
});

export const receivedPromotionalApp = json => ({
  type: RECEIVE_PROMOTIONAL_APP,
  json,
});

export const createPromotionalApp = params => (
  (dispatch) => {
    dispatch(requestPromotionalApps());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/promotional-apps`, { method: 'POST', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);

export const deletePromotionalApp = id => (
  (dispatch) => {
    dispatch(requestPromotionalApps());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/promotional-apps/${id}`, { method: 'DELETE', headers: {} })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);

export const fetchPromotionalApps = sort => (
  (dispatch) => {
    dispatch(requestPromotionalApps());
    let url = `${ENV.REACT_APP_API_SERVER}/promotional-apps`;
    if (sort) url += `?order=${JSON.stringify(sort)}`;
    return authorizedRequest(url, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
          dispatch(receivedPromotionalApps([]));
        } else if (json.status === 401) {
          dispatch(logout());
          dispatch(receivedPromotionalApps([]));
        } else {
          dispatch(receivedPromotionalApps({ data: json.quanticapps } || []));
        }
        return json.quanticapps;
      });
  }
);

export const fetchPromotionalApp = id => (
  (dispatch) => {
    dispatch(requestPromotionalApps());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/promotional-apps/${id}`, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(logout());
        } else {
          dispatch(receivedPromotionalApp(json.app));
        }
        return json.app;
      });
  }
);

export const savePromotionalApp = (id, params) => (
  (dispatch) => {
    dispatch(requestPromotionalApps());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/promotional-apps/${id}`, { method: 'POST', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);
