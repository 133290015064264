import {
  REQUEST_DST_SETUP, RECEIVE_DST_SETUP,
} from '../actions/dst-setup';

const reducer = (state = {
  items: {},
  loading: false,
}, action) => {
  switch (action.type) {
    case REQUEST_DST_SETUP:
      return { ...state, loading: true };
    case RECEIVE_DST_SETUP:
      return { ...state, items: action.json.data, loading: false };
    default:
      return state;
  }
};

export default reducer;
