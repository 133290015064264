/* eslint-disable react/no-array-index-key */
import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import SortableItem from './sortableItem';

const SortableList = SortableContainer(
  ({
    items, handleChange, handleTypeChange, handleRemove, submitted,
  }) => (
    <ul style={{ listStyle: 'none', padding: 0 }}>
      {items.map((card, i) => (
        <SortableItem
          key={i}
          index={i}
          count={i}
          handleChange={handleChange}
          handleTypeChange={handleTypeChange}
          handleRemove={handleRemove}
          card={card}
          submitted={submitted}
        />
      ))}
    </ul>
  ),
);

export default SortableList;
