/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchCalendar, saveCalendar } from '../../actions/calendar';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class CalendarEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    const { getCalendar } = this.props;
    getCalendar();
  }

  handleChange(e, name) {
    const { value } = e.target;
    this.setState({ [name]: value });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { saveCalendarAction, goToShow, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      nameEN,
      nameFR,
      nameAR,
      dateEN,
      dateFR,
      dateAR,
      year,
    } = this.state;
    const body = {
      nameEN,
      nameFR,
      nameAR,
      dateEN,
      dateFR,
      dateAR,
      year,
    };
    Object.keys(body).forEach(key => body[key] === undefined && delete body[key]);
    const { calendar } = this.props;
    saveCalendarAction(calendar.calendarID, { body: JSON.stringify(body) }).then(() => goToShow());
  }

  render() {
    const { calendar } = this.props;
    const {
      nameEN,
      nameFR,
      nameAR,
      dateEN,
      dateFR,
      dateAR,
      year,
      submitted,
    } = this.state;
    if (!calendar || Object.keys(calendar).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        }
        <Row>
          <Col lg={8}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)} encType="multipart/form-data">
                <CardHeader>
                  <strong>
                    {'Edit calendar: '}
                    {calendar.calendarID}
                  </strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="nameAR">Calendar Name Ar</Label>
                    <Input
                      id="nameEN"
                      type="text"
                      placeholder="Enter Calendar Name En"
                      defaultValue={calendar.nameEN}
                      invalid={submitted && nameEN === ''}
                      onChange={e => this.handleChange(e, 'nameEN')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="nameFR">Calendar Name Fr</Label>
                    <Input
                      id="nameFR"
                      type="text"
                      placeholder="Enter Calendar Name Fr"
                      defaultValue={calendar.nameFR}
                      invalid={submitted && nameFR === ''}
                      onChange={e => this.handleChange(e, 'nameFR')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="nameAR">Calendar Name Ar</Label>
                    <Input
                      id="nameAR"
                      type="text"
                      placeholder="Enter Calendar Name Ar"
                      defaultValue={calendar.nameAR}
                      invalid={submitted && nameAR === ''}
                      onChange={e => this.handleChange(e, 'nameAR')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="dateEN">Calendar Date En</Label>
                    <Input
                      id="dateEN"
                      type="text"
                      placeholder="Enter Calendar Date En"
                      defaultValue={calendar.dateEN}
                      invalid={submitted && dateEN === ''}
                      onChange={e => this.handleChange(e, 'dateEN')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="dateFR">Calendar Name Fr</Label>
                    <Input
                      id="dateFR"
                      type="text"
                      placeholder="Enter Calendar Name Fr"
                      defaultValue={calendar.dateFR}
                      invalid={submitted && dateFR === ''}
                      onChange={e => this.handleChange(e, 'dateFR')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="dateAR">Calendar Name Ar</Label>
                    <Input
                      id="dateAR"
                      type="text"
                      placeholder="Enter Calendar Name Ar"
                      defaultValue={calendar.dateAR}
                      invalid={submitted && dateAR === ''}
                      onChange={e => this.handleChange(e, 'dateAR')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="year">Calendar Year</Label>
                    <Input
                      id="year"
                      type="text"
                      placeholder="Enter Calendar Year"
                      defaultValue={calendar.year}
                      invalid={submitted && year === ''}
                      onChange={e => this.handleChange(e, 'year')}
                      required
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="success">Save</Button>
                  {' '}
                  <Button href={`/#/calendar/${calendar.calendarID}`} size="sm" color="danger">Cancel</Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

CalendarEdit.propTypes = {
  getCalendar: PropTypes.func.isRequired,
  goToShow: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
  saveCalendarAction: PropTypes.func.isRequired,
  calendar: PropTypes.shape.isRequired,
};

const mapStateToProps = ({ calendar }) => ({
  calendar: calendar.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  getCalendar: () => fetchCalendar(ownProps.match.params.id),
  goToShow: () => push(`/calendar/${ownProps.match.params.id}`),
  resetErrorAction: () => resetError(),
  saveCalendarAction: (id, body) => saveCalendar(id, body),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CalendarEdit);
