export default {
  1: {
    name: 'Text & Image',
    fields: [
      { id: 'type', label: 'Type', type: 'number' },
      { id: 'template', label: 'Template', type: 'text' },
      {
        id: 'app_icon',
        label: 'App Icon',
        type: 'text',
        isImage: true,
      },
      { id: 'image_credit_text', label: 'Image Credit Text', type: 'text' },
      { id: 'image_credit_link', label: 'Image Credit Link', type: 'text' },
      {
        id: 'read_more_button_text',
        label: 'Read More Button Text',
        type: 'text',
      },
      {
        id: 'read_more_button_color',
        label: 'Read More Button Color',
        type: 'text',
      },
      { id: 'title_text', label: 'Title Text', type: 'text' },
      { id: 'title_color', label: 'Title Color', type: 'text' },
      { id: 'body_En', label: 'Body En', type: 'textarea' },
      { id: 'body_Fr', label: 'Body Fr', type: 'textarea' },
      { id: 'body_AR', label: 'Body Ar', type: 'textarea' },
      { id: 'body_Tr', label: 'Body Tr', type: 'textarea' },
      {
        id: 'image',
        label: 'Image',
        type: 'text',
        isImage: true,
      },
      { id: 'action', label: 'Action', type: 'number' },
    ],
  },
  2: {
    name: 'Wallpaper',
    fields: [
      { id: 'type', label: 'Type', type: 'number' },
      { id: 'template', label: 'Template', type: 'text' },
      {
        id: 'app_icon',
        label: 'App Icon',
        type: 'text',
        isImage: true,
      },
      { id: 'image_credit_text', label: 'Image Credit Text', type: 'text' },
      { id: 'image_credit_link', label: 'Image Credit Link', type: 'text' },
      { id: 'get_button_text', label: 'Get Button Text', type: 'text' },
      { id: 'get_button_color', label: 'Get Button Color', type: 'text' },
      { id: 'title_text', label: 'Title Text', type: 'text' },
      { id: 'title_color', label: 'Title Color', type: 'text' },
      {
        id: 'image',
        label: 'Image',
        type: 'text',
        isImage: true,
      },
      { id: 'action', label: 'Action', type: 'number' },
    ],
  },
  3: {
    name: 'Blog article',
    fields: [
      { id: 'type', label: 'Type', type: 'number' },
      { id: 'template', label: 'Template', type: 'text' },
      {
        id: 'app_icon',
        label: 'App Icon',
        type: 'text',
        isImage: true,
      },
      { id: 'image_credit_text', label: 'Image Credit Text', type: 'text' },
      { id: 'image_credit_link', label: 'Image Credit Link', type: 'text' },
      {
        id: 'read_more_button_text',
        label: 'Read More Button Text',
        type: 'text',
      },
      {
        id: 'read_more_button_color',
        label: 'Read More Button Color',
        type: 'text',
      },
      { id: 'title_text', label: 'Title Text', type: 'text' },
      { id: 'title_color', label: 'Title Color', type: 'text' },
      { id: 'article_body', label: 'Article Body EN', type: 'textarea' },
      { id: 'article_body_fr', label: 'Article Body FR', type: 'textarea' },
      { id: 'article_body_ar', label: 'Article Body AR', type: 'textarea' },
      { id: 'article_body_tr', label: 'Article Body TR', type: 'textarea' },
      {
        id: 'image',
        label: 'Image',
        type: 'text',
        isImage: true,
      },
      { id: 'action', label: 'Action', type: 'number' },
    ],
  },
  4: {
    name: 'Apps',
    fields: [
      { id: 'type', label: 'Type', type: 'number' },
      { id: 'template', label: 'Template', type: 'text' },
      { id: 'apps_json', label: 'Apps JSON', type: 'text' },
      { id: 'action', label: 'Action', type: 'number' },
    ],
  },
  5: {
    name: 'Promotion',
    fields: [
      { id: 'type', label: 'Type', type: 'number' },
      { id: 'template', label: 'Template', type: 'text' },
      {
        id: 'app_icon',
        label: 'App Icon',
        type: 'text',
        isImage: true,
        defaultValue:
          'https://athanpro-contents.s3.amazonaws.com/quranpro-icon.png',
      },
      {
        id: 'image_credit_text',
        label: 'Image Credit Text',
        type: 'text',
        defaultValue: 'Photo by Heather Shevlin on Unsplash',
      },
      {
        id: 'image_credit_link',
        label: 'Image Credit Link',
        type: 'text',
        defaultValue:
          'https://unsplash.com/photos/3B_NrzTjajc?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText',
      },
      {
        id: 'title_text',
        label: 'Title Text',
        type: 'text',
        defaultValue: 'App of the Day',
      },
      {
        id: 'title_color',
        label: 'Title Color',
        type: 'text',
        defaultValue: '0xfefefe',
      },
      {
        id: 'get_button_text',
        label: 'Get Button Text',
        type: 'text',
        defaultValue: 'GET',
      },
      {
        id: 'get_button_color',
        label: 'Get Button Color',
        type: 'text',
        defaultValue: '0x0f60e2',
      },
      {
        id: 'image',
        label: 'Image',
        type: 'text',
        isImage: true,
        defaultValue:
          'https://images.unsplash.com/photo-1482881497185-d4a9ddbe4151?ixlib=rb-0.3.5&s=94b8282b7d73d1522326d3d91fee141f&auto=format&fit=crop&w=1301&q=80',
      },
      {
        id: 'target_app_link',
        label: 'Target App Link',
        type: 'text',
        defaultValue: 'http://onelink.to/2283cm',
      },
      {
        id: 'action', label: 'Action', type: 'number', defaultValue: 5,
      },
    ],
  },
  7: {
    name: 'Event',
    fields: [
      { id: 'type', label: 'Type', type: 'number' },
      { id: 'template', label: 'Template', type: 'text' },
      {
        id: 'app_icon',
        label: 'App Icon',
        type: 'text',
        isImage: true,
      },
      { id: 'image_credit_text', label: 'Image Credit Text', type: 'text' },
      { id: 'image_credit_link', label: 'Image Credit Link', type: 'text' },
      { id: 'title_text', label: 'Title Text', type: 'text' },
      { id: 'title_color', label: 'Title Color', type: 'text' },
      { id: 'short_message_text', label: 'Short Message Text', type: 'text' },
      { id: 'short_message_color', label: 'Short Message Color', type: 'text' },
      {
        id: 'image',
        label: 'Image',
        type: 'text',
        isImage: true,
      },
      { id: 'animated', label: 'Animated', type: 'text' },
      { id: 'action', label: 'Action', type: 'number' },
    ],
  },
  8: {
    name: 'List',
    fields: [
      { id: 'type', label: 'Type', type: 'number' },
      { id: 'template', label: 'Template', type: 'text' },
      { id: 'title_text', label: 'Title Text', type: 'text' },
      { id: 'title_color', label: 'Title Color', type: 'text' },
      { id: 'prayers', label: 'Prayers', type: 'text' },
      { id: 'times', label: 'Times', type: 'text' },
      { id: 'action', label: 'Action', type: 'number' },
    ],
  },
  9: {
    name: 'Countdown',
    fields: [
      { id: 'type', label: 'Type', type: 'number' },
      { id: 'template', label: 'Template', type: 'text' },
      {
        id: 'app_icon',
        label: 'App Icon',
        type: 'text',
        isImage: true,
        defaultValue: 'https://is1-ssl.mzstatic.com/image/thumb/Purple128/v4/0b/4e/2a/0b4e2a68-e820-9e73-c09a-cfd5c64edae8/AppIcon-1x_U007emarketing-85-220-6.png/230x0w.jpg',
      },
      {
        id: 'image_credit_text', label: 'Image Credit Text', type: 'text', defaultValue: 'Photo by Javier Allegue Barros on Unsplash',
      },
      {
        id: 'image_credit_link', label: 'Image Credit Link', type: 'text', defaultValue: 'https://unsplash.com',
      },
      {
        id: 'title_text', label: 'Title Text', type: 'text', defaultValue: 'Ramadan 2022',
      },
      {
        id: 'title_color', label: 'Title Color', type: 'text', defaultValue: '0xffffff',
      },
      {
        id: 'target_date', label: 'Target Date', type: 'text', defaultValue: '02/04/2022',
      },
      {
        id: 'image',
        label: 'Image',
        type: 'text',
        isImage: true,
        defaultValue: 'https://storage.gra.cloud.ovh.net/v1/AUTH_6a3f2c790b804a798c1bac5ccb119d78/athanpro/iStock-1305026622.jpg',
      },
      {
        id: 'target_app_link', label: 'Target App Link', type: 'text', defaultValue: 'https://itunes.apple.com/app/id743843090',
      },
      {
        id: 'target_app_link_android',
        label: 'Target App Link Android',
        type: 'text',
        defaultValue: 'https://play.google.com/store/apps/details?id=com.quanticapps.athan',
      },
      {
        id: 'action', label: 'Action', type: 'number', defaultValue: 1,
      },
    ],
  },
  10: {
    name: 'Audio',
    fields: [
      { id: 'type', label: 'Type', type: 'number' },
      { id: 'template', label: 'Template', type: 'text' },
      {
        id: 'title_text', label: 'Title Text', type: 'text', defaultValue: 'Surat Al-Kahf',
      },
      {
        id: 'global_color', label: 'Global Color', type: 'text', defaultValue: '0x00A8FF',
      },
      {
        id: 'audio_link', label: 'Audio Link', type: 'text', defaultValue: 'https://quanticapps.com/quran/abu_bakr_ash-shaatree/018.mp3',
      },
      {
        id: 'share_link', label: 'Share Link', type: 'text', defaultValue: 'https://open.quranpro.co/#/play?reciter=819532f9-99a6-4624-bbce-5ce5b690367d&track=18',
      },
      {
        id: 'image',
        label: 'Image',
        type: 'text',
        isImage: true,
        defaultValue: 'https://content.athanpro.com/today/assets/images/audio_bg.jpg',
      },
      {
        id: 'action', label: 'Action', type: 'number', defaultValue: 1,
      },
    ],
  },
  11: {
    name: 'Video',
    fields: [
      { id: 'type', label: 'Type', type: 'number' },
      { id: 'template', label: 'Template', type: 'text' },
      {
        id: 'title_text', label: 'Title Text', type: 'text', defaultValue: 'Video of the Day',
      },
      {
        id: 'global_color', label: 'Global Color', type: 'text', defaultValue: '0x00A8FF',
      },
      {
        id: 'thumbnail_url',
        label: 'Thumnail URL',
        type: 'text',
        isImage: true,
        defaultValue: 'https://storage.gra.cloud.ovh.net/v1/AUTH_6a3f2c790b804a798c1bac5ccb119d78/athanpro/12102021.jpg',
      },
      {
        id: 'video_url', label: 'Video URL', type: 'text', defaultValue: 'https://storage.gra.cloud.ovh.net/v1/AUTH_6a3f2c790b804a798c1bac5ccb119d78/athanpro/12102021.mp4',
      },
      {
        id: 'share_link', label: 'Share Link', type: 'text', defaultValue: 'http://athan.pro',
      },
      {
        id: 'action', label: 'Action', type: 'number', defaultValue: 1,
      },
    ],
  },
};
