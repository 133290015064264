/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchContinent, saveContinent } from '../../actions/continents';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class ContinentEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    const { getContinent } = this.props;
    getContinent();
  }

  handleChange(e, name) {
    const { value } = e.target;
    this.setState({ [name]: value });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { saveContinentAction, goToShow, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      name,
    } = this.state;
    if (!name) {
      goToShow();
      return;
    }
    const body = {
      name,
    };
    Object.keys(body).forEach(key => body[key] === undefined && delete body[key]);
    const { continent } = this.props;
    saveContinentAction(continent.id, { body: JSON.stringify(body) }).then(() => goToShow());
  }

  render() {
    const { continent } = this.props;
    const {
      name,
      submitted,
    } = this.state;
    if (!continent || Object.keys(continent).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        }
        <Row>
          <Col lg={8}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)} encType="multipart/form-data">
                <CardHeader>
                  <strong>
                    {'Edit continent: '}
                    {continent.id}
                  </strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="name">Continent Name</Label>
                    <Input
                      id="name"
                      type="text"
                      placeholder="Enter Continent Name"
                      defaultValue={continent.name}
                      invalid={submitted && name === ''}
                      onChange={e => this.handleChange(e, 'name')}
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="success">Save</Button>
                  {' '}
                  <Button href={`/#/continents/${continent.id}`} size="sm" color="danger">Cancel</Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

ContinentEdit.propTypes = {
  getContinent: PropTypes.func.isRequired,
  goToShow: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
  saveContinentAction: PropTypes.func.isRequired,
  continent: PropTypes.shape.isRequired,
};

const mapStateToProps = ({ continents }) => ({
  continent: continents.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  getContinent: () => fetchContinent(ownProps.match.params.id),
  goToShow: () => push(`/continents/${ownProps.match.params.id}`),
  resetErrorAction: () => resetError(),
  saveContinentAction: (id, body) => saveContinent(id, body),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContinentEdit);
