import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, CardHeader, Col, Row, Table,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchHidjri } from '../../actions/hidjri';

import HidjriRow from './row';

class Hidjri extends React.Component {
  componentWillMount() {
    const { getHidjri } = this.props;
    getHidjri();
  }

  render() {
    const { hidjri, loading } = this.props;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xl={12}>
            <Card>
              <CardHeader>
                <Row>
                  <Col lg={4}>
                    Hidjri
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col">Hidjri Ramadan Start MA</th>
                      <th scope="col">Hidjri Ramadan Start SA</th>
                      <th scope="col">Hidjri Year</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && !hidjri && <tr className="row-no-data"><td colSpan="4">No data found</td></tr>}
                    {hidjri && <HidjriRow key={0} hidjri={hidjri} />}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

Hidjri.propTypes = {
  getHidjri: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  hidjri: PropTypes.arrayOf(PropTypes.object),
};

Hidjri.defaultProps = {
  hidjri: [],
};

const mapStateToProps = ({ hidjri }) => ({
  loading: hidjri.loading,
  hidjri: hidjri.items,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getHidjri: () => fetchHidjri(),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Hidjri);
