import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Row, Table,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { deleteSoundsFull, fetchSoundsFull } from '../../actions/sounds-full';
import Can from '../../components/can';
import PinModal from '../../components/pin';

class SoundsFull extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.requestDelete = this.requestDelete.bind(this);
    this.failedDelete = this.failedDelete.bind(this);
  }

  componentWillMount() {
    const { getSoundsFull } = this.props;
    getSoundsFull();
  }

  requestDelete(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedDelete() {
    this.setState({
      submitted: false,
    });
  }

  handleDelete() {
    const { deleteSoundsFullAction, goToList } = this.props;
    deleteSoundsFullAction().then(() => goToList());
  }

  render() {
    const { submitted } = this.state;
    const { soundsFull } = this.props;
    if (!soundsFull || Object.keys(soundsFull).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleDelete} failedSubmit={this.failedDelete} />
        }
        <Row>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <strong>
                  {'SoundsFull ID: '}
                  {soundsFull.soundID}
                </strong>
                <div className="card-header-actions">
                  <Can I="update" a="SoundsFull">
                    <Button href={`/#/sounds-full/${soundsFull.soundID}/edit`} color="link" className="card-header-action btn-setting">
                      <i className="icon-note" />
                    </Button>
                  </Can>
                  <Can I="delete" a="SoundsFull">
                    <Button color="link" className="card-header-action btn-setting" onClick={e => this.requestDelete(e)}>
                      <i className="icon-trash" />
                    </Button>
                  </Can>
                </div>
              </CardHeader>
              <CardBody>
                <Table responsive striped hover>
                  <tbody>
                    <tr key="id">
                      <td>ID:</td>
                      <td><strong>{soundsFull.soundID}</strong></td>
                    </tr>
                    <tr key="title">
                      <td>Title:</td>
                      <td><strong>{soundsFull.title}</strong></td>
                    </tr>
                    <tr key="title">
                      <td>Title Ar:</td>
                      <td><strong>{soundsFull.titleAR}</strong></td>
                    </tr>
                    <tr key="link">
                      <td>Link:</td>
                      <td><strong>{soundsFull.link}</strong></td>
                    </tr>
                    <tr key="full_link">
                      <td>Full Link:</td>
                      <td><strong>{soundsFull.full_link}</strong></td>
                    </tr>
                    <tr key="short_link">
                      <td>Short Link:</td>
                      <td><strong>{soundsFull.short_link}</strong></td>
                    </tr>
                    <tr key="shortversion">
                      <td>Short Version:</td>
                      <td><strong>{soundsFull.shortversion}</strong></td>
                    </tr>
                    <tr key="longversion">
                      <td>Long Version:</td>
                      <td><strong>{soundsFull.longversion}</strong></td>
                    </tr>
                    <tr key="length">
                      <td>Length:</td>
                      <td><strong>{soundsFull.length}</strong></td>
                    </tr>
                    <tr key="type">
                      <td>Type:</td>
                      <td><strong>{soundsFull.type}</strong></td>
                    </tr>
                    <tr key="format">
                      <td>Format:</td>
                      <td><strong>{soundsFull.format}</strong></td>
                    </tr>
                    <tr key="premium">
                      <td>Premium:</td>
                      <td><strong>{soundsFull.premium.toString()}</strong></td>
                    </tr>
                    <tr key="split">
                      <td>Split:</td>
                      <td><strong>{soundsFull.split}</strong></td>
                    </tr>
                    <tr key="default">
                      <td>Default:</td>
                      <td><strong>{soundsFull.default.toString()}</strong></td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter>
                <Button href={`/#/sounds-full/${soundsFull.soundID}/edit`} color="secondary" size="sm">Edit</Button>
                {' '}
                <Button color="danger" onClick={e => this.requestDelete(e)} size="sm">Delete</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

SoundsFull.propTypes = {
  deleteSoundsFullAction: PropTypes.func.isRequired,
  getSoundsFull: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired,
  soundsFull: PropTypes.shape,
};

SoundsFull.defaultProps = {
  soundsFull: {},
};

const mapStateToProps = ({ soundsFulls }) => ({
  soundsFull: soundsFulls.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  deleteSoundsFullAction: () => deleteSoundsFull(ownProps.match.params.id),
  getSoundsFull: () => fetchSoundsFull(ownProps.match.params.id),
  goToList: () => goBack(),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SoundsFull);
