import { authorizedRequest, logout } from './account';
import { responseError } from './errors';

require('dotenv').config();

const ENV = process.env;

export const REQUEST_CALCULATION_METHODS = 'REQUEST_CALCULATION_METHODS';
export const RECEIVE_CALCULATION_METHODS = 'RECEIVE_CALCULATION_METHODS';
export const RECEIVE_CALCULATION_METHOD = 'RECEIVE_CALCULATION_METHOD';

export const requestCalculationMethods = () => ({
  type: REQUEST_CALCULATION_METHODS,
});

export const receivedCalculationMethods = json => ({
  type: RECEIVE_CALCULATION_METHODS,
  json,
});

export const receivedCalculationMethod = json => ({
  type: RECEIVE_CALCULATION_METHOD,
  json,
});

export const createCalculationMethod = params => (
  (dispatch) => {
    dispatch(requestCalculationMethods());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/calculation-methods`, { method: 'POST', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);

export const deleteCalculationMethod = id => (
  (dispatch) => {
    dispatch(requestCalculationMethods());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/calculation-methods/${id}`, { method: 'DELETE', headers: {} })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);

export const fetchCalculationMethods = sort => (
  (dispatch) => {
    dispatch(requestCalculationMethods());
    let url = `${ENV.REACT_APP_API_SERVER}/calculation-methods`;
    if (sort) url += `?order=${JSON.stringify(sort)}`;
    return authorizedRequest(url, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
          dispatch(receivedCalculationMethods([]));
        } else if (json.status === 401) {
          dispatch(logout());
          dispatch(receivedCalculationMethods([]));
        } else {
          dispatch(receivedCalculationMethods({ data: json } || []));
        }
        return json;
      });
  }
);

export const fetchCalculationMethod = id => (
  (dispatch) => {
    dispatch(requestCalculationMethods());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/calculation-methods/${id}`, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(logout());
        } else {
          dispatch(receivedCalculationMethod(json));
        }
        return json;
      });
  }
);

export const saveCalculationMethod = (id, params) => (
  (dispatch) => {
    dispatch(requestCalculationMethods());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/calculation-methods/${id}`, { method: 'POST', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);
