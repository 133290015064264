import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class DstSetupRow extends Component {
  link() {
    return '/dst-setup/edit';
  }

  render() {
    const { dstSetup } = this.props;
    if (!dstSetup || !Object.keys(dstSetup).length) return null;
    return (
      <tr>
        <th scope="row"><Link to={this.link()}>{JSON.stringify(dstSetup.countries)}</Link></th>
        <td><Link to={this.link()}>{JSON.stringify(dstSetup.dst_set)}</Link></td>
        <td><Link to={this.link()}>{JSON.stringify(dstSetup.hijri_set)}</Link></td>
      </tr>
    );
  }
}

DstSetupRow.propTypes = {
  dstSetup: PropTypes.oneOfType([PropTypes.object]),
};
DstSetupRow.defaultProps = {
  dstSetup: {},
};
export default DstSetupRow;
