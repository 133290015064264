import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Row, Table,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { deleteMedia, fetchMedia } from '../../actions/media';
import Can from '../../components/can';
import PinModal from '../../components/pin';

class Media extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.requestDelete = this.requestDelete.bind(this);
    this.failedDelete = this.failedDelete.bind(this);
  }

  componentWillMount() {
    const { getMedia } = this.props;
    getMedia();
  }

  requestDelete(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedDelete() {
    this.setState({
      submitted: false,
    });
  }

  handleDelete() {
    const { deleteMediaAction, goToList } = this.props;
    deleteMediaAction().then(() => goToList());
  }

  render() {
    const { submitted } = this.state;
    const { media } = this.props;
    if (!media || Object.keys(media).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleDelete} failedSubmit={this.failedDelete} />
        }
        <Row>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <strong>
                  {'Media ID: '}
                  {media.mediaID}
                </strong>
                <div className="card-header-actions">
                  <Can I="update" a="Media">
                    <Button href={`/#/media/${media.mediaID}/edit`} color="link" className="card-header-action btn-setting">
                      <i className="icon-note" />
                    </Button>
                  </Can>
                  <Can I="delete" a="Media">
                    <Button color="link" className="card-header-action btn-setting" onClick={e => this.requestDelete(e)}>
                      <i className="icon-trash" />
                    </Button>
                  </Can>
                </div>
              </CardHeader>
              <CardBody>
                <Table responsive striped hover>
                  <tbody>
                    <tr key="id">
                      <td>Media ID:</td>
                      <td><strong>{media.mediaID}</strong></td>
                    </tr>
                    <tr key="title">
                      <td>Media Title:</td>
                      <td><strong>{media.title}</strong></td>
                    </tr>
                    <tr key="titleAR">
                      <td>Media Title AR:</td>
                      <td><strong>{media.titleAR}</strong></td>
                    </tr>
                    <tr key="link">
                      <td>Media Link:</td>
                      <td><strong>{media.link}</strong></td>
                    </tr>
                    <tr key="category">
                      <td>Media Category:</td>
                      <td><strong>{media.category}</strong></td>
                    </tr>
                    <tr key="url">
                      <td>Media URL:</td>
                      <td><strong>{media.url}</strong></td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter>
                <Button href={`/#/media/${media.mediaID}/edit`} color="secondary" size="sm">Edit</Button>
                {' '}
                <Button color="danger" onClick={e => this.requestDelete(e)} size="sm">Delete</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

Media.propTypes = {
  deleteMediaAction: PropTypes.func.isRequired,
  getMedia: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired,
  media: PropTypes.shape,
};

Media.defaultProps = {
  media: {},
};

const mapStateToProps = ({ media }) => ({
  media: media.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  deleteMediaAction: () => deleteMedia(ownProps.match.params.id),
  getMedia: () => fetchMedia(ownProps.match.params.id),
  goToList: () => goBack(),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Media);
