import { authorizedRequest, logout } from './account';
import { responseError } from './errors';

require('dotenv').config();

const ENV = process.env;

export const REQUEST_COUNTRIES = 'REQUEST_COUNTRIES';
export const RECEIVE_COUNTRIES = 'RECEIVE_COUNTRIES';
export const RECEIVE_COUNTRY = 'RECEIVE_COUNTRY';

export const requestCountries = () => ({
  type: REQUEST_COUNTRIES,
});

export const receivedCountries = json => ({
  type: RECEIVE_COUNTRIES,
  json,
});

export const receivedCountry = json => ({
  type: RECEIVE_COUNTRY,
  json,
});

export const createCountry = params => (
  (dispatch) => {
    dispatch(requestCountries());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/countries`, { method: 'POST', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);

export const deleteCountry = id => (
  (dispatch) => {
    dispatch(requestCountries());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/countries/${id}`, { method: 'DELETE', headers: {} })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);

export const fetchCountries = sort => (
  (dispatch) => {
    dispatch(requestCountries());
    let url = `${ENV.REACT_APP_API_SERVER}/countries`;
    if (sort) url += `?order=${JSON.stringify(sort)}`;
    return authorizedRequest(url, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
          dispatch(receivedCountries([]));
        } else if (json.status === 401) {
          dispatch(logout());
          dispatch(receivedCountries([]));
        } else {
          dispatch(receivedCountries({ data: json } || []));
        }
        return json;
      });
  }
);

export const fetchCountry = id => (
  (dispatch) => {
    dispatch(requestCountries());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/countries/${id}`, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(logout());
        } else {
          dispatch(receivedCountry(json));
        }
        return json;
      });
  }
);

export const saveCountry = (id, params) => (
  (dispatch) => {
    dispatch(requestCountries());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/countries/${id}`, { method: 'POST', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);
