/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchDstSetup, saveDstSetup } from '../../actions/dst-setup';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class DstSetupEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    const { getDstSetup } = this.props;
    getDstSetup();
  }

  handleChange(e, name) {
    const { value } = e.target;
    this.setState({ [name]: value });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const {
      dstSetup,
      saveDstSetupAction,
      goToShow,
      resetErrorAction,
    } = this.props;
    resetErrorAction();
    const {
      countries,
      dst_morocco,
      dst_state,
      hidjri_morocco,
      hidjri_saudi,
    } = this.state;
    const body = {
      countries: countries ? countries.split(',').map(c => c.trim()) : dstSetup.countries,
      dst_set: {
        Morocco: dst_morocco || dstSetup.dst_set.Morocco,
        State: dst_state || dstSetup.dst_set.State,
      },
      hijri_set: {
        Morocco: hidjri_morocco || dstSetup.hijri_set.Morocco,
        Saudi: hidjri_saudi || dstSetup.hijri_set.Saudi,
      },
    };
    Object.keys(body).forEach(key => body[key] === undefined && delete body[key]);
    saveDstSetupAction(dstSetup.id, { body: JSON.stringify(body) }).then(() => goToShow());
  }

  render() {
    const { dstSetup } = this.props;
    const {
      countries,
      dst_morocco,
      dst_state,
      hidjri_morocco,
      hidjri_saudi,
      submitted,
    } = this.state;
    if (!dstSetup || Object.keys(dstSetup).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        }
        <Row>
          <Col lg={8}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)}>
                <CardHeader>
                  <strong>
                    {'Edit DST Setup: '}
                    {dstSetup.id}
                  </strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="countries">Countries</Label>
                    <Input
                      id="countries"
                      type="text"
                      placeholder="Enter Countries"
                      defaultValue={dstSetup.countries.join(', ')}
                      invalid={submitted && !countries === ''}
                      onChange={e => this.handleChange(e, 'countries')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="dst_morocco">DST set: Morocco</Label>
                    <Input
                      id="dst_morocco"
                      type="text"
                      placeholder="Enter DST set: Morocco"
                      defaultValue={dstSetup.dst_set.Morocco}
                      invalid={submitted && !dst_morocco === ''}
                      onChange={e => this.handleChange(e, 'dst_morocco')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="dst_state">DST set: State</Label>
                    <Input
                      id="dst_state"
                      type="text"
                      placeholder="Enter DST set: State"
                      defaultValue={dstSetup.dst_set.State}
                      invalid={submitted && !dst_state === ''}
                      onChange={e => this.handleChange(e, 'dst_state')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="hidjri_morocco">Hidjri set: Morocco</Label>
                    <Input
                      id="hidjri_morocco"
                      type="text"
                      placeholder="Enter Hidjri set: Morocco"
                      defaultValue={dstSetup.hijri_set.Morocco}
                      invalid={submitted && !hidjri_morocco === ''}
                      onChange={e => this.handleChange(e, 'hidjri_morocco')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="hidjri_saudi">Hidjri set: Saudi</Label>
                    <Input
                      id="hidjri_saudi"
                      type="text"
                      placeholder="Enter Hidjri set: Saudi"
                      defaultValue={dstSetup.hijri_set.Saudi}
                      invalid={submitted && !hidjri_saudi === ''}
                      onChange={e => this.handleChange(e, 'hidjri_saudi')}
                      required
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="success">Save</Button>
                  {' '}
                  <Button href="/#/dstSetup" size="sm" color="danger">Cancel</Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

DstSetupEdit.propTypes = {
  getDstSetup: PropTypes.func.isRequired,
  goToShow: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
  saveDstSetupAction: PropTypes.func.isRequired,
  dstSetup: PropTypes.shape({
    countries: PropTypes.arrayOf(PropTypes.string),
    dst_set: PropTypes.object,
    hijri_set: PropTypes.object,
  }).isRequired,
};

const mapStateToProps = ({ dstSetup }) => ({
  dstSetup: dstSetup.items,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  getDstSetup: () => fetchDstSetup(ownProps.match.params.id),
  goToShow: () => push('/dst-setup'),
  resetErrorAction: () => resetError(),
  saveDstSetupAction: (id, body) => saveDstSetup(id, body),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DstSetupEdit);
