import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import CardForm from './index';

const SortableItem = SortableElement(
  ({
    card,
    count,
    handleChange,
    handleTypeChange,
    handleRemove,
    submitted,
  }) => (
    <li>
      <CardForm
        index={count}
        type={+card.type}
        onChange={handleChange}
        onTypeChange={handleTypeChange}
        onRemove={handleRemove}
        card={card}
        submitted={submitted}
      />
    </li>
  ),
);

export default SortableItem;
