/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { createSoundsFull } from '../../actions/sounds-full';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class SoundsFullNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.requestSubmit = this.requestSubmit.bind(this);
    this.failedSubmit = this.failedSubmit.bind(this);
  }

  handleChange(e, name) {
    const { value } = e.target;
    this.setState({ [name]: value });
  }

  handleFileChange(e, name) {
    const { files } = e.target;
    this.setState({ [name]: files[0] });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { createSoundsFullAction, goToList, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      title,
      titleAR,
      link,
      full_link,
      short_link,
      shortversion,
      longversion,
      length,
      type,
      format,
      premium,
      defaultValue,
      split,
    } = this.state;
    const body = {
      title,
      titleAR,
      link,
      full_link,
      short_link,
      shortversion,
      longversion,
      length,
      type,
      format,
      premium,
      default: defaultValue,
      split,
    };
    createSoundsFullAction({ body: JSON.stringify(body) }).then(() => {
      goToList();
    });
  }

  render() {
    const {
      title,
      titleAR,
      link,
      full_link,
      short_link,
      shortversion,
      longversion,
      length,
      type,
      format,
      split,
      submitted,
    } = this.state;
    const { goToList } = this.props;
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        }
        <Row>
          <Col lg={6}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)} encType="multipart/form-data">
                <CardHeader>
                  <strong>
                    {'New soundsFull'}
                  </strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="title">Title</Label>
                    <Input
                      id="title"
                      type="text"
                      placeholder="Enter Title"
                      invalid={submitted && title === ''}
                      onChange={e => this.handleChange(e, 'title')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="titleAR">Title AR</Label>
                    <Input
                      id="titleAR"
                      type="text"
                      placeholder="Enter Title AR"
                      invalid={submitted && titleAR === ''}
                      onChange={e => this.handleChange(e, 'titleAR')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="link">Link</Label>
                    <Input
                      id="link"
                      type="text"
                      placeholder="Enter Link"
                      invalid={submitted && link === ''}
                      onChange={e => this.handleChange(e, 'link')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="full_link">Full Link</Label>
                    <Input
                      id="full_link"
                      type="text"
                      placeholder="Enter Link"
                      invalid={submitted && full_link === ''}
                      onChange={e => this.handleChange(e, 'full_link')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="short_link">Short Link</Label>
                    <Input
                      id="short_link"
                      type="text"
                      placeholder="Enter Link"
                      invalid={submitted && short_link === ''}
                      onChange={e => this.handleChange(e, 'short_link')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="shortversion">Short Version</Label>
                    <Input
                      id="shortversion"
                      type="text"
                      placeholder="Enter Short Version"
                      invalid={submitted && shortversion === ''}
                      onChange={e => this.handleChange(e, 'shortversion')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="longversion">Long Version</Label>
                    <Input
                      id="longversion"
                      type="text"
                      placeholder="Enter Long Version"
                      invalid={submitted && longversion === ''}
                      onChange={e => this.handleChange(e, 'longversion')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="length">Length</Label>
                    <Input
                      id="length"
                      type="text"
                      placeholder="Enter Length"
                      invalid={submitted && length === ''}
                      onChange={e => this.handleChange(e, 'length')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="type">Type</Label>
                    <Input
                      id="type"
                      type="text"
                      placeholder="Enter Type"
                      invalid={submitted && type === ''}
                      onChange={e => this.handleChange(e, 'type')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="format">Format</Label>
                    <Input
                      id="format"
                      type="text"
                      placeholder="Enter Format"
                      invalid={submitted && format === ''}
                      onChange={e => this.handleChange(e, 'format')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="premium">Premium</Label>
                    <Input
                      type="select"
                      name="premium"
                      id="premium"
                      onChange={e => this.handleChange(e, 'premium')}
                    >
                      <option>true</option>
                      <option>false</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="split">Split</Label>
                    <Input
                      id="split"
                      type="number"
                      placeholder="Enter Split"
                      invalid={submitted && split === ''}
                      onChange={e => this.handleChange(e, 'split')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="default">Default</Label>
                    <Input
                      type="select"
                      name="default"
                      id="default"
                      onChange={e => this.handleChange(e, 'defaultValue')}
                    >
                      <option>false</option>
                      <option>true</option>
                    </Input>
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="primary">Create</Button>
                  {' '}
                  <Button onClick={() => goToList()} size="sm" color="danger">Cancel</Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

SoundsFullNew.propTypes = {
  createSoundsFullAction: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  createSoundsFullAction: body => createSoundsFull(body),
  goToList: () => push('/sounds-full'),
  resetErrorAction: () => resetError(),
}, dispatch);

export default connect(
  null,
  mapDispatchToProps,
)(SoundsFullNew);
