import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardHeader, Col, Row,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchCountries } from '../../actions/countries';
import DataTable from '../../components/data-table';

const COLUMNS = [
  { id: 'id', label: 'ID', link: true },
  { id: 'name', label: 'Name', link: true },
  { id: 'continent.name', label: 'Continent' },
  { id: 'ISOcountryCode', label: 'ISO Country Code' },
  { id: 'daylightSaving', label: 'Daylight Saving' },
  { id: 'calculationMethod.name', label: 'Calculation Method' },
];

const Countries = (props) => {
  const { getCountries } = props;
  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <Row>
                <Col lg={4}>
                  Countries
                </Col>
                <Col lg={8} style={{ textAlign: 'right' }}>
                  <Button href="/#/countries/new" color="success" size="sm">New</Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <DataTable resource="countries" dataSource={getCountries} columns={COLUMNS} {...props} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

Countries.propTypes = {
  getCountries: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object),
};

Countries.defaultProps = {
  countries: [],
};

const mapStateToProps = ({ countries }) => ({
  loading: countries.loading,
  countries: countries.items,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getCountries: sort => fetchCountries(sort),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Countries);
