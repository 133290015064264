import {
  REQUEST_HIDJRI, RECEIVE_HIDJRI,
} from '../actions/hidjri';

const reducer = (state = {
  items: [],
  loading: false,
  item: {},
}, action) => {
  switch (action.type) {
    case REQUEST_HIDJRI:
      return { ...state, loading: true };
    case RECEIVE_HIDJRI:
      return { ...state, items: action.json.data[0], loading: false };
    default:
      return state;
  }
};

export default reducer;
