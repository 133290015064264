import Home from './containers/home';
import Import from './containers/import/new';
import Hidjri from './containers/hidjri';
import HidjriEdit from './containers/hidjri/edit';
import DstSetup from './containers/dst-setup';
import DstSetupEdit from './containers/dst-setup/edit';
import Continent from './containers/continents/show';
import ContinentNew from './containers/continents/new';
import ContinentEdit from './containers/continents/edit';
import Continents from './containers/continents';
import Country from './containers/countries/show';
import CountryNew from './containers/countries/new';
import CountryEdit from './containers/countries/edit';
import Countries from './containers/countries';
import City from './containers/cities/show';
import CityNew from './containers/cities/new';
import CityEdit from './containers/cities/edit';
import Cities from './containers/cities';
import Mosque from './containers/mosques/show';
import MosqueNew from './containers/mosques/new';
import MosqueEdit from './containers/mosques/edit';
import Mosques from './containers/mosques';
import CalculationMethod from './containers/calculation-methods/show';
import CalculationMethodNew from './containers/calculation-methods/new';
import CalculationMethodEdit from './containers/calculation-methods/edit';
import CalculationMethods from './containers/calculation-methods';
import DaylightSaving from './containers/daylight-savings/show';
import DaylightSavingNew from './containers/daylight-savings/new';
import DaylightSavingEdit from './containers/daylight-savings/edit';
import DaylightSavings from './containers/daylight-savings';
import SoundsFullShow from './containers/sounds-full/show';
import SoundsFullNew from './containers/sounds-full/new';
import SoundsFullEdit from './containers/sounds-full/edit';
import SoundsFulls from './containers/sounds-full';
import PromotionalAppShow from './containers/promotional-apps/show';
import PromotionalAppNew from './containers/promotional-apps/new';
import PromotionalAppEdit from './containers/promotional-apps/edit';
import PromotionalApps from './containers/promotional-apps';
import ThemeShow from './containers/themes/show';
import ThemeNew from './containers/themes/new';
import ThemeEdit from './containers/themes/edit';
import Themes from './containers/themes';
import WallpaperShow from './containers/wallpapers/show';
import WallpaperNew from './containers/wallpapers/new';
import WallpaperEdit from './containers/wallpapers/edit';
import Wallpapers from './containers/wallpapers';
import MediaShow from './containers/media/show';
import MediaNew from './containers/media/new';
import MediaEdit from './containers/media/edit';
import Medias from './containers/media';
import TodayShow from './containers/today/show';
import TodayNew from './containers/today/new';
import TodayEdit from './containers/today/edit';
import Todays from './containers/today';
import CalendarShow from './containers/calendar/show';
import CalendarNew from './containers/calendar/new';
import CalendarEdit from './containers/calendar/edit';
import Calendars from './containers/calendar';

const routes = [
  {
    path: '/', exact: true, name: 'Home', component: Home,
  },
  {
    path: '/import', exact: true, name: 'Import', component: Import,
  },
  {
    path: '/hidjri', exact: true, name: 'Hidjri', component: Hidjri,
  },
  {
    path: '/hidjri/edit', exact: true, name: 'Hidjri Edit', component: HidjriEdit,
  },
  {
    path: '/dst-setup', exact: true, name: 'DstSetup', component: DstSetup,
  },
  {
    path: '/dst-setup/edit', exact: true, name: 'DstSetup Edit', component: DstSetupEdit,
  },
  {
    path: '/sounds-full', exact: true, name: 'SoundsFulls', component: SoundsFulls,
  },
  {
    path: '/sounds-full/new', exact: true, name: 'SoundsFull New', component: SoundsFullNew,
  },
  {
    path: '/sounds-full/:id', exact: true, name: 'SoundsFull Details', component: SoundsFullShow,
  },
  {
    path: '/sounds-full/:id/edit', exact: true, name: 'SoundsFull Edit', component: SoundsFullEdit,
  },
  {
    path: '/promotional-apps', exact: true, name: 'PromotionalApps', component: PromotionalApps,
  },
  {
    path: '/promotional-apps/new', exact: true, name: 'PromotionalApp New', component: PromotionalAppNew,
  },
  {
    path: '/promotional-apps/:id', exact: true, name: 'PromotionalApp Details', component: PromotionalAppShow,
  },
  {
    path: '/promotional-apps/:id/edit', exact: true, name: 'PromotionalApp Edit', component: PromotionalAppEdit,
  },
  {
    path: '/themes', exact: true, name: 'Themes', component: Themes,
  },
  {
    path: '/themes/new', exact: true, name: 'Theme New', component: ThemeNew,
  },
  {
    path: '/themes/:id', exact: true, name: 'Theme Details', component: ThemeShow,
  },
  {
    path: '/themes/:id/edit', exact: true, name: 'Theme Edit', component: ThemeEdit,
  },
  {
    path: '/wallpapers', exact: true, name: 'Wallpapers', component: Wallpapers,
  },
  {
    path: '/wallpapers/new', exact: true, name: 'Wallpaper New', component: WallpaperNew,
  },
  {
    path: '/wallpapers/:id', exact: true, name: 'Wallpaper Details', component: WallpaperShow,
  },
  {
    path: '/wallpapers/:id/edit', exact: true, name: 'Wallpaper Edit', component: WallpaperEdit,
  },
  {
    path: '/continents', exact: true, name: 'Continents', component: Continents,
  },
  {
    path: '/continents/new', exact: true, name: 'Continent New', component: ContinentNew,
  },
  {
    path: '/continents/:id', exact: true, name: 'Continent Details', component: Continent,
  },
  {
    path: '/continents/:id/edit', exact: true, name: 'Continent Edit', component: ContinentEdit,
  },
  {
    path: '/countries', exact: true, name: 'Countries', component: Countries,
  },
  {
    path: '/countries/new', exact: true, name: 'Country New', component: CountryNew,
  },
  {
    path: '/countries/:id', exact: true, name: 'Country Details', component: Country,
  },
  {
    path: '/countries/:id/edit', exact: true, name: 'Country Edit', component: CountryEdit,
  },
  {
    path: '/cities', exact: true, name: 'Cities', component: Cities,
  },
  {
    path: '/cities/new', exact: true, name: 'City New', component: CityNew,
  },
  {
    path: '/cities/:id', exact: true, name: 'City Details', component: City,
  },
  {
    path: '/cities/:id/edit', exact: true, name: 'City Edit', component: CityEdit,
  },
  {
    path: '/mosques', exact: true, name: 'Mosques', component: Mosques,
  },
  {
    path: '/mosques/new', exact: true, name: 'Mosque New', component: MosqueNew,
  },
  {
    path: '/mosques/:id', exact: true, name: 'Mosque Details', component: Mosque,
  },
  {
    path: '/mosques/:id/edit', exact: true, name: 'Mosque Edit', component: MosqueEdit,
  },
  {
    path: '/calculation-methods', exact: true, name: 'CalculationMethods', component: CalculationMethods,
  },
  {
    path: '/calculation-methods/new', exact: true, name: 'CalculationMethod New', component: CalculationMethodNew,
  },
  {
    path: '/calculation-methods/:id', exact: true, name: 'CalculationMethod Details', component: CalculationMethod,
  },
  {
    path: '/calculation-methods/:id/edit', exact: true, name: 'CalculationMethod Edit', component: CalculationMethodEdit,
  },
  {
    path: '/daylight-savings', exact: true, name: 'DaylightSavings', component: DaylightSavings,
  },
  {
    path: '/daylight-savings/new', exact: true, name: 'DaylightSaving New', component: DaylightSavingNew,
  },
  {
    path: '/daylight-savings/:id', exact: true, name: 'DaylightSaving Details', component: DaylightSaving,
  },
  {
    path: '/daylight-savings/:id/edit', exact: true, name: 'DaylightSaving Edit', component: DaylightSavingEdit,
  },
  {
    path: '/media', exact: true, name: 'Media', component: Medias,
  },
  {
    path: '/media/new', exact: true, name: 'Media New', component: MediaNew,
  },
  {
    path: '/media/:id', exact: true, name: 'Media Details', component: MediaShow,
  },
  {
    path: '/media/:id/edit', exact: true, name: 'Media Edit', component: MediaEdit,
  },
  {
    path: '/calendar', exact: true, name: 'Calendar', component: Calendars,
  },
  {
    path: '/calendar/new', exact: true, name: 'Calendar New', component: CalendarNew,
  },
  {
    path: '/calendar/:id', exact: true, name: 'Calendar Details', component: CalendarShow,
  },
  {
    path: '/calendar/:id/edit', exact: true, name: 'Calendar Edit', component: CalendarEdit,
  },
  {
    path: '/today', exact: true, name: 'Today', component: Todays,
  },
  {
    path: '/today/new', exact: true, name: 'Today New', component: TodayNew,
  },
  {
    path: '/today/:id', exact: true, name: 'Today Details', component: TodayShow,
  },
  {
    path: '/today/:id/edit', exact: true, name: 'Today Edit', component: TodayEdit,
  },
];

export default routes;
