/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { createCalculationMethod } from '../../actions/calculation-methods';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class CalculationMethodNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.requestSubmit = this.requestSubmit.bind(this);
    this.failedSubmit = this.failedSubmit.bind(this);
  }

  handleChange(e, name) {
    const { value } = e.target;
    this.setState({ [name]: value });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { createCalculationMethodAction, goToList, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      name,
      name_ar,
      code,
      fajr_angle,
      isha_angle,
      manualAdjustmentFajr,
      manualAdjustmentSunrise,
      manualAdjustmentDhuhr,
      manualAdjustmentAsr,
      manualAdjustmentMaghrib,
      manualAdjustmentIsha,
      version,
    } = this.state;
    const body = {
      name,
      name_ar,
      code,
      fajr_angle,
      isha_angle,
      manualAdjustmentFajr,
      manualAdjustmentSunrise,
      manualAdjustmentDhuhr,
      manualAdjustmentAsr,
      manualAdjustmentMaghrib,
      manualAdjustmentIsha,
      version,
    };
    Object.keys(body).forEach(key => body[key] === undefined && delete body[key]);
    createCalculationMethodAction({ body: JSON.stringify(body) }).then(() => {
      goToList();
    });
  }

  render() {
    const {
      name,
      name_ar,
      code,
      fajr_angle,
      isha_angle,
      manualAdjustmentFajr,
      manualAdjustmentSunrise,
      manualAdjustmentDhuhr,
      manualAdjustmentAsr,
      manualAdjustmentMaghrib,
      manualAdjustmentIsha,
      version,
      submitted,
    } = this.state;
    const { goToList } = this.props;
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        }
        <Row>
          <Col lg={6}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)}>
                <CardHeader>
                  <strong>
                    {'New Calculation Method'}
                  </strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="name">CalculationMethod Name</Label>
                    <Input
                      id="name"
                      type="text"
                      placeholder="Enter CalculationMethod Name"
                      invalid={submitted && name === ''}
                      onChange={e => this.handleChange(e, 'name')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="name_ar">CalculationMethod Name Arabic</Label>
                    <Input
                      id="name_ar"
                      type="text"
                      placeholder="Enter CalculationMethod Name Arabic"
                      invalid={submitted && name_ar === ''}
                      onChange={e => this.handleChange(e, 'name_ar')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="code">Code</Label>
                    <Input
                      id="code"
                      type="text"
                      placeholder="Enter Code"
                      invalid={submitted && code === ''}
                      onChange={e => this.handleChange(e, 'code')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="fajr_angle">Fajr Angle</Label>
                    <Input
                      id="fajr_angle"
                      type="text"
                      placeholder="Enter Fajr Angle"
                      invalid={submitted && fajr_angle === ''}
                      onChange={e => this.handleChange(e, 'fajr_angle')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="isha_angle">Isha Angle</Label>
                    <Input
                      id="isha_angle"
                      type="text"
                      placeholder="Enter Isha Angle"
                      invalid={submitted && isha_angle === ''}
                      onChange={e => this.handleChange(e, 'isha_angle')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="manualAdjustmentFajr">Manual Adjustment Fajr</Label>
                    <Input
                      id="manualAdjustmentFajr"
                      type="text"
                      placeholder="Enter Manual Adjustment Fajr"
                      invalid={submitted && manualAdjustmentFajr === ''}
                      onChange={e => this.handleChange(e, 'manualAdjustmentFajr')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="manualAdjustmentSunrise">Manual Adjustment Sunrise</Label>
                    <Input
                      id="manualAdjustmentSunrise"
                      type="text"
                      placeholder="Enter Manual Adjustment Sunrise"
                      invalid={submitted && manualAdjustmentSunrise === ''}
                      onChange={e => this.handleChange(e, 'manualAdjustmentSunrise')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="manualAdjustmentDhuhr">Manual Adjustment Dhuhr</Label>
                    <Input
                      id="manualAdjustmentDhuhr"
                      type="text"
                      placeholder="Enter Manual Adjustment Dhuhr"
                      invalid={submitted && manualAdjustmentDhuhr === ''}
                      onChange={e => this.handleChange(e, 'manualAdjustmentDhuhr')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="manualAdjustmentAsr">Manual Adjustment Asr</Label>
                    <Input
                      id="manualAdjustmentAsr"
                      type="text"
                      placeholder="Enter Manual Adjustment Asr"
                      invalid={submitted && manualAdjustmentAsr === ''}
                      onChange={e => this.handleChange(e, 'manualAdjustmentAsr')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="manualAdjustmentMaghrib">Manual Adjustment Mahrib</Label>
                    <Input
                      id="manualAdjustmentMaghrib"
                      type="text"
                      placeholder="Enter Manual Adjustment Mahrib"
                      invalid={submitted && manualAdjustmentMaghrib === ''}
                      onChange={e => this.handleChange(e, 'manualAdjustmentMaghrib')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="manualAdjustmentIsha">Manual Adjustment Isha</Label>
                    <Input
                      id="manualAdjustmentIsha"
                      type="text"
                      placeholder="Enter Manual Adjustment Isha"
                      invalid={submitted && manualAdjustmentIsha === ''}
                      onChange={e => this.handleChange(e, 'manualAdjustmentIsha')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="version">Version</Label>
                    <Input
                      id="version"
                      type="number"
                      placeholder="Enter Version"
                      invalid={submitted && version === ''}
                      onChange={e => this.handleChange(e, 'version')}
                      required
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="primary">Create</Button>
                  {' '}
                  <Button onClick={() => goToList()} size="sm" color="danger">Cancel</Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

CalculationMethodNew.propTypes = {
  createCalculationMethodAction: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
};
const mapDispatchToProps = dispatch => bindActionCreators({
  createCalculationMethodAction: body => createCalculationMethod(body),
  goToList: () => push('/calculation-methods'),
  resetErrorAction: () => resetError(),
}, dispatch);

export default connect(
  null,
  mapDispatchToProps,
)(CalculationMethodNew);
