import {
  REQUEST_COUNTRIES, RECEIVE_COUNTRIES, RECEIVE_COUNTRY,
} from '../actions/countries';

const reducer = (state = {
  items: [],
  loading: false,
  item: {},
}, action) => {
  switch (action.type) {
    case REQUEST_COUNTRIES:
      return { ...state, loading: true };
    case RECEIVE_COUNTRIES:
      return { ...state, items: action.json.data, loading: false };
    case RECEIVE_COUNTRY:
      return {
        ...state,
        item: action.json,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
