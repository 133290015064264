/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchCity, saveCity } from '../../actions/cities';
import { fetchCountries } from '../../actions/countries';
import { fetchCalculationMethods } from '../../actions/calculation-methods';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class CityEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.failedSubmit = this.failedSubmit.bind(this);
  }

  componentWillMount() {
    const { getCity, getCoutries, getCalculationMethods } = this.props;
    getCity();
    getCoutries();
    getCalculationMethods();
  }

  handleChange(e, name) {
    const { value } = e.target;
    this.setState({ [name]: value });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { saveCityAction, goToShow, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      country_id,
      name,
      name_arabic,
      code,
      state,
      latitude,
      longitude,
      version,
      calculation_methods_id,
    } = this.state;
    if (!name && !name_arabic && !country_id && !code && !state
      && !calculation_methods_id && !latitude && !longitude && !version) {
      goToShow();
      return;
    }
    const body = {
      country_id,
      name,
      name_arabic,
      code,
      state,
      latitude,
      longitude,
      version,
      calculation_methods_id,
    };
    Object.keys(body).forEach(key => body[key] === undefined && delete body[key]);
    const { city } = this.props;
    saveCityAction(city.id, { body: JSON.stringify(body) }).then(() => goToShow());
  }

  render() {
    const { city, countries, calculationMethods } = this.props;
    const {
      name,
      name_arabic,
      code,
      state,
      latitude,
      longitude,
      version,
      submitted,
    } = this.state;
    if (!city || Object.keys(city).length === 0
    || !countries.length || !calculationMethods.length) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        }
        <Row>
          <Col lg={8}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)} encType="multipart/form-data">
                <CardHeader>
                  <strong>
                    {'Edit city: '}
                    {city.id}
                  </strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label for="country_id">Country</Label>
                    <Input
                      type="select"
                      name="country_id"
                      id="country_id"
                      onChange={e => this.handleChange(e, 'country_id')}
                      defaultValue={city.country_id}
                    >
                      {
                        countries.map(country => (
                          <option
                            key={country.id}
                            value={country.id}
                          >
                            { country.name }
                          </option>
                        ))
                      }
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="name">City Name</Label>
                    <Input
                      id="name"
                      type="text"
                      placeholder="Enter City Name"
                      defaultValue={city.name}
                      invalid={submitted && name === ''}
                      onChange={e => this.handleChange(e, 'name')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="name_arabic">City Name Arabic</Label>
                    <Input
                      id="name_arabic"
                      type="text"
                      placeholder="Enter City Name Arabic"
                      defaultValue={city.name_arabic}
                      invalid={submitted && name_arabic === ''}
                      onChange={e => this.handleChange(e, 'name_arabic')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="calculation_methods_id">Calculation Method</Label>
                    <Input
                      type="select"
                      name="calculation_methods_id"
                      id="calculation_methods_id"
                      onChange={e => this.handleChange(e, 'calculation_methods_id')}
                      defaultValue={city.calculation_methods_id}
                    >
                      {
                        calculationMethods.map(calculationMethod => (
                          <option
                            key={calculationMethod.id}
                            value={calculationMethod.id}
                          >
                            { calculationMethod.name }
                          </option>
                        ))
                      }
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="code">Code</Label>
                    <Input
                      id="code"
                      type="text"
                      placeholder="Enter Code"
                      defaultValue={city.code}
                      invalid={submitted && code === ''}
                      onChange={e => this.handleChange(e, 'code')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="state">State</Label>
                    <Input
                      id="state"
                      type="text"
                      placeholder="Enter State"
                      defaultValue={city.state}
                      invalid={submitted && state === ''}
                      onChange={e => this.handleChange(e, 'state')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="latitude">Latitude</Label>
                    <Input
                      id="latitude"
                      type="text"
                      placeholder="Enter Latitude"
                      defaultValue={city.latitude}
                      invalid={submitted && latitude === ''}
                      onChange={e => this.handleChange(e, 'latitude')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="longitude">Longitude</Label>
                    <Input
                      id="longitude"
                      type="text"
                      placeholder="Enter Longitude"
                      defaultValue={city.longitude}
                      invalid={submitted && longitude === ''}
                      onChange={e => this.handleChange(e, 'longitude')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="version">Version</Label>
                    <Input
                      id="version"
                      type="number"
                      placeholder="Enter Version"
                      defaultValue={city.version}
                      invalid={submitted && version === ''}
                      onChange={e => this.handleChange(e, 'version')}
                      required
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="success">Save</Button>
                  {' '}
                  <Button href={`/#/cities/${city.id}`} size="sm" color="danger">Cancel</Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

CityEdit.propTypes = {
  getCity: PropTypes.func.isRequired,
  getCoutries: PropTypes.func.isRequired,
  getCalculationMethods: PropTypes.func.isRequired,
  goToShow: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
  saveCityAction: PropTypes.func.isRequired,
  city: PropTypes.shape.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object),
  calculationMethods: PropTypes.arrayOf(PropTypes.object),
};

CityEdit.defaultProps = {
  countries: [],
  calculationMethods: [],
};

const mapStateToProps = ({ cities, countries, calculationMethods }) => ({
  city: cities.item,
  countries: countries.items,
  calculationMethods: calculationMethods.items,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  getCity: () => fetchCity(ownProps.match.params.id),
  getCoutries: () => fetchCountries(),
  getCalculationMethods: () => fetchCalculationMethods(),
  goToShow: () => push(`/cities/${ownProps.match.params.id}`),
  resetErrorAction: () => resetError(),
  saveCityAction: (id, body) => saveCity(id, body),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CityEdit);
