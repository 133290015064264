import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Row, Table,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { deleteWallpaper, fetchWallpaper } from '../../actions/wallpapers';
import Can from '../../components/can';
import PinModal from '../../components/pin';

class Wallpaper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.requestDelete = this.requestDelete.bind(this);
    this.failedDelete = this.failedDelete.bind(this);
  }

  componentWillMount() {
    const { getWallpaper } = this.props;
    getWallpaper();
  }

  requestDelete(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedDelete() {
    this.setState({
      submitted: false,
    });
  }

  handleDelete() {
    const { deleteWallpaperAction, goToList } = this.props;
    deleteWallpaperAction().then(() => goToList());
  }

  render() {
    const { submitted } = this.state;
    const { wallpaper } = this.props;
    if (!wallpaper || Object.keys(wallpaper).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleDelete} failedSubmit={this.failedDelete} />
        }
        <Row>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <strong>
                  {'Wallpaper ID: '}
                  {wallpaper.wallpaperID}
                </strong>
                <div className="card-header-actions">
                  <Can I="update" a="Wallpaper">
                    <Button href={`/#/wallpapers/${wallpaper.wallpaperID}/edit`} color="link" className="card-header-action btn-setting">
                      <i className="icon-note" />
                    </Button>
                  </Can>
                  <Can I="delete" a="Wallpaper">
                    <Button color="link" className="card-header-action btn-setting" onClick={e => this.requestDelete(e)}>
                      <i className="icon-trash" />
                    </Button>
                  </Can>
                </div>
              </CardHeader>
              <CardBody>
                <Table responsive striped hover>
                  <tbody>
                    <tr key="id">
                      <td>ID:</td>
                      <td><strong>{wallpaper.wallpaperID}</strong></td>
                    </tr>
                    <tr key="title">
                      <td>Title:</td>
                      <td><strong>{wallpaper.title}</strong></td>
                    </tr>
                    <tr key="link">
                      <td>Link:</td>
                      <td><strong>{wallpaper.link}</strong></td>
                    </tr>
                    <tr key="filename">
                      <td>Filename:</td>
                      <td><strong>{wallpaper.filename}</strong></td>
                    </tr>
                    <tr key="thumbnail">
                      <td>Thumbnail:</td>
                      <td><strong>{wallpaper.thumbnail}</strong></td>
                    </tr>
                    <tr key="preview">
                      <td>Preview:</td>
                      <td><strong>{wallpaper.preview}</strong></td>
                    </tr>
                    <tr key="category">
                      <td>Category:</td>
                      <td><strong>{wallpaper.category}</strong></td>
                    </tr>
                    <tr key="device">
                      <td>Device:</td>
                      <td><strong>{wallpaper.device}</strong></td>
                    </tr>
                    <tr key="type">
                      <td>Type:</td>
                      <td><strong>{wallpaper.type}</strong></td>
                    </tr>
                    <tr key="format">
                      <td>Format:</td>
                      <td><strong>{wallpaper.format}</strong></td>
                    </tr>
                    <tr key="premium">
                      <td>Premium:</td>
                      <td><strong>{wallpaper.premium.toString()}</strong></td>
                    </tr>
                    <tr key="default">
                      <td>Default:</td>
                      <td><strong>{wallpaper.default.toString()}</strong></td>
                    </tr>
                    <tr key="credit">
                      <td>Credit:</td>
                      <td><strong>{wallpaper.credit}</strong></td>
                    </tr>
                    <tr key="credit_link">
                      <td>Credit Link:</td>
                      <td><strong>{wallpaper.credit_link}</strong></td>
                    </tr>
                    <tr key="twoTones">
                      <td>2 Tones:</td>
                      <td><strong>{wallpaper.twoTones.toString()}</strong></td>
                    </tr>
                    <tr key="darkVariation">
                      <td>Dark Variation:</td>
                      <td><strong>{wallpaper.darkVariation}</strong></td>
                    </tr>
                    <tr key="alternative">
                      <td>Alternative:</td>
                      <td><strong>{wallpaper.alternative.toString()}</strong></td>
                    </tr>
                    <tr key="filename_alt">
                      <td>Filename Alt:</td>
                      <td><strong>{wallpaper.filename_alt}</strong></td>
                    </tr>
                    <tr key="darkVariation_alt">
                      <td>Dark Variation Alt:</td>
                      <td><strong>{wallpaper.darkVariation_alt}</strong></td>
                    </tr>
                    <tr key="thumbnail_alt">
                      <td>Thumbnail Alt:</td>
                      <td><strong>{wallpaper.thumbnail_alt}</strong></td>
                    </tr>
                    <tr key="order">
                      <td>Order:</td>
                      <td><strong>{wallpaper.order}</strong></td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter>
                <Button href={`/#/wallpapers/${wallpaper.wallpaperID}/edit`} color="secondary" size="sm">Edit</Button>
                {' '}
                <Button color="danger" onClick={e => this.requestDelete(e)} size="sm">Delete</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

Wallpaper.propTypes = {
  deleteWallpaperAction: PropTypes.func.isRequired,
  getWallpaper: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired,
  wallpaper: PropTypes.shape,
};

Wallpaper.defaultProps = {
  wallpaper: {},
};

const mapStateToProps = ({ wallpapers }) => ({
  wallpaper: wallpapers.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  deleteWallpaperAction: () => deleteWallpaper(ownProps.match.params.id),
  getWallpaper: () => fetchWallpaper(ownProps.match.params.id),
  goToList: () => goBack(),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Wallpaper);
