/* eslint-disable camelcase */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { createCountry } from '../../actions/countries';
import { fetchContinents } from '../../actions/continents';
import { fetchCalculationMethods } from '../../actions/calculation-methods';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class CountryNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.requestSubmit = this.requestSubmit.bind(this);
    this.failedSubmit = this.failedSubmit.bind(this);
  }

  componentWillMount() {
    const { getContinents, getCalculationMethods } = this.props;
    getContinents();
    getCalculationMethods();
  }

  handleChange(e, name) {
    const { value } = e.target;
    this.setState({ [name]: value });
  }

  handleFileChange(e, name) {
    const { files } = e.target;
    this.setState({ [name]: files[0] });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { createCountryAction, goToList, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      name,
      continent_id,
      ISOcountryCode,
      daylightSaving,
      calculation_method_id,
    } = this.state;
    if (!name && !continent_id && !ISOcountryCode && !daylightSaving && !calculation_method_id) {
      return;
    }
    const body = {
      name,
      continent_id,
      ISOcountryCode,
      daylightSaving,
      calculation_method_id,
    };
    createCountryAction({ body: JSON.stringify(body) }).then(() => {
      goToList();
    });
  }

  render() {
    const {
      name, submitted, ISOcountryCode, daylightSaving,
    } = this.state;
    const { continents, calculationMethods, goToList } = this.props;
    if (!continents.length || !calculationMethods.length) {
      return null;
    }
    return (
      <div className="animated fadeIn">
        {submitted && (
          <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        )}
        <Row>
          <Col lg={6}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)} encType="multipart/form-data">
                <CardHeader>
                  <strong>New country</strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="name">Country Name</Label>
                    <Input
                      id="name"
                      type="text"
                      placeholder="Enter Country Name"
                      invalid={submitted && name === ''}
                      onChange={e => this.handleChange(e, 'name')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="continent_id">Continent</Label>
                    <Input
                      type="select"
                      name="continent_id"
                      id="continent_id"
                      onChange={e => this.handleChange(e, 'continent_id')}
                      required
                    >
                      <Fragment>
                        <option />
                        {continents.map(continent => (
                          <option key={continent.id} value={continent.id}>
                            {continent.name}
                          </option>
                        ))}
                      </Fragment>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="ISOcountryCode">ISO Country Code</Label>
                    <Input
                      id="ISOcountryCode"
                      type="text"
                      placeholder="Enter ISO Country Code"
                      invalid={submitted && ISOcountryCode === ''}
                      onChange={e => this.handleChange(e, 'ISOcountryCode')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="daylightSaving">Daylight Saving</Label>
                    <Input
                      id="daylightSaving"
                      type="text"
                      placeholder="Enter Daylight Saving"
                      invalid={submitted && daylightSaving === ''}
                      onChange={e => this.handleChange(e, 'daylightSaving')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="calculation_method_id">Calculation Method</Label>
                    <Input
                      type="select"
                      name="calculation_method_id"
                      id="calculation_method_id"
                      onChange={e => this.handleChange(e, 'calculation_method_id')}
                      required
                    >
                      {calculationMethods.map(calculationMethod => (
                        <option key={calculationMethod.id} value={calculationMethod.id}>
                          {calculationMethod.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="primary">
                    Create
                  </Button>
                  {' '}
                  <Button onClick={() => goToList()} size="sm" color="danger">
                    Cancel
                  </Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

CountryNew.propTypes = {
  getContinents: PropTypes.func.isRequired,
  getCalculationMethods: PropTypes.func.isRequired,
  createCountryAction: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
  continents: PropTypes.arrayOf(PropTypes.object),
  calculationMethods: PropTypes.arrayOf(PropTypes.object),
};

CountryNew.defaultProps = {
  continents: [],
  calculationMethods: [],
};

const mapStateToProps = ({ continents, calculationMethods }) => ({
  continents: continents.items,
  calculationMethods: calculationMethods.items,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getContinents: () => fetchContinents(),
    getCalculationMethods: () => fetchCalculationMethods(),
    createCountryAction: body => createCountry(body),
    goToList: () => push('/countries'),
    resetErrorAction: () => resetError(),
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CountryNew);
