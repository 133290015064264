/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { createMedia } from '../../actions/media';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class MediaNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.requestSubmit = this.requestSubmit.bind(this);
    this.failedSubmit = this.failedSubmit.bind(this);
  }

  handleChange(e, name) {
    const { value } = e.target;
    this.setState({ [name]: value });
  }

  handleFileChange(e, name) {
    const { files } = e.target;
    this.setState({ [name]: files[0] });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { createMediaAction, goToList, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      title,
      titleAR,
      link,
      category,
      url,
    } = this.state;
    const body = {
      title,
      titleAR,
      link,
      category,
      url,
    };
    createMediaAction({ body: JSON.stringify(body) }).then(() => {
      goToList();
    });
  }

  render() {
    const {
      title,
      titleAR,
      link,
      category,
      url,
      submitted,
    } = this.state;
    const { goToList } = this.props;
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        }
        <Row>
          <Col lg={6}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)} encType="multipart/form-data">
                <CardHeader>
                  <strong>
                    {'New media'}
                  </strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="title">Media Title</Label>
                    <Input
                      id="title"
                      type="text"
                      placeholder="Enter Media Title"
                      invalid={submitted && title === ''}
                      onChange={e => this.handleChange(e, 'title')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="titleAR">Media Title AR</Label>
                    <Input
                      id="titleAR"
                      type="text"
                      placeholder="Enter Media Title AR"
                      invalid={submitted && titleAR === ''}
                      onChange={e => this.handleChange(e, 'titleAR')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="link">Media Link</Label>
                    <Input
                      id="link"
                      type="text"
                      placeholder="Enter Media Link"
                      invalid={submitted && link === ''}
                      onChange={e => this.handleChange(e, 'link')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="category">Media Category</Label>
                    <Input
                      id="category"
                      type="text"
                      placeholder="Enter Media Category"
                      invalid={submitted && category === ''}
                      onChange={e => this.handleChange(e, 'category')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="url">Media URL</Label>
                    <Input
                      id="url"
                      type="text"
                      placeholder="Enter Media URL"
                      invalid={submitted && url === ''}
                      onChange={e => this.handleChange(e, 'url')}
                      required
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="primary">Create</Button>
                  {' '}
                  <Button onClick={() => goToList()} size="sm" color="danger">Cancel</Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

MediaNew.propTypes = {
  createMediaAction: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  createMediaAction: body => createMedia(body),
  goToList: () => push('/media'),
  resetErrorAction: () => resetError(),
}, dispatch);

export default connect(
  null,
  mapDispatchToProps,
)(MediaNew);
