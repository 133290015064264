import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardHeader, Col, Row,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchContinents } from '../../actions/continents';
import DataTable from '../../components/data-table';

const COLUMNS = [
  { id: 'id', label: 'ID', link: true },
  { id: 'name', label: 'Name', link: true },
];

const Continents = (props) => {
  const { getContinents } = props;
  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <Row>
                <Col lg={4}>Continents</Col>
                <Col lg={8} style={{ textAlign: 'right' }}>
                  <Button href="/#/continents/new" color="success" size="sm">
                    New
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <DataTable
                resource="continents"
                dataSource={getContinents}
                columns={COLUMNS}
                {...props}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

Continents.propTypes = {
  getContinents: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  continents: PropTypes.arrayOf(PropTypes.object),
};

Continents.defaultProps = {
  continents: [],
};

const mapStateToProps = ({ continents }) => ({
  loading: continents.loading,
  continents: continents.items,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getContinents: sort => fetchContinents(sort),
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Continents);
