import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardHeader, Col, Row,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchSoundsFulls } from '../../actions/sounds-full';
import DataTable from '../../components/data-table';

const COLUMNS = [
  { id: 'soundID', label: 'ID', link: true },
  { id: 'title', label: 'Title', link: true },
  { id: 'titleAR', label: 'Title AR', link: true },
  { id: 'longversion', label: 'Long Version' },
  { id: 'length', label: 'Length' },
  { id: 'type', label: 'Type' },
  { id: 'default', label: 'Default', boolean: true },
];

const SoundsFulls = (props) => {
  const { getSoundsFulls } = props;
  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <Row>
                <Col lg={4}>
                    SoundsFulls
                </Col>
                <Col lg={8} style={{ textAlign: 'right' }}>
                  <Button href="/#/sounds-full/new" color="success" size="sm">New</Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <DataTable
                resource="soundsFulls"
                dataSource={getSoundsFulls}
                columns={COLUMNS}
                urlSchema="sounds-full"
                {...props}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

SoundsFulls.propTypes = {
  getSoundsFulls: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  soundsFulls: PropTypes.arrayOf(PropTypes.object),
};

SoundsFulls.defaultProps = {
  soundsFulls: [],
};

const mapStateToProps = ({ soundsFulls }) => ({
  loading: soundsFulls.loading,
  soundsFulls: soundsFulls.items,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getSoundsFulls: sort => fetchSoundsFulls(sort),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SoundsFulls);
