import { authorizedRequest, logout } from './account';
import { responseError } from './errors';

require('dotenv').config();

const ENV = process.env;

export const REQUEST_MEDIAS = 'REQUEST_MEDIAS';
export const RECEIVE_MEDIAS = 'RECEIVE_MEDIAS';
export const RECEIVE_MEDIA = 'RECEIVE_MEDIA';

export const requestMedias = () => ({
  type: REQUEST_MEDIAS,
});

export const receivedMedias = json => ({
  type: RECEIVE_MEDIAS,
  json,
});

export const receivedMedia = json => ({
  type: RECEIVE_MEDIA,
  json,
});

export const createMedia = params => (
  (dispatch) => {
    dispatch(requestMedias());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/media`, { method: 'POST', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);

export const deleteMedia = id => (
  (dispatch) => {
    dispatch(requestMedias());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/media/${id}`, { method: 'DELETE', headers: {} })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);

export const fetchMedias = sort => (
  (dispatch) => {
    dispatch(requestMedias());
    let url = `${ENV.REACT_APP_API_SERVER}/media`;
    if (sort) url += `?order=${JSON.stringify(sort)}`;
    return authorizedRequest(url, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
          dispatch(receivedMedias([]));
        } else if (json.status === 401) {
          dispatch(logout());
          dispatch(receivedMedias([]));
        } else {
          dispatch(receivedMedias({ data: json.media } || []));
        }
        return json.media;
      });
  }
);

export const fetchMedia = id => (
  (dispatch) => {
    dispatch(requestMedias());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/media/${id}`, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(logout());
        } else {
          dispatch(receivedMedia(json.media));
        }
        return json.media;
      });
  }
);

export const saveMedia = (id, params) => (
  (dispatch) => {
    dispatch(requestMedias());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/media/${id}`, { method: 'POST', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);
