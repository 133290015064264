import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Row, Table,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { deleteCalendar, fetchCalendar } from '../../actions/calendar';
import Can from '../../components/can';
import PinModal from '../../components/pin';

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.requestDelete = this.requestDelete.bind(this);
    this.failedDelete = this.failedDelete.bind(this);
  }

  componentWillMount() {
    const { getCalendar } = this.props;
    getCalendar();
  }

  requestDelete(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedDelete() {
    this.setState({
      submitted: false,
    });
  }

  handleDelete() {
    const { deleteCalendarAction, goToList } = this.props;
    deleteCalendarAction().then(() => goToList());
  }

  render() {
    const { submitted } = this.state;
    const { calendar } = this.props;
    if (!calendar || Object.keys(calendar).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleDelete} failedSubmit={this.failedDelete} />
        }
        <Row>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <strong>
                  {'Calendar ID: '}
                  {calendar.calendarID}
                </strong>
                <div className="card-header-actions">
                  <Can I="update" a="Calendar">
                    <Button href={`/#/calendar/${calendar.calendarID}/edit`} color="link" className="card-header-action btn-setting">
                      <i className="icon-note" />
                    </Button>
                  </Can>
                  <Can I="delete" a="Calendar">
                    <Button color="link" className="card-header-action btn-setting" onClick={e => this.requestDelete(e)}>
                      <i className="icon-trash" />
                    </Button>
                  </Can>
                </div>
              </CardHeader>
              <CardBody>
                <Table responsive striped hover>
                  <tbody>
                    <tr key="id">
                      <td>Calendar ID:</td>
                      <td><strong>{calendar.calendarID}</strong></td>
                    </tr>
                    <tr key="nameEN">
                      <td>Calendar Name En:</td>
                      <td><strong>{calendar.nameEN}</strong></td>
                    </tr>
                    <tr key="nameFR">
                      <td>Calendar Name Fr:</td>
                      <td><strong>{calendar.nameFR}</strong></td>
                    </tr>
                    <tr key="nameAR">
                      <td>Calendar Name Ar:</td>
                      <td><strong>{calendar.nameAR}</strong></td>
                    </tr>
                    <tr key="dateEN">
                      <td>Calendar Date En:</td>
                      <td><strong>{calendar.dateEN}</strong></td>
                    </tr>
                    <tr key="dateFR">
                      <td>Calendar Date Fr:</td>
                      <td><strong>{calendar.dateFR}</strong></td>
                    </tr>
                    <tr key="dateAR">
                      <td>Calendar Date Ar:</td>
                      <td><strong>{calendar.dateAR}</strong></td>
                    </tr>
                    <tr key="year">
                      <td>Calendar Year:</td>
                      <td><strong>{calendar.year}</strong></td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter>
                <Button href={`/#/calendar/${calendar.calendarID}/edit`} color="secondary" size="sm">Edit</Button>
                {' '}
                <Button color="danger" onClick={e => this.requestDelete(e)} size="sm">Delete</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

Calendar.propTypes = {
  deleteCalendarAction: PropTypes.func.isRequired,
  getCalendar: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired,
  calendar: PropTypes.shape,
};

Calendar.defaultProps = {
  calendar: {},
};

const mapStateToProps = ({ calendar }) => ({
  calendar: calendar.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  deleteCalendarAction: () => deleteCalendar(ownProps.match.params.id),
  getCalendar: () => fetchCalendar(ownProps.match.params.id),
  goToList: () => goBack(),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Calendar);
