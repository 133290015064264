import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Row, Table,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { deletePromotionalApp, fetchPromotionalApp } from '../../actions/promotional-apps';
import Can from '../../components/can';
import PinModal from '../../components/pin';

class PromotionalApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.requestDelete = this.requestDelete.bind(this);
    this.failedDelete = this.failedDelete.bind(this);
  }

  componentWillMount() {
    const { getPromotionalApp } = this.props;
    getPromotionalApp();
  }

  requestDelete(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedDelete() {
    this.setState({
      submitted: false,
    });
  }

  handleDelete() {
    const { deletePromotionalAppAction, goToList } = this.props;
    deletePromotionalAppAction().then(() => goToList());
  }

  render() {
    const { submitted } = this.state;
    const { promotionalApp } = this.props;
    if (!promotionalApp || Object.keys(promotionalApp).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleDelete} failedSubmit={this.failedDelete} />
        }
        <Row>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <strong>
                  {'PromotionalApp ID: '}
                  {promotionalApp.applicationID}
                </strong>
                <div className="card-header-actions">
                  <Can I="update" a="PromotionalApp">
                    <Button href={`/#/promotional-apps/${promotionalApp.applicationID}/edit`} color="link" className="card-header-action btn-setting">
                      <i className="icon-note" />
                    </Button>
                  </Can>
                  <Can I="delete" a="PromotionalApp">
                    <Button color="link" className="card-header-action btn-setting" onClick={e => this.requestDelete(e)}>
                      <i className="icon-trash" />
                    </Button>
                  </Can>
                </div>
              </CardHeader>
              <CardBody>
                <Table responsive striped hover>
                  <tbody>
                    <tr key="id">
                      <td>ID:</td>
                      <td><strong>{promotionalApp.applicationID}</strong></td>
                    </tr>
                    <tr key="appID">
                      <td>App ID:</td>
                      <td><strong>{promotionalApp.appID}</strong></td>
                    </tr>
                    <tr key="title">
                      <td>Title:</td>
                      <td><strong>{promotionalApp.title}</strong></td>
                    </tr>
                    <tr key="url_schema">
                      <td>URL Schema:</td>
                      <td><strong>{promotionalApp.url_schema}</strong></td>
                    </tr>
                    <tr key="description">
                      <td>Description:</td>
                      <td><strong>{promotionalApp.description}</strong></td>
                    </tr>
                    <tr key="icon">
                      <td>Icon:</td>
                      <td><strong>{promotionalApp.icon}</strong></td>
                    </tr>
                    <tr key="screen">
                      <td>Screen:</td>
                      <td><strong>{promotionalApp.screen}</strong></td>
                    </tr>
                    <tr key="screeniOS">
                      <td>Screen iOs:</td>
                      <td><strong>{promotionalApp.screeniOS}</strong></td>
                    </tr>
                    <tr key="version_code">
                      <td>Version Code:</td>
                      <td><strong>{promotionalApp.version_code}</strong></td>
                    </tr>
                    <tr key="priority">
                      <td>Priority:</td>
                      <td><strong>{promotionalApp.priority}</strong></td>
                    </tr>
                    <tr key="linkiOs">
                      <td>Link iOs:</td>
                      <td><strong>{promotionalApp.linkiOs}</strong></td>
                    </tr>
                    <tr key="linkAndroid">
                      <td>Link Android:</td>
                      <td><strong>{promotionalApp.linkAndroid}</strong></td>
                    </tr>
                    <tr key="package_name">
                      <td>Package Name:</td>
                      <td><strong>{promotionalApp.package_name}</strong></td>
                    </tr>
                    <tr key="version_name">
                      <td>Version Name:</td>
                      <td><strong>{promotionalApp.version_name}</strong></td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter>
                <Button href={`/#/promotional-apps/${promotionalApp.applicationID}/edit`} color="secondary" size="sm">Edit</Button>
                {' '}
                <Button color="danger" onClick={e => this.requestDelete(e)} size="sm">Delete</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

PromotionalApp.propTypes = {
  deletePromotionalAppAction: PropTypes.func.isRequired,
  getPromotionalApp: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired,
  promotionalApp: PropTypes.shape,
};

PromotionalApp.defaultProps = {
  promotionalApp: {},
};

const mapStateToProps = ({ promotionalApps }) => ({
  promotionalApp: promotionalApps.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  deletePromotionalAppAction: () => deletePromotionalApp(ownProps.match.params.id),
  getPromotionalApp: () => fetchPromotionalApp(ownProps.match.params.id),
  goToList: () => goBack(),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PromotionalApp);
