import {
  REQUEST_PROMOTIONAL_APPS, RECEIVE_PROMOTIONAL_APPS, RECEIVE_PROMOTIONAL_APP,
} from '../actions/promotional-apps';

const reducer = (state = {
  items: [],
  loading: false,
  item: {},
}, action) => {
  switch (action.type) {
    case REQUEST_PROMOTIONAL_APPS:
      return { ...state, loading: true };
    case RECEIVE_PROMOTIONAL_APPS:
      return { ...state, items: action.json.data, loading: false };
    case RECEIVE_PROMOTIONAL_APP:
      return {
        ...state,
        item: action.json,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
