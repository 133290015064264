import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardHeader, Col, Row,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchPromotionalApps } from '../../actions/promotional-apps';
import DataTable from '../../components/data-table';

const COLUMNS = [
  { id: 'icon', label: '', image: true },
  { id: 'applicationID', label: 'ID', link: true },
  { id: 'title', label: 'Title', link: true },
  { id: 'appID', label: 'App ID' },
  { id: 'url_schema', label: 'URL Schema' },
];

const PromotionalApps = (props) => {
  const { getPromotionalApps } = props;
  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <Row>
                <Col lg={4}>
                    PromotionalApps
                </Col>
                <Col lg={8} style={{ textAlign: 'right' }}>
                  <Button href="/#/promotional-apps/new" color="success" size="sm">New</Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <DataTable
                resource="promotionalApps"
                dataSource={getPromotionalApps}
                columns={COLUMNS}
                urlSchema="promotional-apps"
                {...props}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

PromotionalApps.propTypes = {
  getPromotionalApps: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  promotionalApps: PropTypes.arrayOf(PropTypes.object),
};

PromotionalApps.defaultProps = {
  promotionalApps: [],
};

const mapStateToProps = ({ promotionalApps }) => ({
  loading: promotionalApps.loading,
  promotionalApps: promotionalApps.items,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getPromotionalApps: sort => fetchPromotionalApps(sort),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PromotionalApps);
