import {
  REQUEST_IMPORT,
} from '../actions/import';

const reducer = (state = {
  items: [],
  loading: false,
  item: {},
}, action) => {
  switch (action.type) {
    case REQUEST_IMPORT:
      return { ...state, loading: true };
    default:
      return state;
  }
};

export default reducer;
