import { authorizedRequest, logout } from './account';
import { responseError } from './errors';

require('dotenv').config();

const ENV = process.env;

export const REQUEST_CALENDARS = 'REQUEST_CALENDARS';
export const RECEIVE_CALENDARS = 'RECEIVE_CALENDARS';
export const RECEIVE_CALENDAR = 'RECEIVE_CALENDAR';

export const requestCalendars = () => ({
  type: REQUEST_CALENDARS,
});

export const receivedCalendars = json => ({
  type: RECEIVE_CALENDARS,
  json,
});

export const receivedCalendar = json => ({
  type: RECEIVE_CALENDAR,
  json,
});

export const createCalendar = params => (
  (dispatch) => {
    dispatch(requestCalendars());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/calendar`, { method: 'POST', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);

export const deleteCalendar = id => (
  (dispatch) => {
    dispatch(requestCalendars());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/calendar/${id}`, { method: 'DELETE', headers: {} })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);

export const fetchCalendars = sort => (
  (dispatch) => {
    dispatch(requestCalendars());
    let url = `${ENV.REACT_APP_API_SERVER}/calendar`;
    if (sort) url += `?order=${JSON.stringify(sort)}`;
    return authorizedRequest(url, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
          dispatch(receivedCalendars([]));
        } else if (json.status === 401) {
          dispatch(logout());
          dispatch(receivedCalendars([]));
        } else {
          dispatch(receivedCalendars({ data: json.calendar } || []));
        }
        return json.calendar;
      });
  }
);

export const fetchCalendar = id => (
  (dispatch) => {
    dispatch(requestCalendars());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/calendar/${id}`, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(logout());
        } else {
          dispatch(receivedCalendar(json.calendar));
        }
        return json.calendar;
      });
  }
);

export const saveCalendar = (id, params) => (
  (dispatch) => {
    dispatch(requestCalendars());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/calendar/${id}`, { method: 'POST', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);
