import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardHeader, Col, Row,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchThemes } from '../../actions/themes';
import DataTable from '../../components/data-table';

const COLUMNS = [
  { id: 'themeID', label: 'ID', link: true },
  { id: 'title', label: 'Title', link: true },
  { id: 'link', label: 'Link' },
  { id: 'category', label: 'Category' },
  { id: 'device', label: 'Device' },
  { id: 'type', label: 'Type' },
];

const Themes = (props) => {
  const { getThemes } = props;
  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <Row>
                <Col lg={4}>
                    Themes
                </Col>
                <Col lg={8} style={{ textAlign: 'right' }}>
                  <Button href="/#/themes/new" color="success" size="sm">New</Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <DataTable
                resource="themes"
                dataSource={getThemes}
                columns={COLUMNS}
                {...props}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

Themes.propTypes = {
  getThemes: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  themes: PropTypes.arrayOf(PropTypes.object),
};

Themes.defaultProps = {
  themes: [],
};

const mapStateToProps = ({ themes }) => ({
  loading: themes.loading,
  themes: themes.items,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getThemes: sort => fetchThemes(sort),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Themes);
