import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Row, Table,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { deleteTheme, fetchTheme } from '../../actions/themes';
import Can from '../../components/can';
import PinModal from '../../components/pin';

class Theme extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.requestDelete = this.requestDelete.bind(this);
    this.failedDelete = this.failedDelete.bind(this);
  }

  componentWillMount() {
    const { getTheme } = this.props;
    getTheme();
  }

  requestDelete(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedDelete() {
    this.setState({
      submitted: false,
    });
  }

  handleDelete() {
    const { deleteThemeAction, goToList } = this.props;
    deleteThemeAction().then(() => goToList());
  }

  render() {
    const { submitted } = this.state;
    const { theme } = this.props;
    if (!theme || Object.keys(theme).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleDelete} failedSubmit={this.failedDelete} />
        }
        <Row>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <strong>
                  {'Theme ID: '}
                  {theme.themeID}
                </strong>
                <div className="card-header-actions">
                  <Can I="update" a="Theme">
                    <Button href={`/#/themes/${theme.themeID}/edit`} color="link" className="card-header-action btn-setting">
                      <i className="icon-note" />
                    </Button>
                  </Can>
                  <Can I="delete" a="Theme">
                    <Button color="link" className="card-header-action btn-setting" onClick={e => this.requestDelete(e)}>
                      <i className="icon-trash" />
                    </Button>
                  </Can>
                </div>
              </CardHeader>
              <CardBody>
                <Table responsive striped hover>
                  <tbody>
                    <tr key="id">
                      <td>ID:</td>
                      <td><strong>{theme.themeID}</strong></td>
                    </tr>
                    <tr key="title">
                      <td>Title:</td>
                      <td><strong>{theme.title}</strong></td>
                    </tr>
                    <tr key="link">
                      <td>Link:</td>
                      <td><strong>{theme.link}</strong></td>
                    </tr>
                    <tr key="animation_points">
                      <td>Animation Points:</td>
                      <td><strong>{JSON.stringify(theme.animation_points)}</strong></td>
                    </tr>
                    <tr key="filename">
                      <td>Filename:</td>
                      <td><strong>{theme.filename}</strong></td>
                    </tr>
                    <tr key="category">
                      <td>Category:</td>
                      <td><strong>{theme.category}</strong></td>
                    </tr>
                    <tr key="device">
                      <td>Device:</td>
                      <td><strong>{theme.device}</strong></td>
                    </tr>
                    <tr key="type">
                      <td>Type:</td>
                      <td><strong>{theme.type}</strong></td>
                    </tr>
                    <tr key="premium">
                      <td>Premium:</td>
                      <td><strong>{theme.premium.toString()}</strong></td>
                    </tr>
                    <tr key="credit">
                      <td>Credit:</td>
                      <td><strong>{theme.credit}</strong></td>
                    </tr>
                    <tr key="credit_link">
                      <td>Credit Link:</td>
                      <td><strong>{theme.credit_link}</strong></td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter>
                <Button href={`/#/themes/${theme.themeID}/edit`} color="secondary" size="sm">Edit</Button>
                {' '}
                <Button color="danger" onClick={e => this.requestDelete(e)} size="sm">Delete</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

Theme.propTypes = {
  deleteThemeAction: PropTypes.func.isRequired,
  getTheme: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired,
  theme: PropTypes.shape,
};

Theme.defaultProps = {
  theme: {},
};

const mapStateToProps = ({ themes }) => ({
  theme: themes.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  deleteThemeAction: () => deleteTheme(ownProps.match.params.id),
  getTheme: () => fetchTheme(ownProps.match.params.id),
  goToList: () => goBack(),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Theme);
