import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cryptLib from '@skavinvarnan/cryptlib';
import _get from 'lodash/get';

const Column = ({
  column, id, record, schema,
}) => {
  if (column.link) {
    return (
      <td>
        <Link to={`${schema}/${record[id]}`}>{_get(record, column.id)}</Link>
      </td>
    );
  }
  if (column.decrypt) return <DecryptedColumn record={record} column={column} />;
  if (column.image) return <ImageColumn record={record} column={column} />;
  if (column.boolean) return <td>{_get(record, column.id, '').toString()}</td>;
  if (column.id.includes('.')) return <td>{_get(record, column.id, '').toString()}</td>;
  return <td>{record[column.id]}</td>;
};
Column.propTypes = {
  column: PropTypes.oneOf(PropTypes.object).isRequired,
  id: PropTypes.string.isRequired,
  schema: PropTypes.string.isRequired,
  record: PropTypes.oneOf(PropTypes.object).isRequired,
};

const DecryptedColumn = ({ column, record }) => {
  const decryptedString = cryptLib.decryptCipherTextWithRandomIV(
    record[column.id],
    'hello_secret_key',
  );
  return (
    <td>
      <a target="_blank" rel="noopener noreferrer" href={decryptedString}>
        {decryptedString}
      </a>
    </td>
  );
};
DecryptedColumn.propTypes = {
  column: PropTypes.oneOf(PropTypes.object).isRequired,
  record: PropTypes.oneOf(PropTypes.object).isRequired,
};

const ImageColumn = ({ column, record }) => {
  if (Array.isArray(column.id)) {
    const [first, last] = column.id;
    return (
      <td>
        <img src={`${record[first]}${record[last]}`} alt="record" width="30" />
      </td>
    );
  }
  return (
    <td>
      <img src={`${record[column.id]}`} alt="record" width="30" />
    </td>
  );
};
ImageColumn.propTypes = {
  column: PropTypes.oneOf(PropTypes.object).isRequired,
  record: PropTypes.oneOf(PropTypes.object).isRequired,
};

const List = ({
  columns, data, resource, schema,
}) => {
  const { id } = columns.find(column => column.link && column.label === 'ID');
  return data.map(item => (
    <tr key={item[id]}>
      {columns.map(column => (
        <Column
          key={id}
          resource={resource}
          record={item}
          id={id}
          column={column}
          schema={schema}
        />
      ))}
    </tr>
  ));
};

List.propTypes = {
  reciter: PropTypes.oneOf(PropTypes.object),
  schema: PropTypes.string.isRequired,
};

List.defaultProps = {
  reciter: {},
};

export default List;
