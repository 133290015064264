import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class HidjriRow extends Component {
  link() {
    return '/hidjri/edit';
  }

  render() {
    const { hidjri } = this.props;
    return (
      <tr>
        <th scope="row"><Link to={this.link()}>{hidjri.Ramadan_Start_MA}</Link></th>
        <td><Link to={this.link()}>{hidjri.Ramadan_Start_SA}</Link></td>
        <td><Link to={this.link()}>{hidjri.year}</Link></td>
      </tr>
    );
  }
}

HidjriRow.propTypes = {
  hidjri: PropTypes.oneOfType([PropTypes.object]),
};
HidjriRow.defaultProps = {
  hidjri: {},
};
export default HidjriRow;
