import { authorizedRequest, logout } from './account';
import { responseError } from './errors';

require('dotenv').config();

const ENV = process.env;

export const REQUEST_CITIES = 'REQUEST_CITIES';
export const RECEIVE_CITIES = 'RECEIVE_CITIES';
export const RECEIVE_CITY = 'RECEIVE_CITY';

export const requestCities = () => ({
  type: REQUEST_CITIES,
});

export const receivedCities = json => ({
  type: RECEIVE_CITIES,
  json,
});

export const receivedCity = json => ({
  type: RECEIVE_CITY,
  json,
});

export const createCity = params => (
  (dispatch) => {
    dispatch(requestCities());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/cities`, { method: 'POST', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);

export const deleteCity = id => (
  (dispatch) => {
    dispatch(requestCities());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/cities/${id}`, { method: 'DELETE', headers: {} })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);

export const fetchCities = sort => (
  (dispatch) => {
    dispatch(requestCities());
    let url = `${ENV.REACT_APP_API_SERVER}/cities`;
    if (sort) url += `?order=${JSON.stringify(sort)}`;
    return authorizedRequest(url, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
          dispatch(receivedCities([]));
        } else if (json.status === 401) {
          dispatch(logout());
          dispatch(receivedCities([]));
        } else {
          dispatch(receivedCities({ data: json } || []));
        }
        return json;
      });
  }
);

export const fetchCity = id => (
  (dispatch) => {
    dispatch(requestCities());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/cities/${id}`, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(logout());
        } else {
          dispatch(receivedCity(json));
        }
        return json;
      });
  }
);

export const saveCity = (id, params) => (
  (dispatch) => {
    dispatch(requestCities());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/cities/${id}`, { method: 'POST', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);
