/* eslint-disable no-console */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { importExcel } from '../../actions/import';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class ImportNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      uploading: false,
    };
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.requestSubmit = this.requestSubmit.bind(this);
    this.resetSubmit = this.resetSubmit.bind(this);
  }

  handleFileChange(e, name) {
    const { files } = e.target;
    this.setState({ [name]: files[0] });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  resetSubmit() {
    this.setState({
      submitted: false,
    });
  }

  toggleUploading() {
    const { uploading } = this.state;
    this.setState({ uploading: !uploading });
  }

  handleSubmit() {
    const { importExcelAction, resetErrorAction } = this.props;
    resetErrorAction();
    const { file } = this.state;
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    this.toggleUploading();
    importExcelAction(formData).then((json) => {
      this.resetSubmit();
      if (json && json.success) {
        this.toggleUploading();
        toast.success('Import successful!');
      } else {
        toast.error('Import failed! Check console.');
        console.log('ERROR', json);
      }
    });
  }

  render() {
    const {
      file,
      submitted,
      uploading,
    } = this.state;
    return (
      <div className="animated fadeIn">
        {
          (submitted && !uploading)
          && <PinModal successSubmit={this.handleSubmit} failedSubmit={this.resetSubmit} />
        }
        <Row>
          <Col lg={6}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)} encType="multipart/form-data">
                <CardHeader>
                  <strong>
                    {'New Import'}
                  </strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="file">Select file</Label>
                    <Input
                      id="file"
                      type="file"
                      invalid={submitted && !file}
                      disabled={submitted}
                      onChange={e => this.handleFileChange(e, 'file')}
                      required
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="primary" disabled={uploading}>{uploading ? 'Uploading...' : 'Save'}</Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          hideProgressBar
        />
      </div>
    );
  }
}

ImportNew.propTypes = {
  importExcelAction: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  importExcelAction: body => importExcel(body),
  resetErrorAction: () => resetError(),
}, dispatch);

export default connect(
  null,
  mapDispatchToProps,
)(ImportNew);
