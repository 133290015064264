/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchPromotionalApp, savePromotionalApp } from '../../actions/promotional-apps';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class PromotionalAppEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    const { getPromotionalApp } = this.props;
    getPromotionalApp();
  }

  handleChange(e, name) {
    const { value } = e.target;
    this.setState({ [name]: value });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { savePromotionalAppAction, goToShow, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      title,
      appID,
      url_schema,
      description,
      icon,
      screen,
      screeniOS,
      version_code,
      priority,
      linkiOs,
      linkAndroid,
      package_name,
      version_name,
    } = this.state;
    const body = {
      title,
      appID,
      url_schema,
      description,
      icon,
      screen,
      screeniOS,
      version_code,
      priority,
      linkiOs,
      linkAndroid,
      package_name,
      version_name,
    };
    Object.keys(body).forEach(key => body[key] === undefined && delete body[key]);
    const { promotionalApp } = this.props;
    savePromotionalAppAction(
      promotionalApp.applicationID,
      { body: JSON.stringify(body) },
    )
      .then(() => goToShow());
  }

  render() {
    const { promotionalApp } = this.props;
    const {
      title,
      appID,
      url_schema,
      description,
      icon,
      screen,
      screeniOS,
      version_code,
      priority,
      linkiOs,
      linkAndroid,
      package_name,
      version_name,
      submitted,
    } = this.state;
    if (!promotionalApp || Object.keys(promotionalApp).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        }
        <Row>
          <Col lg={8}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)} encType="multipart/form-data">
                <CardHeader>
                  <strong>
                    {'Edit promotionalApp: '}
                    {promotionalApp.applicationID}
                  </strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="appID">App ID</Label>
                    <Input
                      id="appID"
                      type="text"
                      placeholder="Enter App ID"
                      invalid={submitted && appID === ''}
                      onChange={e => this.handleChange(e, 'appID')}
                      defaultValue={promotionalApp.appID}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="title">Title</Label>
                    <Input
                      id="title"
                      type="text"
                      placeholder="Enter Title"
                      invalid={submitted && title === ''}
                      onChange={e => this.handleChange(e, 'title')}
                      defaultValue={promotionalApp.title}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="url_schema">URL Schema</Label>
                    <Input
                      id="url_schema"
                      type="text"
                      placeholder="Enter URL Schema"
                      invalid={submitted && url_schema === ''}
                      onChange={e => this.handleChange(e, 'url_schema')}
                      defaultValue={promotionalApp.url_schema}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="description">Description</Label>
                    <Input
                      id="description"
                      type="text"
                      placeholder="Enter Description"
                      invalid={submitted && description === ''}
                      onChange={e => this.handleChange(e, 'description')}
                      defaultValue={promotionalApp.description}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="icon">Icon</Label>
                    <Input
                      id="icon"
                      type="text"
                      placeholder="Enter Icon"
                      invalid={submitted && icon === ''}
                      onChange={e => this.handleChange(e, 'icon')}
                      defaultValue={promotionalApp.icon}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="screen">Screen</Label>
                    <Input
                      id="screen"
                      type="text"
                      placeholder="Enter Screen"
                      invalid={submitted && screen === ''}
                      onChange={e => this.handleChange(e, 'screen')}
                      defaultValue={promotionalApp.screen}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="screeniOS">Screen iOs</Label>
                    <Input
                      id="screeniOS"
                      type="text"
                      placeholder="Enter Screen iOs"
                      invalid={submitted && screeniOS === ''}
                      onChange={e => this.handleChange(e, 'screeniOS')}
                      defaultValue={promotionalApp.screeniOS}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="version_code">Version Code</Label>
                    <Input
                      id="version_code"
                      type="text"
                      placeholder="Enter Version Code"
                      invalid={submitted && version_code === ''}
                      onChange={e => this.handleChange(e, 'version_code')}
                      defaultValue={promotionalApp.version_code}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="priority">Priority</Label>
                    <Input
                      id="priority"
                      type="number"
                      placeholder="Enter Priority"
                      invalid={submitted && priority === ''}
                      onChange={e => this.handleChange(e, 'priority')}
                      defaultValue={promotionalApp.priority}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="linkiOs">Link iOs</Label>
                    <Input
                      id="linkiOs"
                      type="text"
                      placeholder="Enter Link iOs"
                      invalid={submitted && linkiOs === ''}
                      onChange={e => this.handleChange(e, 'linkiOs')}
                      defaultValue={promotionalApp.linkiOs}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="linkAndroid">Link Android</Label>
                    <Input
                      id="linkAndroid"
                      type="text"
                      placeholder="Enter Link Android"
                      invalid={submitted && linkAndroid === ''}
                      onChange={e => this.handleChange(e, 'linkAndroid')}
                      defaultValue={promotionalApp.linkAndroid}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="package_name">Package Name</Label>
                    <Input
                      id="package_name"
                      type="text"
                      placeholder="Enter Package Name"
                      invalid={submitted && package_name === ''}
                      onChange={e => this.handleChange(e, 'package_name')}
                      defaultValue={promotionalApp.package_name}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="version_name">Version Name</Label>
                    <Input
                      id="version_name"
                      type="text"
                      placeholder="Enter Version Name"
                      invalid={submitted && version_name === ''}
                      onChange={e => this.handleChange(e, 'version_name')}
                      defaultValue={promotionalApp.version_name}
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="success">Save</Button>
                  {' '}
                  <Button href={`/#/promotional-apps/${promotionalApp.applicationID}`} size="sm" color="danger">Cancel</Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

PromotionalAppEdit.propTypes = {
  getPromotionalApp: PropTypes.func.isRequired,
  goToShow: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
  savePromotionalAppAction: PropTypes.func.isRequired,
  promotionalApp: PropTypes.shape.isRequired,
};

const mapStateToProps = ({ promotionalApps }) => ({
  promotionalApp: promotionalApps.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  getPromotionalApp: () => fetchPromotionalApp(ownProps.match.params.id),
  goToShow: () => push(`/promotional-apps/${ownProps.match.params.id}`),
  resetErrorAction: () => resetError(),
  savePromotionalAppAction: (id, body) => savePromotionalApp(id, body),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PromotionalAppEdit);
