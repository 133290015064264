/* eslint-disable no-alert */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchTheme, saveTheme } from '../../actions/themes';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';
import validateAnimationPoints from './validate';

class ThemeEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    const { getTheme } = this.props;
    getTheme();
  }

  handleChange(e, name) {
    const { value } = e.target;
    this.setState({ [name]: value });
  }

  requestSubmit(e) {
    e.preventDefault();
    const { animation_points } = this.state;
    if (animation_points) {
      const result = validateAnimationPoints(animation_points);
      if (result.valid) {
        this.setState({
          submitted: true,
          animation_points_error: false,
        });
      } else {
        alert(result.message);
        this.setState({
          submitted: false,
          animation_points_error: true,
        });
      }
    } else {
      this.setState({
        submitted: true,
        animation_points_error: false,
      });
    }
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { saveThemeAction, goToShow, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      title,
      link,
      animation_points,
      filename,
      category,
      device,
      type,
      comments,
      credit,
      credit_link,
      premium,
    } = this.state;
    const body = {
      title,
      link,
      animation_points: animation_points ? JSON.parse(animation_points) : undefined,
      filename,
      category,
      device,
      type,
      comments,
      credit,
      credit_link,
      premium,
    };
    Object.keys(body).forEach(key => body[key] === undefined && delete body[key]);
    const { theme } = this.props;
    saveThemeAction(theme.themeID, { body: JSON.stringify(body) }).then(() => goToShow());
  }

  render() {
    const { theme } = this.props;
    const {
      title,
      link,
      animation_points,
      animation_points_error,
      filename,
      category,
      device,
      type,
      comments,
      credit,
      credit_link,
      submitted,
    } = this.state;
    if (!theme || Object.keys(theme).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        }
        <Row>
          <Col lg={8}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)} encType="multipart/form-data">
                <CardHeader>
                  <strong>
                    {'Edit theme: '}
                    {theme.themeID}
                  </strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="title">Title</Label>
                    <Input
                      id="title"
                      type="text"
                      placeholder="Enter Title"
                      invalid={submitted && title === ''}
                      onChange={e => this.handleChange(e, 'title')}
                      defaultValue={theme.title}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="link">Link</Label>
                    <Input
                      id="link"
                      type="text"
                      placeholder="Enter Link"
                      invalid={submitted && link === ''}
                      onChange={e => this.handleChange(e, 'link')}
                      defaultValue={theme.link}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="animation_points">Animation points</Label>
                    <Input
                      id="animation_points"
                      type="textarea"
                      placeholder="Enter Animation points"
                      invalid={(submitted && animation_points === '') || animation_points_error}
                      onChange={e => this.handleChange(e, 'animation_points')}
                      defaultValue={JSON.stringify(theme.animation_points)}
                      required
                      rows="10"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="filename">Filename</Label>
                    <Input
                      id="filename"
                      type="text"
                      placeholder="Enter Filename"
                      invalid={submitted && filename === ''}
                      onChange={e => this.handleChange(e, 'filename')}
                      defaultValue={theme.filename}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="category">Category</Label>
                    <Input
                      id="category"
                      type="text"
                      placeholder="Enter Category"
                      invalid={submitted && category === ''}
                      onChange={e => this.handleChange(e, 'category')}
                      defaultValue={theme.category}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="device">Device</Label>
                    <Input
                      id="device"
                      type="text"
                      placeholder="Enter Device"
                      invalid={submitted && device === ''}
                      onChange={e => this.handleChange(e, 'device')}
                      defaultValue={theme.device}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="type">Type</Label>
                    <Input
                      id="type"
                      type="text"
                      placeholder="Enter Type"
                      invalid={submitted && type === ''}
                      onChange={e => this.handleChange(e, 'type')}
                      defaultValue={theme.type}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="premium">Premium</Label>
                    <Input
                      type="select"
                      name="premium"
                      id="premium"
                      onChange={e => this.handleChange(e, 'premium')}
                      defaultValue={theme.premium}
                    >
                      <option>true</option>
                      <option>false</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="comments">Comments</Label>
                    <Input
                      id="comments"
                      type="text"
                      placeholder="Enter Comments"
                      invalid={submitted && comments === ''}
                      onChange={e => this.handleChange(e, 'comments')}
                      defaultValue={theme.comments}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="credit">Credit</Label>
                    <Input
                      id="credit"
                      type="text"
                      placeholder="Enter Credit"
                      invalid={submitted && credit === ''}
                      onChange={e => this.handleChange(e, 'credit')}
                      defaultValue={theme.credit}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="credit_link">Credit Link</Label>
                    <Input
                      id="credit_link"
                      type="text"
                      placeholder="Enter Credit Link"
                      invalid={submitted && credit_link === ''}
                      onChange={e => this.handleChange(e, 'credit_link')}
                      defaultValue={theme.credit_link}
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="success">Save</Button>
                  {' '}
                  <Button href={`/#/themes/${theme.themeID}`} size="sm" color="danger">Cancel</Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

ThemeEdit.propTypes = {
  getTheme: PropTypes.func.isRequired,
  goToShow: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
  saveThemeAction: PropTypes.func.isRequired,
  theme: PropTypes.shape.isRequired,
};

const mapStateToProps = ({ themes }) => ({
  theme: themes.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  getTheme: () => fetchTheme(ownProps.match.params.id),
  goToShow: () => push(`/themes/${ownProps.match.params.id}`),
  resetErrorAction: () => resetError(),
  saveThemeAction: (id, body) => saveTheme(id, body),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThemeEdit);
