/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

import List from '../list';

function generateSort(state, id) {
  const { key, order } = state;
  const newKey = id;
  let newOrder = order;
  if (id === key) {
    newOrder = !order;
  }
  return { key: newKey, order: newOrder };
}

function normalizeSort(sort) {
  const { key, order } = sort;
  return [key, order ? 1 : -1];
}

class DataTable extends Component {
  constructor(props) {
    super(props);
    const { columns } = this.props;
    let key = columns[0].id;
    let order = true;
    const column = columns.find(c => c.sort !== undefined);
    if (column) {
      key = column.id;
      order = false;
    }
    this.state = {
      key,
      order,
    };
  }

  componentDidMount() {
    const { dataSource } = this.props;
    dataSource(normalizeSort(this.state));
  }

  handleChange(id) {
    const { loading, dataSource } = this.props;
    if (!loading) {
      const newState = generateSort(this.state, id);
      this.setState(newState, () => {
        dataSource(normalizeSort(newState));
      });
    }
  }

  render() {
    const { key, order } = this.state;
    const {
      loading, columns, resource, urlSchema,
    } = this.props;
    const resourceData = this.props[resource];
    const schema = urlSchema || resource;
    return (
      <Table responsive hover>
        <thead>
          <tr>
            {columns.map(column => (
              column.image
                ? <th key={0} scope="col" className="column" />

                : (
                  <th key={column.id} scope="col" onClick={() => this.handleChange(column.id)} className="column">
                    <p>
                      {column.label}
                      {' '}
                      {key === column.id ? order ? <span>&#8593;</span> : <span>&#8595;</span> : ''}
                    </p>
                  </th>
                )
            ))}
          </tr>
        </thead>
        <tbody>
          {!loading && resourceData.length === 0 && (
          <tr className="row-no-data">
            <td colSpan="4">No data found</td>
          </tr>
          )}
          <List columns={columns} data={resourceData} resource={resource} schema={schema} />
        </tbody>
      </Table>
    );
  }
}

DataTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool.isRequired,
  resource: PropTypes.string.isRequired,
  dataSource: PropTypes.func.isRequired,
  urlSchema: PropTypes.string,
};

DataTable.defaultProps = {
  columns: [],
  urlSchema: '',
};

export default DataTable;
