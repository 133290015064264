/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchHidjri, saveHidjri } from '../../actions/hidjri';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class HidjriEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    const { getHidjri } = this.props;
    getHidjri();
  }

  handleChange(e, name) {
    const { value } = e.target;
    this.setState({ [name]: value });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { saveHidjriAction, goToShow, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      Ramadan_Start_MA,
      Ramadan_Start_SA,
      year,
    } = this.state;
    const body = {
      Ramadan_Start_MA,
      Ramadan_Start_SA,
      year,
    };
    Object.keys(body).forEach(key => body[key] === undefined && delete body[key]);
    const { hidjri } = this.props;
    saveHidjriAction(hidjri.id, { body: JSON.stringify(body) }).then(() => goToShow());
  }

  render() {
    const { hidjri } = this.props;
    const {
      Ramadan_Start_MA,
      Ramadan_Start_SA,
      year,
      submitted,
    } = this.state;
    if (!hidjri || Object.keys(hidjri).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        }
        <Row>
          <Col lg={8}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)} encType="multipart/form-data">
                <CardHeader>
                  <strong>
                    {'Edit hidjri: '}
                    {hidjri.id}
                  </strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="Ramadan_Start_MA">Ramadan Start MA</Label>
                    <Input
                      id="Ramadan_Start_MA"
                      type="text"
                      placeholder="Enter Ramadan Start MA"
                      defaultValue={hidjri.Ramadan_Start_MA}
                      invalid={submitted && !Ramadan_Start_MA === ''}
                      onChange={e => this.handleChange(e, 'Ramadan_Start_MA')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="Ramadan_Start_SA">Ramadan Start SA</Label>
                    <Input
                      id="Ramadan_Start_SA"
                      type="text"
                      placeholder="Enter Ramadan Start SA"
                      defaultValue={hidjri.Ramadan_Start_SA}
                      invalid={submitted && !Ramadan_Start_SA === ''}
                      onChange={e => this.handleChange(e, 'Ramadan_Start_SA')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="year">Year</Label>
                    <Input
                      id="year"
                      type="text"
                      placeholder="Enter Year"
                      defaultValue={hidjri.year}
                      invalid={submitted && !year === ''}
                      onChange={e => this.handleChange(e, 'year')}
                      required
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="success">Save</Button>
                  {' '}
                  <Button href="/#/hidjri" size="sm" color="danger">Cancel</Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

HidjriEdit.propTypes = {
  getHidjri: PropTypes.func.isRequired,
  goToShow: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
  saveHidjriAction: PropTypes.func.isRequired,
  hidjri: PropTypes.shape.isRequired,
};

const mapStateToProps = ({ hidjri }) => ({
  hidjri: hidjri.items,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  getHidjri: () => fetchHidjri(ownProps.match.params.id),
  goToShow: () => push('/hidjri'),
  resetErrorAction: () => resetError(),
  saveHidjriAction: (id, body) => saveHidjri(id, body),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HidjriEdit);
