/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { arrayMoveImmutable } from 'array-move';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchToday, saveToday } from '../../actions/today';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';
import FIELDS from './forms/fields';
import SortableList from './forms/sortableList';

const DEFAULT_CARD_TYPE = 1;

const toJSONLocal = (date) => {
  const local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
};

const createCardWithType = (type) => {
  const { name, fields } = FIELDS[type];
  const newCard = {};
  fields.forEach((field) => {
    newCard[field.id] = field.defaultValue ? field.defaultValue : '';
    if (field.id === 'template') newCard[field.id] = name;
  });
  newCard.type = type;
  return newCard;
};

const TodayEdit = (props) => {
  const [submitted, setSubmitted] = useState(false);
  const [day, setDay] = useState(new Date());
  const [cardsData, setCardsData] = useState([]);
  const {
    saveTodayAction, resetErrorAction, goToShow, getToday, today,
  } = props;

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const sorted = arrayMoveImmutable(cardsData, oldIndex, newIndex);
    setCardsData(sorted);
  };

  const handleRemove = (index) => {
    const cards = cardsData.map((cardData, i) => {
      if (i !== index) return cardData;
      return null;
    }).filter(card => card);
    setCardsData(cards);
  };

  const handleTypeChange = (index, type) => {
    const card = createCardWithType(type);
    const cards = cardsData.map((cardData, i) => {
      if (i !== index) return cardData;
      return card;
    });
    setCardsData(cards);
  };

  const handleChange = (e, field, index) => {
    const { value } = e.target;
    const card = { ...cardsData[index] };
    const cards = cardsData.map((cardData, i) => {
      if (i !== index) return cardData;
      return { ...card, [field.id]: field.type === 'number' ? +value : value };
    });
    setCardsData(cards);
  };

  const addCard = () => {
    const card = createCardWithType(DEFAULT_CARD_TYPE);
    const newCardsData = [...cardsData, card];
    setCardsData(newCardsData);
  };

  const requestSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
  };

  const failedSubmit = () => {
    setSubmitted(false);
  };

  const handleSubmit = () => {
    resetErrorAction();
    const body = {
      day: toJSONLocal(day),
      cards: JSON.stringify(cardsData),
    };
    saveTodayAction(today.id, { body: JSON.stringify(body) }).then(() => {
      goToShow();
    });
  };

  useEffect(() => {
    if (today.id) {
      setCardsData(today.cards || []);
      setDay(new Date(today.date));
    } else {
      getToday();
    }
  }, [today]);

  return (
    <div className="animated fadeIn">
      {submitted && <PinModal successSubmit={handleSubmit} failedSubmit={failedSubmit} />}
      <Row>
        <Col lg={6}>
          <Card>
            <Form onSubmit={e => requestSubmit(e)}>
              <CardHeader>
                <strong>
                  {'Edit today: '}
                  {today.id}
                </strong>
              </CardHeader>
              <CardBody>
                <div>
                  <label>Day</label>
                  <DatePicker
                    className="form-control datepicker"
                    dateFormat="yyyy-MM-dd"
                    selected={day}
                    onChange={date => setDay(date)}
                    showYearDropdown
                    showMonthDropdown
                  />
                </div>
                <SortableList
                  items={cardsData}
                  onSortEnd={onSortEnd}
                  handleChange={handleChange}
                  handleTypeChange={handleTypeChange}
                  handleRemove={handleRemove}
                  submitted={submitted}
                />
                <Button type="button" size="sm" color="primary" onClick={addCard}>
                  + Add Card
                </Button>
              </CardBody>
              <CardFooter>
                <Button type="submit" size="sm" color="primary">
                  Save
                </Button>
                {' '}
                <Button onClick={() => goToShow()} size="sm" color="danger">
                  Cancel
                </Button>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

TodayEdit.propTypes = {
  getToday: PropTypes.func.isRequired,
  goToShow: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
  saveTodayAction: PropTypes.func.isRequired,
  today: PropTypes.shape.isRequired,
};

const mapStateToProps = ({ today }) => ({
  today: today.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  getToday: () => fetchToday(ownProps.match.params.id),
  goToShow: () => push(`/today/${ownProps.match.params.id}`),
  resetErrorAction: () => resetError(),
  saveTodayAction: (id, body) => saveToday(id, body),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TodayEdit);
