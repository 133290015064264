/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchMosque, saveMosque } from '../../actions/mosques';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class MosqueEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.failedSubmit = this.failedSubmit.bind(this);
  }

  componentWillMount() {
    const { getMosque } = this.props;
    getMosque();
  }

  handleChange(e, name) {
    const { value } = e.target;
    this.setState({ [name]: value });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { saveMosqueAction, goToShow, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      uuid,
      name,
      countryCode,
      phone,
      localisation,
      site,
      email,
      association,
      otherInfo,
      latitude,
      longitude,
      version,
    } = this.state;
    if (!uuid && !name && !countryCode && !phone && !localisation
      && !site && !email && !association && !otherInfo
       && !latitude && !longitude && !version) {
      goToShow();
      return;
    }
    const body = {
      uuid,
      name,
      countryCode,
      phone,
      localisation,
      site,
      email,
      association,
      otherInfo,
      latitude,
      longitude,
      version,
    };
    Object.keys(body).forEach(key => body[key] === undefined && delete body[key]);
    const { mosque } = this.props;
    saveMosqueAction(mosque.id, { body: JSON.stringify(body) }).then(() => goToShow());
  }

  render() {
    const { mosque } = this.props;
    const {
      uuid,
      name,
      countryCode,
      phone,
      localisation,
      site,
      email,
      association,
      otherInfo,
      latitude,
      longitude,
      version,
      submitted,
    } = this.state;
    if (!mosque || Object.keys(mosque).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        }
        <Row>
          <Col lg={8}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)} encType="multipart/form-data">
                <CardHeader>
                  <strong>
                    {'Edit mosque: '}
                    {mosque.id}
                  </strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="uuid">Mosque UUID</Label>
                    <Input
                      id="uuid"
                      type="text"
                      placeholder="Enter Mosque UUID"
                      defaultValue={mosque.uuid}
                      invalid={submitted && uuid === ''}
                      onChange={e => this.handleChange(e, 'uuid')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="name">Mosque Name</Label>
                    <Input
                      id="name"
                      type="text"
                      placeholder="Enter Mosque Name"
                      defaultValue={mosque.name}
                      invalid={submitted && name === ''}
                      onChange={e => this.handleChange(e, 'name')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="countryCode">Mosque Country Code</Label>
                    <Input
                      id="countryCode"
                      type="text"
                      placeholder="Enter Mosque Country Code"
                      defaultValue={mosque.countryCode}
                      invalid={submitted && countryCode === ''}
                      onChange={e => this.handleChange(e, 'countryCode')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="phone">Phone</Label>
                    <Input
                      id="phone"
                      type="text"
                      placeholder="Enter Phone"
                      defaultValue={mosque.phone}
                      invalid={submitted && phone === ''}
                      onChange={e => this.handleChange(e, 'phone')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="localisation">Localization</Label>
                    <Input
                      id="localisation"
                      type="text"
                      placeholder="Enter Localization"
                      defaultValue={mosque.localisation}
                      invalid={submitted && localisation === ''}
                      onChange={e => this.handleChange(e, 'localisation')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="email">Email</Label>
                    <Input
                      id="email"
                      type="text"
                      placeholder="Enter Email"
                      defaultValue={mosque.email}
                      invalid={submitted && email === ''}
                      onChange={e => this.handleChange(e, 'email')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="site">Site</Label>
                    <Input
                      id="site"
                      type="text"
                      placeholder="Enter Site"
                      defaultValue={mosque.site}
                      invalid={submitted && site === ''}
                      onChange={e => this.handleChange(e, 'site')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="association">Association</Label>
                    <Input
                      id="association"
                      type="text"
                      placeholder="Enter Association"
                      defaultValue={mosque.association}
                      invalid={submitted && association === ''}
                      onChange={e => this.handleChange(e, 'association')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="otherInfo">Other Info</Label>
                    <Input
                      id="otherInfo"
                      type="text"
                      placeholder="Enter Other Info"
                      defaultValue={mosque.otherInfo}
                      invalid={submitted && otherInfo === ''}
                      onChange={e => this.handleChange(e, 'otherInfo')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="latitude">Latitude</Label>
                    <Input
                      id="latitude"
                      type="text"
                      placeholder="Enter Latitude"
                      defaultValue={mosque.latitude}
                      invalid={submitted && latitude === ''}
                      onChange={e => this.handleChange(e, 'latitude')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="longitude">Longitude</Label>
                    <Input
                      id="longitude"
                      type="text"
                      placeholder="Enter Longitude"
                      defaultValue={mosque.longitude}
                      invalid={submitted && longitude === ''}
                      onChange={e => this.handleChange(e, 'longitude')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="version">Version</Label>
                    <Input
                      id="version"
                      type="number"
                      placeholder="Enter Version"
                      defaultValue={mosque.version}
                      invalid={submitted && version === ''}
                      onChange={e => this.handleChange(e, 'version')}
                      required
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="success">Save</Button>
                  {' '}
                  <Button href={`/#/mosques/${mosque.id}`} size="sm" color="danger">Cancel</Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

MosqueEdit.propTypes = {
  getMosque: PropTypes.func.isRequired,
  goToShow: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
  saveMosqueAction: PropTypes.func.isRequired,
  mosque: PropTypes.shape.isRequired,
};

MosqueEdit.defaultProps = {
};

const mapStateToProps = ({ mosques }) => ({
  mosque: mosques.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  getMosque: () => fetchMosque(ownProps.match.params.id),
  goToShow: () => push(`/mosques/${ownProps.match.params.id}`),
  resetErrorAction: () => resetError(),
  saveMosqueAction: (id, body) => saveMosque(id, body),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MosqueEdit);
