import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardHeader, Col, Row,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchMedias } from '../../actions/media';
import DataTable from '../../components/data-table';

const COLUMNS = [
  { id: 'mediaID', label: 'ID', link: true },
  { id: 'title', label: 'Title', link: true },
  { id: 'titleAR', label: 'Title AR', link: true },
  { id: 'link', label: 'Link' },
  { id: 'category', label: 'Category' },
  { id: 'url', label: 'URL' },
];

const Medias = (props) => {
  const { getMedias } = props;
  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <Row>
                <Col lg={4}>
                    Media
                </Col>
                <Col lg={8} style={{ textAlign: 'right' }}>
                  <Button href="/#/media/new" color="success" size="sm">New</Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <DataTable
                resource="medias"
                dataSource={getMedias}
                columns={COLUMNS}
                urlSchema="media"
                {...props}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

Medias.propTypes = {
  getMedias: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  medias: PropTypes.arrayOf(PropTypes.object),
};

Medias.defaultProps = {
  medias: [],
};

const mapStateToProps = ({ media }) => ({
  loading: media.loading,
  medias: media.items,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getMedias: sort => fetchMedias(sort),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Medias);
