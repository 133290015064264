import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, CardHeader, Col, Row, Table,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchDstSetup } from '../../actions/dst-setup';

import DstSetupRow from './row';

class DstSetup extends React.Component {
  componentWillMount() {
    const { getDstSetup } = this.props;
    getDstSetup();
  }

  render() {
    const { dstSetup, loading } = this.props;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xl={12}>
            <Card>
              <CardHeader>
                <Row>
                  <Col lg={4}>
                    DstSetup
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col">Countries</th>
                      <th scope="col">Dst Setup </th>
                      <th scope="col">Hidjri Set</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && !dstSetup && <tr className="row-no-data"><td colSpan="4">No data found</td></tr>}
                    {dstSetup && <DstSetupRow key={0} dstSetup={dstSetup} />}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

DstSetup.propTypes = {
  getDstSetup: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  dstSetup: PropTypes.shape({
    countries: PropTypes.arrayOf(PropTypes.string),
    dst_set: PropTypes.object,
    hijri_set: PropTypes.object,
  }),
};
DstSetup.defaultProps = {
  dstSetup: {},
};

const mapStateToProps = ({ dstSetup }) => ({
  loading: dstSetup.loading,
  dstSetup: dstSetup.items,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getDstSetup: () => fetchDstSetup(),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DstSetup);
