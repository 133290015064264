import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardHeader, Col, Row,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchDaylightSavings } from '../../actions/daylight-savings';
import DataTable from '../../components/data-table';

const COLUMNS = [
  { id: 'id', label: 'ID', link: true },
  { id: 'daylight_start', label: 'Daylight Start' },
  { id: 'daylight_end', label: 'Daylight End' },
  { id: 'daylight_start_ref', label: 'Daylight Start Ref' },
  { id: 'daylight_end_ref', label: 'Daylight End Ref' },
  { id: 'year', label: 'Year' },
  { id: 'continent.name', label: 'Continent' },
];

const DaylightSavings = (props) => {
  const { getDaylightSavings } = props;
  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <Row>
                <Col lg={4}>
                    DaylightSavings
                </Col>
                <Col lg={8} style={{ textAlign: 'right' }}>
                  <Button href="/#/daylight-savings/new" color="success" size="sm">New</Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <DataTable
                resource="daylightSavings"
                dataSource={getDaylightSavings}
                columns={COLUMNS}
                urlSchema="daylight-savings"
                {...props}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

DaylightSavings.propTypes = {
  getDaylightSavings: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  daylightSavings: PropTypes.arrayOf(PropTypes.object),
};

DaylightSavings.defaultProps = {
  daylightSavings: [],
};

const mapStateToProps = ({ daylightSavings }) => ({
  loading: daylightSavings.loading,
  daylightSavings: daylightSavings.items,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getDaylightSavings: sort => fetchDaylightSavings(sort),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DaylightSavings);
