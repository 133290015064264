import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink } from 'reactstrap';

const isActive = (pathname, currentPathname) => currentPathname.includes(pathname);

const NavigationItem = ({ location, path, name }) => (
  <NavItem>
    <NavLink active={isActive(path, location.pathname)} href={`/#/${path}`}>{name}</NavLink>
  </NavItem>
);
NavigationItem.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  path: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const Sidebar = ({ location }) => (
  <Nav vertical className="col-md-2 d-none d-md-block bg-light sidebar">
    <NavigationItem
      location={location}
      path="import"
      name="Import"
    />
    <NavigationItem
      location={location}
      path="hidjri"
      name="Hidjri Dates"
    />
    <NavigationItem
      location={location}
      path="dst-setup"
      name="DST Setup Dates"
    />
    <NavigationItem
      location={location}
      path="themes"
      name="Themes"
    />
    <NavigationItem
      location={location}
      path="wallpapers"
      name="Wallpapers"
    />
    <NavigationItem
      location={location}
      path="sounds-full"
      name="Sounds Full"
    />
    <NavigationItem
      location={location}
      path="promotional-apps"
      name="Promotional Apps"
    />
    <NavigationItem
      location={location}
      path="continents"
      name="Continents"
    />
    <NavigationItem
      location={location}
      path="countries"
      name="Countries"
    />
    <NavigationItem
      location={location}
      path="cities"
      name="Cities"
    />
    <NavigationItem
      location={location}
      path="mosques"
      name="Mosques"
    />
    <NavigationItem
      location={location}
      path="calculation-methods"
      name="Calculation Methods"
    />
    <NavigationItem
      location={location}
      path="daylight-savings"
      name="Daylight Savings"
    />
    <NavigationItem
      location={location}
      path="media"
      name="Media"
    />
    <NavigationItem
      location={location}
      path="calendar"
      name="Calendar"
    />
    <NavigationItem
      location={location}
      path="today"
      name="Today File"
    />
  </Nav>
);
Sidebar.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};
Sidebar.defaultProps = {
  location: {},
};

export default Sidebar;
