import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardHeader, Col, Row,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchMosques } from '../../actions/mosques';
import DataTable from '../../components/data-table';

const COLUMNS = [
  { id: 'id', label: 'ID', link: true },
  { id: 'name', label: 'Name', link: true },
  { id: 'countryCode', label: 'Country Code' },
  { id: 'localisation', label: 'Localization' },
  { id: 'phone', label: 'Phone' },
  { id: 'version', label: 'Version' },
];

const Mosques = (props) => {
  const { getMosques } = props;
  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <Row>
                <Col lg={4}>Mosques</Col>
                <Col lg={8} style={{ textAlign: 'right' }}>
                  <Button href="/#/mosques/new" color="success" size="sm">
                    New
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <DataTable resource="mosques" dataSource={getMosques} columns={COLUMNS} {...props} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

Mosques.propTypes = {
  getMosques: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  mosques: PropTypes.arrayOf(PropTypes.object),
};

Mosques.defaultProps = {
  mosques: [],
};

const mapStateToProps = ({ mosques }) => ({
  loading: mosques.loading,
  mosques: mosques.items,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getMosques: sort => fetchMosques(sort),
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Mosques);
