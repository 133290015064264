import { combineReducers } from 'redux';
import account from './account';
import errors from './errors';
import continents from './continents';
import countries from './countries';
import cities from './cities';
import mosques from './mosques';
import calculationMethods from './calculation-methods';
import daylightSavings from './daylight-savings';
import importExcel from './import';
import hidjri from './hidjri';
import dstSetup from './dst-setup';
import soundsFulls from './sounds-full';
import promotionalApps from './promotional-apps';
import themes from './themes';
import wallpapers from './wallpapers';
import media from './media';
import today from './today';
import calendar from './calendar';

export default combineReducers({
  account,
  errors,
  continents,
  countries,
  cities,
  mosques,
  calculationMethods,
  daylightSavings,
  importExcel,
  hidjri,
  dstSetup,
  soundsFulls,
  promotionalApps,
  themes,
  wallpapers,
  media,
  calendar,
  today,
});
