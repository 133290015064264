import {
  REQUEST_MEDIAS, RECEIVE_MEDIAS, RECEIVE_MEDIA,
} from '../actions/media';

const reducer = (state = {
  items: [],
  loading: false,
  item: {},
}, action) => {
  switch (action.type) {
    case REQUEST_MEDIAS:
      return { ...state, loading: true };
    case RECEIVE_MEDIAS:
      return { ...state, items: action.json.data, loading: false };
    case RECEIVE_MEDIA:
      return {
        ...state,
        item: action.json,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
