import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Row, Table,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { deleteDaylightSaving, fetchDaylightSaving } from '../../actions/daylight-savings';
import Can from '../../components/can';
import PinModal from '../../components/pin';

class DaylightSaving extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.requestDelete = this.requestDelete.bind(this);
    this.failedDelete = this.failedDelete.bind(this);
  }

  componentWillMount() {
    const { getDaylightSaving } = this.props;
    getDaylightSaving();
  }

  requestDelete(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedDelete() {
    this.setState({
      submitted: false,
    });
  }

  handleDelete() {
    const { deleteDaylightSavingAction, goToList } = this.props;
    deleteDaylightSavingAction().then(() => goToList());
  }

  render() {
    const { submitted } = this.state;
    const { daylightSaving } = this.props;
    if (!daylightSaving || Object.keys(daylightSaving).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleDelete} failedSubmit={this.failedDelete} />
        }
        <Row>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <strong>
                  {'DaylightSaving ID: '}
                  {daylightSaving.id}
                </strong>
                <div className="card-header-actions">
                  <Can I="update" a="DaylightSaving">
                    <Button href={`/#/daylight-savings/${daylightSaving.id}/edit`} color="link" className="card-header-action btn-setting">
                      <i className="icon-note" />
                    </Button>
                  </Can>
                  <Can I="delete" a="DaylightSaving">
                    <Button color="link" className="card-header-action btn-setting" onClick={e => this.requestDelete(e)}>
                      <i className="icon-trash" />
                    </Button>
                  </Can>
                </div>
              </CardHeader>
              <CardBody>
                <Table responsive striped hover>
                  <tbody>
                    <tr key="id">
                      <td>ID:</td>
                      <td><strong>{daylightSaving.id}</strong></td>
                    </tr>
                    <tr key="daylight_start">
                      <td>Daylight Start:</td>
                      <td><strong>{daylightSaving.daylight_start}</strong></td>
                    </tr>
                    <tr key="daylight_end">
                      <td>Daylight End:</td>
                      <td><strong>{daylightSaving.daylight_end}</strong></td>
                    </tr>
                    <tr key="daylight_start_ref">
                      <td>Daylight Start Ref:</td>
                      <td><strong>{daylightSaving.daylight_start_ref}</strong></td>
                    </tr>
                    <tr key="daylight_end_ref">
                      <td>Daylight End Ref:</td>
                      <td><strong>{daylightSaving.daylight_end_ref}</strong></td>
                    </tr>
                    <tr key="year">
                      <td>Year:</td>
                      <td><strong>{daylightSaving.year}</strong></td>
                    </tr>
                    <tr key="continent_id">
                      <td>Continent:</td>
                      <td>
                        <strong>
                          {daylightSaving.continent && daylightSaving.continent.name}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter>
                <Button href={`/#/daylight-savings/${daylightSaving.id}/edit`} color="secondary" size="sm">Edit</Button>
                {' '}
                <Button color="danger" onClick={e => this.requestDelete(e)} size="sm">Delete</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

DaylightSaving.propTypes = {
  deleteDaylightSavingAction: PropTypes.func.isRequired,
  getDaylightSaving: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired,
  daylightSaving: PropTypes.shape,
};

DaylightSaving.defaultProps = {
  daylightSaving: {},
};

const mapStateToProps = ({ daylightSavings }) => ({
  daylightSaving: daylightSavings.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  deleteDaylightSavingAction: () => deleteDaylightSaving(ownProps.match.params.id),
  getDaylightSaving: () => fetchDaylightSaving(ownProps.match.params.id),
  goToList: () => goBack(),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DaylightSaving);
