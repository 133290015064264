import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Row, Table,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { deleteMosque, fetchMosque } from '../../actions/mosques';
import Can from '../../components/can';
import PinModal from '../../components/pin';

class Mosque extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.requestDelete = this.requestDelete.bind(this);
    this.failedDelete = this.failedDelete.bind(this);
  }

  componentWillMount() {
    const { getMosque } = this.props;
    getMosque();
  }

  requestDelete(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedDelete() {
    this.setState({
      submitted: false,
    });
  }

  handleDelete() {
    const { deleteMosqueAction, goToList } = this.props;
    deleteMosqueAction().then(() => goToList());
  }

  render() {
    const { submitted } = this.state;
    const { mosque } = this.props;
    if (!mosque || Object.keys(mosque).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleDelete} failedSubmit={this.failedDelete} />
        }
        <Row>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <strong>
                  {'Mosque ID: '}
                  {mosque.id}
                </strong>
                <div className="card-header-actions">
                  <Can I="update" a="Mosque">
                    <Button href={`/#/mosques/${mosque.id}/edit`} color="link" className="card-header-action btn-setting">
                      <i className="icon-note" />
                    </Button>
                  </Can>
                  <Can I="delete" a="Mosque">
                    <Button color="link" className="card-header-action btn-setting" onClick={e => this.requestDelete(e)}>
                      <i className="icon-trash" />
                    </Button>
                  </Can>
                </div>
              </CardHeader>
              <CardBody>
                <Table responsive striped hover>
                  <tbody>
                    <tr key="id">
                      <td>Mosque ID:</td>
                      <td><strong>{mosque.id}</strong></td>
                    </tr>
                    <tr key="uuid">
                      <td>Mosque UUID:</td>
                      <td><strong>{mosque.uuid}</strong></td>
                    </tr>
                    <tr key="name">
                      <td>Mosque Name:</td>
                      <td><strong>{mosque.name}</strong></td>
                    </tr>
                    <tr key="countryCode">
                      <td>Mosque Arabic Name:</td>
                      <td><strong>{mosque.countryCode}</strong></td>
                    </tr>
                    <tr key="localisation">
                      <td>Localisation:</td>
                      <td><strong>{mosque.localisation}</strong></td>
                    </tr>
                    <tr key="phone">
                      <td>Phone:</td>
                      <td><strong>{mosque.phone}</strong></td>
                    </tr>
                    <tr key="email">
                      <td>Email:</td>
                      <td><strong>{mosque.email}</strong></td>
                    </tr>
                    <tr key="site">
                      <td>Site:</td>
                      <td><strong>{mosque.site}</strong></td>
                    </tr>
                    <tr key="association">
                      <td>Association:</td>
                      <td><strong>{mosque.association}</strong></td>
                    </tr>
                    <tr key="otherInfo">
                      <td>Other Info:</td>
                      <td><strong>{mosque.otherInfo}</strong></td>
                    </tr>
                    <tr key="latitude">
                      <td>Latitude:</td>
                      <td><strong>{mosque.latitude}</strong></td>
                    </tr>
                    <tr key="longitude">
                      <td>Longitude:</td>
                      <td><strong>{mosque.longitude}</strong></td>
                    </tr>
                    <tr key="version">
                      <td>Version:</td>
                      <td><strong>{mosque.version}</strong></td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter>
                <Button href={`/#/mosques/${mosque.id}/edit`} color="secondary" size="sm">Edit</Button>
                {' '}
                <Button color="danger" onClick={e => this.requestDelete(e)} size="sm">Delete</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

Mosque.propTypes = {
  deleteMosqueAction: PropTypes.func.isRequired,
  getMosque: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired,
  mosque: PropTypes.shape,
};

Mosque.defaultProps = {
  mosque: {},
};

const mapStateToProps = ({ mosques }) => ({
  mosque: mosques.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  deleteMosqueAction: () => deleteMosque(ownProps.match.params.id),
  getMosque: () => fetchMosque(ownProps.match.params.id),
  goToList: () => goBack(),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Mosque);
