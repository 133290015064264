/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { createMosque } from '../../actions/mosques';
import { fetchCountries } from '../../actions/countries';
import { fetchCalculationMethods } from '../../actions/calculation-methods';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class MosqueNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.requestSubmit = this.requestSubmit.bind(this);
    this.failedSubmit = this.failedSubmit.bind(this);
  }

  componentWillMount() {
    const { getCountries, getCalculationMethods } = this.props;
    getCountries();
    getCalculationMethods();
  }

  handleChange(e, name) {
    const { value } = e.target;
    this.setState({ [name]: value });
  }

  handleFileChange(e, name) {
    const { files } = e.target;
    this.setState({ [name]: files[0] });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { createMosqueAction, goToList, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      uuid,
      name,
      countryCode,
      phone,
      localisation,
      site,
      email,
      association,
      otherInfo,
      latitude,
      longitude,
      version,
    } = this.state;
    if (!uuid && !name && !countryCode && !phone && !localisation
      && !site && !email && !association && !otherInfo
       && !latitude && !longitude && !version) {
      return;
    }
    const body = {
      uuid,
      name,
      countryCode,
      phone,
      localisation,
      site,
      email,
      association,
      otherInfo,
      latitude,
      longitude,
      version,
    };
    createMosqueAction({ body: JSON.stringify(body) }).then(() => {
      goToList();
    });
  }

  render() {
    const {
      uuid,
      name,
      countryCode,
      phone,
      localisation,
      site,
      email,
      association,
      otherInfo,
      latitude,
      longitude,
      version,
      submitted,
    } = this.state;
    const { countries, calculationMethods, goToList } = this.props;
    if (!countries.length || !calculationMethods.length) {
      return null;
    }
    return (
      <div className="animated fadeIn">
        {submitted && (
          <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        )}
        <Row>
          <Col lg={6}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)} encType="multipart/form-data">
                <CardHeader>
                  <strong>New mosque</strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="uuid">Mosque UUID</Label>
                    <Input
                      id="uuid"
                      type="text"
                      placeholder="Enter Mosque UUID"
                      invalid={submitted && uuid === ''}
                      onChange={e => this.handleChange(e, 'uuid')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="name">Mosque Name</Label>
                    <Input
                      id="name"
                      type="text"
                      placeholder="Enter Mosque Name"
                      invalid={submitted && name === ''}
                      onChange={e => this.handleChange(e, 'name')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="countryCode">Mosque Country Code</Label>
                    <Input
                      id="countryCode"
                      type="text"
                      placeholder="Enter Mosque Country Code"
                      invalid={submitted && countryCode === ''}
                      onChange={e => this.handleChange(e, 'countryCode')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="phone">Phone</Label>
                    <Input
                      id="phone"
                      type="text"
                      placeholder="Enter Phone"
                      invalid={submitted && phone === ''}
                      onChange={e => this.handleChange(e, 'phone')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="localisation">Localization</Label>
                    <Input
                      id="localisation"
                      type="text"
                      placeholder="Enter Localization"
                      invalid={submitted && localisation === ''}
                      onChange={e => this.handleChange(e, 'localisation')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="email">Email</Label>
                    <Input
                      id="email"
                      type="text"
                      placeholder="Enter Email"
                      invalid={submitted && email === ''}
                      onChange={e => this.handleChange(e, 'email')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="site">Site</Label>
                    <Input
                      id="site"
                      type="text"
                      placeholder="Enter Site"
                      invalid={submitted && site === ''}
                      onChange={e => this.handleChange(e, 'site')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="association">Association</Label>
                    <Input
                      id="association"
                      type="text"
                      placeholder="Enter Association"
                      invalid={submitted && association === ''}
                      onChange={e => this.handleChange(e, 'association')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="otherInfo">Other Info</Label>
                    <Input
                      id="otherInfo"
                      type="text"
                      placeholder="Enter Other Info"
                      invalid={submitted && otherInfo === ''}
                      onChange={e => this.handleChange(e, 'otherInfo')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="latitude">Latitude</Label>
                    <Input
                      id="latitude"
                      type="text"
                      placeholder="Enter Latitude"
                      invalid={submitted && latitude === ''}
                      onChange={e => this.handleChange(e, 'latitude')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="longitude">Longitude</Label>
                    <Input
                      id="longitude"
                      type="text"
                      placeholder="Enter Longitude"
                      invalid={submitted && longitude === ''}
                      onChange={e => this.handleChange(e, 'longitude')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="version">Version</Label>
                    <Input
                      id="version"
                      type="number"
                      placeholder="Enter Version"
                      invalid={submitted && version === ''}
                      onChange={e => this.handleChange(e, 'version')}
                      required
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="primary">
                    Create
                  </Button>
                  {' '}
                  <Button onClick={() => goToList()} size="sm" color="danger">
                    Cancel
                  </Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

MosqueNew.propTypes = {
  getCountries: PropTypes.func.isRequired,
  getCalculationMethods: PropTypes.func.isRequired,
  createMosqueAction: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object),
  calculationMethods: PropTypes.arrayOf(PropTypes.object),
};

MosqueNew.defaultProps = {
  countries: [],
  calculationMethods: [],
};

const mapStateToProps = ({ countries, calculationMethods }) => ({
  countries: countries.items,
  calculationMethods: calculationMethods.items,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getCountries: () => fetchCountries(),
    getCalculationMethods: () => fetchCalculationMethods(),
    createMosqueAction: body => createMosque(body),
    goToList: () => push('/mosques'),
    resetErrorAction: () => resetError(),
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MosqueNew);
