import { authorizedRequest, logout } from './account';
import { responseError } from './errors';

require('dotenv').config();

const ENV = process.env;

export const REQUEST_SOUNDS_FULLS = 'REQUEST_SOUNDS_FULLS';
export const RECEIVE_SOUNDS_FULLS = 'RECEIVE_SOUNDS_FULLS';
export const RECEIVE_SOUNDS_FULL = 'RECEIVE_SOUNDS_FULL';

export const requestSoundsFulls = () => ({
  type: REQUEST_SOUNDS_FULLS,
});

export const receivedSoundsFulls = json => ({
  type: RECEIVE_SOUNDS_FULLS,
  json,
});

export const receivedSoundsFull = json => ({
  type: RECEIVE_SOUNDS_FULL,
  json,
});

export const createSoundsFull = params => (
  (dispatch) => {
    dispatch(requestSoundsFulls());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/sounds-full`, { method: 'POST', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);

export const deleteSoundsFull = id => (
  (dispatch) => {
    dispatch(requestSoundsFulls());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/sounds-full/${id}`, { method: 'DELETE', headers: {} })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);

export const fetchSoundsFulls = sort => (
  (dispatch) => {
    dispatch(requestSoundsFulls());
    let url = `${ENV.REACT_APP_API_SERVER}/sounds-full`;
    if (sort) url += `?order=${JSON.stringify(sort)}`;
    return authorizedRequest(url, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
          dispatch(receivedSoundsFulls([]));
        } else if (json.status === 401) {
          dispatch(logout());
          dispatch(receivedSoundsFulls([]));
        } else {
          dispatch(receivedSoundsFulls({ data: json.sounds } || []));
        }
        return json.sounds;
      });
  }
);

export const fetchSoundsFull = id => (
  (dispatch) => {
    dispatch(requestSoundsFulls());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/sounds-full/${id}`, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(logout());
        } else {
          dispatch(receivedSoundsFull(json.sound));
        }
        return json.sound;
      });
  }
);

export const saveSoundsFull = (id, params) => (
  (dispatch) => {
    dispatch(requestSoundsFulls());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/sounds-full/${id}`, { method: 'POST', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);
