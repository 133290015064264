import { authorizedRequest, logout } from './account';
import { responseError } from './errors';

require('dotenv').config();

const ENV = process.env;

export const REQUEST_WALLPAPERS = 'REQUEST_WALLPAPERS';
export const RECEIVE_WALLPAPERS = 'RECEIVE_WALLPAPERS';
export const RECEIVE_WALLPAPER = 'RECEIVE_WALLPAPER';

export const requestWallpapers = () => ({
  type: REQUEST_WALLPAPERS,
});

export const receivedWallpapers = json => ({
  type: RECEIVE_WALLPAPERS,
  json,
});

export const receivedWallpaper = json => ({
  type: RECEIVE_WALLPAPER,
  json,
});

export const createWallpaper = params => (
  (dispatch) => {
    dispatch(requestWallpapers());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/wallpapers`, { method: 'POST', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);

export const deleteWallpaper = id => (
  (dispatch) => {
    dispatch(requestWallpapers());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/wallpapers/${id}`, { method: 'DELETE', headers: {} })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);

export const fetchWallpapers = sort => (
  (dispatch) => {
    dispatch(requestWallpapers());
    let url = `${ENV.REACT_APP_API_SERVER}/wallpapers`;
    if (sort) url += `?order=${JSON.stringify(sort)}`;
    return authorizedRequest(url, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
          dispatch(receivedWallpapers([]));
        } else if (json.status === 401) {
          dispatch(logout());
          dispatch(receivedWallpapers([]));
        } else {
          dispatch(receivedWallpapers({ data: json.wallpapers } || []));
        }
        return json.wallpapers;
      });
  }
);

export const fetchWallpaper = id => (
  (dispatch) => {
    dispatch(requestWallpapers());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/wallpapers/${id}`, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(logout());
        } else {
          dispatch(receivedWallpaper(json.wallpaper));
        }
        return json.wallpaper;
      });
  }
);

export const saveWallpaper = (id, params) => (
  (dispatch) => {
    dispatch(requestWallpapers());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/wallpapers/${id}`, { method: 'POST', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);
