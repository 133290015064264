import { authorizedRequest, logout } from './account';
import { responseError } from './errors';

require('dotenv').config();

const ENV = process.env;

export const REQUEST_THEMES = 'REQUEST_THEMES';
export const RECEIVE_THEMES = 'RECEIVE_THEMES';
export const RECEIVE_THEME = 'RECEIVE_THEME';

export const requestThemes = () => ({
  type: REQUEST_THEMES,
});

export const receivedThemes = json => ({
  type: RECEIVE_THEMES,
  json,
});

export const receivedTheme = json => ({
  type: RECEIVE_THEME,
  json,
});

export const createTheme = params => (
  (dispatch) => {
    dispatch(requestThemes());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/themes`, { method: 'POST', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);

export const deleteTheme = id => (
  (dispatch) => {
    dispatch(requestThemes());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/themes/${id}`, { method: 'DELETE', headers: {} })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);

export const fetchThemes = sort => (
  (dispatch) => {
    dispatch(requestThemes());
    let url = `${ENV.REACT_APP_API_SERVER}/themes`;
    if (sort) url += `?order=${JSON.stringify(sort)}`;
    return authorizedRequest(url, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
          dispatch(receivedThemes([]));
        } else if (json.status === 401) {
          dispatch(logout());
          dispatch(receivedThemes([]));
        } else {
          dispatch(receivedThemes({ data: json.themes } || []));
        }
        return json.themes;
      });
  }
);

export const fetchTheme = id => (
  (dispatch) => {
    dispatch(requestThemes());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/themes/${id}`, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(logout());
        } else {
          dispatch(receivedTheme(json.theme));
        }
        return json.theme;
      });
  }
);

export const saveTheme = (id, params) => (
  (dispatch) => {
    dispatch(requestThemes());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/themes/${id}`, { method: 'POST', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);
