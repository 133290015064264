import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Row, Table,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { deleteCity, fetchCity } from '../../actions/cities';
import Can from '../../components/can';
import PinModal from '../../components/pin';

class City extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.requestDelete = this.requestDelete.bind(this);
    this.failedDelete = this.failedDelete.bind(this);
  }

  componentWillMount() {
    const { getCity } = this.props;
    getCity();
  }

  requestDelete(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedDelete() {
    this.setState({
      submitted: false,
    });
  }

  handleDelete() {
    const { deleteCityAction, goToList } = this.props;
    deleteCityAction().then(() => goToList());
  }

  render() {
    const { submitted } = this.state;
    const { city } = this.props;
    if (!city || Object.keys(city).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleDelete} failedSubmit={this.failedDelete} />
        }
        <Row>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <strong>
                  {'City ID: '}
                  {city.id}
                </strong>
                <div className="card-header-actions">
                  <Can I="update" a="City">
                    <Button href={`/#/cities/${city.id}/edit`} color="link" className="card-header-action btn-setting">
                      <i className="icon-note" />
                    </Button>
                  </Can>
                  <Can I="delete" a="City">
                    <Button color="link" className="card-header-action btn-setting" onClick={e => this.requestDelete(e)}>
                      <i className="icon-trash" />
                    </Button>
                  </Can>
                </div>
              </CardHeader>
              <CardBody>
                <Table responsive striped hover>
                  <tbody>
                    <tr key="id">
                      <td>City ID:</td>
                      <td><strong>{city.id}</strong></td>
                    </tr>
                    <tr key="name">
                      <td>City Name:</td>
                      <td><strong>{city.name}</strong></td>
                    </tr>
                    <tr key="name_arabic">
                      <td>City Arabic Name:</td>
                      <td><strong>{city.name_arabic}</strong></td>
                    </tr>
                    <tr key="coutnry">
                      <td>Country:</td>
                      <td><strong>{city.country && city.country.name}</strong></td>
                    </tr>
                    <tr key="calculation_method">
                      <td>Calculation Method:</td>
                      <td>
                        <strong>
                          {city.calculationMethod && city.calculationMethod.name}
                        </strong>
                      </td>
                    </tr>
                    <tr key="code">
                      <td>Code:</td>
                      <td><strong>{city.code}</strong></td>
                    </tr>
                    <tr key="state">
                      <td>State:</td>
                      <td><strong>{city.state}</strong></td>
                    </tr>
                    <tr key="latitude">
                      <td>Latitude:</td>
                      <td><strong>{city.latitude}</strong></td>
                    </tr>
                    <tr key="longitude">
                      <td>Longitude:</td>
                      <td><strong>{city.longitude}</strong></td>
                    </tr>
                    <tr key="version">
                      <td>Version:</td>
                      <td><strong>{city.version}</strong></td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter>
                <Button href={`/#/cities/${city.id}/edit`} color="secondary" size="sm">Edit</Button>
                {' '}
                <Button color="danger" onClick={e => this.requestDelete(e)} size="sm">Delete</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

City.propTypes = {
  deleteCityAction: PropTypes.func.isRequired,
  getCity: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired,
  city: PropTypes.shape,
};

City.defaultProps = {
  city: {},
};

const mapStateToProps = ({ cities }) => ({
  city: cities.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  deleteCityAction: () => deleteCity(ownProps.match.params.id),
  getCity: () => fetchCity(ownProps.match.params.id),
  goToList: () => goBack(),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(City);
