import { authorizedRequest, logout } from './account';
import { responseError } from './errors';

require('dotenv').config();

const ENV = process.env;

export const REQUEST_HIDJRI = 'REQUEST_HIDJRI';
export const RECEIVE_HIDJRI = 'RECEIVE_HIDJRI';

export const requestHidjri = () => ({
  type: REQUEST_HIDJRI,
});

export const receivedHidjri = json => ({
  type: RECEIVE_HIDJRI,
  json,
});

export const fetchHidjri = () => (
  (dispatch) => {
    dispatch(requestHidjri());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/hidjri`, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
          dispatch(receivedHidjri([]));
        } else if (json.status === 401) {
          dispatch(logout());
          dispatch(receivedHidjri([]));
        } else {
          dispatch(receivedHidjri({ data: json.hidjri } || []));
        }
        return json;
      });
  }
);

export const saveHidjri = (id, params) => (
  (dispatch) => {
    dispatch(requestHidjri());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/hidjri`, { method: 'POST', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);
