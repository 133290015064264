import { authorizedRequest, logout } from './account';
import { responseError } from './errors';

require('dotenv').config();

const ENV = process.env;

export const REQUEST_MOSQUES = 'REQUEST_MOSQUES';
export const RECEIVE_MOSQUES = 'RECEIVE_MOSQUES';
export const RECEIVE_MOSQUE = 'RECEIVE_MOSQUE';

export const requestMosques = () => ({
  type: REQUEST_MOSQUES,
});

export const receivedMosques = json => ({
  type: RECEIVE_MOSQUES,
  json,
});

export const receivedMosque = json => ({
  type: RECEIVE_MOSQUE,
  json,
});

export const createMosque = params => (
  (dispatch) => {
    dispatch(requestMosques());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/mosques-crud`, { method: 'POST', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);

export const deleteMosque = id => (
  (dispatch) => {
    dispatch(requestMosques());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/mosques-crud/${id}`, { method: 'DELETE', headers: {} })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);

export const fetchMosques = sort => (
  (dispatch) => {
    dispatch(requestMosques());
    let url = `${ENV.REACT_APP_API_SERVER}/mosques-crud`;
    if (sort) url += `?order=${JSON.stringify(sort)}`;
    return authorizedRequest(url, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
          dispatch(receivedMosques([]));
        } else if (json.status === 401) {
          dispatch(logout());
          dispatch(receivedMosques([]));
        } else {
          dispatch(receivedMosques({ data: json } || []));
        }
        return json;
      });
  }
);

export const fetchMosque = id => (
  (dispatch) => {
    dispatch(requestMosques());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/mosques-crud/${id}`, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(logout());
        } else {
          dispatch(receivedMosque(json));
        }
        return json;
      });
  }
);

export const saveMosque = (id, params) => (
  (dispatch) => {
    dispatch(requestMosques());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/mosques-crud/${id}`, { method: 'POST', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);
