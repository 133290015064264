import { authorizedRequest, logout } from './account';
import { responseError } from './errors';

require('dotenv').config();

const ENV = process.env;

export const REQUEST_DST_SETUP = 'REQUEST_DST_SETUP';
export const RECEIVE_DST_SETUP = 'RECEIVE_DST_SETUP';

export const requestDstSetup = () => ({
  type: REQUEST_DST_SETUP,
});

export const receivedDstSetup = json => ({
  type: RECEIVE_DST_SETUP,
  json,
});

export const fetchDstSetup = () => (
  (dispatch) => {
    dispatch(requestDstSetup());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/dst-setup`, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
          dispatch(receivedDstSetup([]));
        } else if (json.status === 401) {
          dispatch(logout());
          dispatch(receivedDstSetup({}));
        } else {
          dispatch(receivedDstSetup({ data: json } || {}));
        }
        return json;
      });
  }
);

export const saveDstSetup = (id, params) => (
  (dispatch) => {
    dispatch(requestDstSetup());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/dst-setup`, { method: 'POST', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Unauthorized'));
        }
        return json;
      });
  }
);
