import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import FIELDS from './fields';

const CardForm = (props) => {
  const {
    index, type, card, onChange, onRemove, onTypeChange, submitted,
  } = props;
  const { fields } = FIELDS[type];
  return (
    <div className="well">
      <button type="button" onClick={() => onRemove(index)}>&times;</button>
      <h6>
        <strong>{`Card ${index + 1}`}</strong>
      </h6>
      <div className="well-data">
        {fields.map((field) => {
          if (field.id === 'template') {
            return (
              <FormGroup key={field.id}>
                <Label htmlFor={field.id}>{field.label}</Label>
                <Input
                  type="select"
                  onChange={e => onTypeChange(index, e.target.value)}
                  // defaultValue={type}
                  value={type}
                  required
                >
                  <>
                    {Object.keys(FIELDS).map((fieldType) => {
                      const fieldItem = FIELDS[fieldType];
                      return (
                        <option key={fieldType} value={fieldType}>
                          {fieldItem.name}
                        </option>
                      );
                    })}
                  </>
                </Input>
              </FormGroup>
            );
          }
          if (field.id === 'type') {
            return (
              <FormGroup key={field.id}>
                <Label htmlFor={field.id}>{field.label}</Label>
                <Input
                  id={field.id}
                  disabled
                  type={field.type}
                  invalid={submitted && card[field.id] === ''}
                  value={card[field.id]}
                  required
                />
              </FormGroup>
            );
          }
          return (
            <FormGroup key={field.id}>
              <Label htmlFor={field.id}>{field.label}</Label>
              <Input
                id={field.id}
                type={field.type}
                placeholder={`Enter ${field.id}`}
                invalid={submitted && card[field.id] === ''}
                defaultValue={card[field.id]}
                onChange={e => onChange(e, field, index)}
                rows={5}
                required
              />
            </FormGroup>
          );
        })}
      </div>
    </div>
  );
};

CardForm.propTypes = {
  index: PropTypes.number.isRequired,
  type: PropTypes.number.isRequired,
  card: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  onTypeChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  submitted: PropTypes.bool.isRequired,
};

export default CardForm;
